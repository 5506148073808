const initialState = {
  notificationList: [],
  notificationListLoading: false,
  notificationCount: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "NOTICATION_COUNT":
      return {
        ...state,
        notificationCount: payload,
      };
    case "NOTIFICATION_LIST":
      return {
        ...state,
        notificationList: payload,
      };
    case "NOTIFICATION_LIST_ON_SCROLL":
      return {
        ...state,
        notificationListOnScroll: payload,
      };
    case "NOTIFICATION_LIST_LOADING":
      return {
        ...state,
        notificationListLoading: payload,
      };
    default:
      return state;
  }
}
