import React from "react";
import { Typography } from "@mui/material";
import { connect } from "react-redux";
 const Devlivery = (props) => {
  const getDeliveryMessage = (full_fill_by_meairaq) => {
    return full_fill_by_meairaq
      ? props.config.fullfill_message
      : props.config.not_fullfill_message;
  }
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      component="p"
      style={{ lineHeight: 2,color:"#e1900f" }}
    >
      {getDeliveryMessage(
        props.full_fill_by_meairaq
      )}
    </Typography>
  );
 }
const mapStateToProps = (state) => ({
 
  config: state.config.detail,
});
export default connect(mapStateToProps, {})(Devlivery);
