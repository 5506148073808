import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Alert from "../Notifications/Alert";

import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { resetPassword } from "../../actions/auth";
import { withTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const useStyles = makeStyles((theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);
const ResetPassword = ({ t }) => {
  const classes = useStyles();
  let history = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    password: null,
  });
  const [showPassword, setShowPassword] = useState(false);


  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();

    dispatch(resetPassword(formData)).then((res) => {
      if (res.status) {
        history({
          pathname: "/",
        });
      }
    });
  };
  return (
    <Container maxWidth="sm" component="main">
      <Box component="div" m={8}>
        <Typography variant="h5" align="center">
          {t("Reset Password")}
        </Typography>
        <Alert />
        <form onSubmit={(e) => onSubmit(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="password"
            label={t("Password")}
            name="password"
            autoComplete="password"
            type={showPassword ? "text" : "password"}
            required
            // autoFocus
            onChange={(e) => onChange(e)}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton arial-label="toggle passwor visibility" edge="end"
                  onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit + " text-white"}
          >
            {t("Update Password")}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default withTranslation()(ResetPassword);
