import React, { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { Grid, TextField } from "@mui/material";
import { withTranslation } from "react-i18next";
import { getCityList } from "actions/cart";

var originalCityList = [];
const CSC = (props) => {
  const { t } = props;
  const [values, setValues] = useState({
    state: "",
    city: "",
    country: t("Iraq"),
  });
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("");

  useEffect(() => {
    setValues(props.values);
    setCity(props.values.city);
  }, [props.values]);

  useEffect(() => {
    getCityList().then((res) => {
      if (res.data) {
        setCityList(res.data);
        originalCityList = res.data;
      }
    });
  }, [getCityList]);

  const handleCityChange = (e) => {
    setCity(e.target.value);
    if (e.target.value) {
      setCityList(
        originalCityList.filter((itm) => itm.name.indexOf(e.target.value) > -1)
      );
    } else {
      setCityList(originalCityList);
    }
  };
  return (
    <Grid container spacing={3} style={{ display: "flex" }}>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          disabled={true}
          id="country"
          name="country"
          value={values.country}
          error={!props.errorObj.country.error}
          helperText={
            !props.errorObj.country.error && props.errorObj.country.msg
          }
          onChange={(e) => props.handleChange(e.target.value, "country")}
          label={t("Country")}
          fullWidth
          autoComplete="Country"
          variant="outlined"
        />
      </Grid>
      {/* <Grid item xs={12} sm={4}>
        <TextField
          required
          id="State"
          name="State"
          value={values.state}
          error={!props.errorObj.state.error}
          helperText={!props.errorObj.state.error && props.errorObj.state.msg}
          onChange={(e) => props.handleChange(e.target.value, "state")}
          label={t("State")}
          fullWidth
          autoComplete="State"
          variant="outlined"
        />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <Autocomplete
          id="city"
          name="city"
          options={cityList || []}
          label={t("city")}
          autoHighlight
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => {
            props.handleChange(value?.name, "city");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Choose a city")}
              variant="outlined"
              error={!props.errorObj.city.error}
              helperText={!props.errorObj.city.error && props.errorObj.city.msg}
              inputProps={{
                ...params.inputProps,
                value: city,
                onChange: handleCityChange,
                autoComplete: "City",
                // autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
export default withTranslation()(CSC);
