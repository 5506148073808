import React, { useEffect, Fragment } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { connect } from "react-redux";
import Simmer from "components/common/skeleton";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    sliderContainer: {
      padding: 40,
      [theme.breakpoints.down('lg')]: {
        padding: "32px 0 0 0",
      },
    },
    media: {
      objectFit: "contain",
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
  })
);

const DealOfTheDay = ({ main_banner, selected_country, loading, t }) => {
  const classes = useStyles();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      {loading ? (
        <Simmer total={6} />
      ) : main_banner && main_banner.length > 0 ? (
        <Box component="div" className={classes.sliderContainer}>
          <Typography variant="h3" component="h3" className={classes.title}>
            {t("Deal Of The Day")}
            <div className={classes.bottomBorder}></div>
          </Typography>
          <Box className="sliderouterdealoftheday">
            <Carousel
              arrows={false}
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              className="carousel-container-ltr"
            >
              {main_banner.map((item, i) => (
                <Fragment key={i}>
                  {item.action ? (
                    <Link to={item.action}>
                      <img
                        src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                        key={i}
                        width="100%"
                        className={classes.media}
                      />
                    </Link>
                  ) : (
                    <img
                      src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                      key={i}
                      className={classes.media}
                    />
                  )}
                </Fragment>
              ))}
            </Carousel>
          </Box>
        </Box>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  main_banner: state.banner.list.deal_of_the_day,
  selected_country: state.countries.selected_country,
  loading: state.banner.Loading,
});
export default withTranslation()(connect(mapStateToProps)(DealOfTheDay));
