import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Alert from "../Notifications/Alert";
import SocialButton from "./SocialButton";
import { withTranslation } from "react-i18next";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
import { REACT_GOOGLE_CAPCHA_KEY } from "helper";

import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { isNumber } from "../../helper";
import { register, login, socialLogin } from "../../actions/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const useStyles = makeStyles((theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    linkcls: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      width: "100%",
      textAlign: "center",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    phoneinput: {
      width: "100% !important",
      height: "56px !important",
    },
  })
);
const Login = ({ t }) => {
  const classes = useStyles();
  let history = useNavigate();
  const dispatch = useDispatch();
  const [recaptchaResponse, setRecaptchaResponse] = useState(false);
  const [loginWithPhone, setLoginWithPhone] = useState(false);
  const [loginWithOtp, setLoginWithOtp] = useState(false);
  const [registerview, setRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);


  const changeRegisterView = (e) => {
    setRegister(!registerview);
    if (!loginWithOtp) setLoginWithOtp(!loginWithOtp);
  };

  const [formData, setFormData] = useState({
    email: null,
    phone: null,
    phone_code: null,
    password: null,
  });
  const checkEmail = (e) => {
    let is_number = isNumber(e.target.value) ? true : false;
    setLoginWithPhone(is_number);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePhoneCode = (e) => {
    setFormData({ ...formData, ["phone_code"]: "+" + e });
  };
  const handleCapchaVerify = useCallback((token) => {
    setRecaptchaResponse(token);
  }, []);

  const handleSocialLogin = (user, err) => {
    if (!err) {
      const user_social_data = {
        display_name: user.profile.name,
        email: user.profile.email,
        provider: user.provider,
        provider_id: user.profile.id,
        customer_type: "customer",
      };
      dispatch(socialLogin(user_social_data)).then((res) => {
        if (res.status) {
          history({
            pathname: "/",
          });
        }
      });
    }
  };

  const onSubmit = (e) => {
    let loginData = {};
    e.preventDefault();
    if (loginWithOtp) {
      loginData = { ...loginData, ...formData, password: null };
    }
    if (loginWithPhone) {
      loginData = { ...loginData, ...formData, email: null };
    } else {
      loginData = { ...loginData, ...formData, phone: null, phone_code: null };
    }
    loginData = { ...loginData, ["recaptcha_response"]: recaptchaResponse };
    setFormData(loginData);
    if (loginWithOtp) {
      //call register api
      dispatch(register(loginData)).then((res) => {
        if (res.status) {
          if (
            window.location.search &&
            window.location.search.split("redirect=") &&
            window.location.search.split("redirect=")[1]
          ) {
            history("/verify", {
              state: {
                formData: loginData,
                pagefrom: "signin",
                redirect: window.location.search.split("redirect=")[1],
              },
            });
          } else {
            history("/verify", {
              state: { formData: loginData, pagefrom: "signin" },
            });
          }
        }
      });
    } else {
      dispatch(login(loginData)).then((res) => {
        if (res.status) {
          if (
            window.location.search &&
            window.location.search.split("redirect=") &&
            window.location.search.split("redirect=")[1]
          ) {
            history({
              pathname: window.location.search.split("redirect=")[1],
            });
          } else {
            history({
              pathname: "/",
            });
          }
        }
      });
    }
    setRefreshReCaptcha(r => !r);
  };

  return (
    <Container maxWidth="lg" component="main">
      <Grid container>
        <Grid container item xs={12} sm={6}>
          <Box component="div" m={6} p={4} boxShadow={2} width={1}>
            <Box p={2}>
              <Typography variant="h3" component="h4" align="center">
                {registerview ? t("Signin/Signup") : t("Signin")}
              </Typography>
              <Alert />
            </Box>
            <Box p={2}>
              <GoogleReCaptchaProvider reCaptchaKey={REACT_GOOGLE_CAPCHA_KEY}>

                <form onSubmit={(e) => onSubmit(e)}>
                  <Grid container>
                    <Grid container item xs={12} sm={12}>
                      {loginWithPhone && (
                        <PhoneInput
                          country={"iq"}
                          value={formData.phone_code}
                          onChange={handlePhoneCode}
                          inputProps={{ readOnly: true, name: "phone_code" }}
                          inputClass={classes.phoneinput}
                          enableSearch={true}
                        />
                      )}
                    </Grid>
                    <Grid container item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id={loginWithPhone ? "phone" : "email"}
                        label={t("Email or Phone")}
                        name={loginWithPhone ? "phone" : "email"}
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        autoFocus={true}
                        onChange={(e) => checkEmail(e)}
                      />
                    </Grid>
                  </Grid>

                  {!loginWithOtp && (
                    <Grid container item xs={12} sm={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="password"
                        label={t("Password")}
                        name="password"
                        autoComplete="password"
                        type={showPassword ? "text" : "password"}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => onChange(e)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                arial-label="toggle passwor visibility"
                                edge="end"
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.submit + " text-white" + " m-0"}
                  >
                    {loginWithOtp ? t("Send Otp") : t("Login")}
                  </Button>

                  <Grid container className="mt-2">
                    {!registerview && (
                      <Grid container item xs={6} sm={6}>
                        <Link
                          href="#"
                          to="/forgot-password"
                          variant="body2"
                          className={classes.linkcls}
                        >
                          {t("Forgot password")}?
                        </Link>
                      </Grid>
                    )}

                    {!registerview && (
                      <Grid container item xs={6} sm={6}>
                        <span
                          onClick={() => setLoginWithOtp(!loginWithOtp)}
                          variant="body2"
                          className={classes.linkcls}
                        >
                          {loginWithOtp
                            ? t("Login with password")
                            : t("Login with OTP")}
                        </span>
                      </Grid>
                    )}
                  </Grid>
                  <GoogleReCaptcha onVerify={handleCapchaVerify} refreshReCaptcha={refreshReCaptcha} />
                  <Box mt={4}>
                    <Grid
                      container
                      item
                      xs={!registerview ? 12 : 12}
                      sm={!registerview ? 12 : 12}
                    >
                      <span
                        onClick={() => changeRegisterView()}
                        variant="body2"
                        className={classes.linkcls}
                      >
                        {!registerview
                          ? t("Don't have an account? Sign Up")
                          : t("Back to Login")}
                      </span>
                    </Grid>
                  </Box>
                </form>
              </GoogleReCaptchaProvider>
            </Box>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Box component="div" m={6} p={4} boxShadow={2} width={1}>
            <Box p={2}>
              <Typography variant="h3" component="h4" align="center">
                {t("Continue with Social")}
              </Typography>
            </Box>
            <SocialButton handleSocialLogin={handleSocialLogin} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withTranslation()(Login);
