import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Box, Typography, Grid } from "@mui/material";
import { similarVendor } from "../../actions/vendor";

import VendorCard from "components/Vendor/vendorCard";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 8,
      boxShadow: "none",
    },
    media: {
      width: "100%",
      height: 280,
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    sliderContainer: {
      [theme.breakpoints.down("lg")]: {
        padding: "32px 0 0 0",
      },
    },
    rating: {
      color: "orange",
    },
  })
);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { min: 1920 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 4000, min: 1025 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const SimilarVendorSameCountry = (props) => {
  const classes = useStyles();
  const { t } = props;
  const [similarVendor, setSimilarVendor] = useState([]);

  useEffect(() => {
    props
      .similarVendor({
        vendor_id: props.vendor?.id,
        other_vendor: 1,
        limit: 10,
        similar: 1,
      })
      .then((res) => {
        setSimilarVendor(res);
      });
  }, [props.vendor?.id]);
  return (
    <Box component="div" style={{ marginTop: 40 }}>
      {similarVendor && similarVendor.length > 0 ? (
        <Box component="div" className={classes.sliderContainer}>
          <Typography variant="h5" component="h4" className={classes.title}>
            {t("Similar Vendors")}
            <div className={classes.bottomBorder}></div>
          </Typography>
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            // customTransition="all .5"
            className="carousel-container-ltr"
            transitionDuration={500}
            containerClass="carousel-container"
          >
            {similarVendor.map((vendor) => {
              return (
                <Grid
                  container
                  spacing={4}
                  className="fixsizeitems"
                  key={vendor}
                >
                  <VendorCard item={vendor} />
                </Grid>
              );
            })}
          </Carousel>
        </Box>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => ({});
export default withTranslation()(
  connect(mapStateToProps, {
    similarVendor,
  })(SimilarVendorSameCountry)
);
