import React, { useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Backdrop, CircularProgress, Container, Grid } from "@mui/material";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getWallets, getUserWallet } from "actions/wallet";
import Moment from "moment";
import { Pagination } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    // maxWidth: '100ch',
    // backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    marginTop: 30,
    marginBottom: 60,
    marginLeft: "auto",
    marginRight: "auto",
    width: "50%",
  },
  inline: {
    display: "inline",
  },
  amount: {
    fontSize: 18,
    fontWeight: 500,
  },
  currentbalance: {},
}));

const textVal = (index, itm, t) => {
  switch (index) {
    case 0:
      return t("Debited against order #") + itm.order_id;
    case 1:
      return t("Refund against order #") + itm.order_id;
    case 2:
      return t("Registration bonus");
    case 3:
      return t("Cashback order #") + itm.order_id;
    case 4:
      return t("Shipping charge #") + itm.order_id;
    case 5:
      return t("Cancel order refund #") + itm.order_id;
    default:
  }
};
const Wallet = (props) => {
  const classes = useStyles();
  const { t } = props;
  useEffect(() => {
    props.getWallets();
    props.getUserWallet();
  }, [props.getWallets]);

  const handlePaginationChange = (e, page) => {
    props.getWallets({ page: page });
  };

  return (
    <Container className={classes.root} maxWidth="lg">
      <Grid container>
        <Grid item xs="8">
          <h2 style={{ padding: 10 }}>{t("Transaction History")}</h2>
        </Grid>
        <Grid item xs="4" className={classes.currentbalance}>
          <h2 style={{ padding: 10 }}>
            {t("Current Balance")} :{" "}
            <span style={{ fontWeight: 400, color: "green" }}>
              {props.balance?.amount || "$0"}
            </span>
          </h2>
          <Divider
            style={{ marginLeft: 0, margin: -2, height: 2, listStyle: "none" }}
            variant="inset"
            component="li"
          />
        </Grid>
      </Grid>
      {props.Loading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" disableShrink />
        </Backdrop>
      )}
      {props.list && props.list.length ? (
        props.list.map((itm) => (
          <>
            <List className={classes.list}>
              <ListItem alignItems="flex-start" className={classes.listItem}>
                <ListItemText
                  primary={textVal(itm.used_for, itm, t)}
                  className="traHistory"
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      ></Typography>
                      {Moment(itm.created_at).format("LL")}
                    </React.Fragment>
                  }
                />
                <ListItemAvatar>
                  <Typography
                    style={{ color: itm.type == 0 ? "red" : "green" }}
                    className={classes.amount}
                    variant="p"
                  >
                    {itm.amount}
                  </Typography>
                </ListItemAvatar>
              </ListItem>
              {/* <Divider style={{marginLeft:0}} variant="inset" component="li" /> */}
            </List>
          </>
        ))
      ) : (
        <p>{t("No transactions data")}</p>
      )}
      <Grid className={classes.pagination}>
        <Pagination
          variant="outlined"
          style={{ float: "right" }}
          page={props.meta.current_page}
          onChange={handlePaginationChange}
          count={Math.ceil(props.meta.total / props.meta.per_page)}
          shape="rounded"
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  list: state.wallet.list,
  meta: state.wallet.meta,
  Loading: state.wallet.Loading,
  balance: state.wallet.balance,
});

const mapDispatchToProps = {
  getWallets,
  getUserWallet,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Wallet)
);
