import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { getProductFilter } from "actions/product";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Typography,
  Checkbox
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CheckCircle as CheckCircleIcon, ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Simmer from "components/common/skeleton";
import { withTranslation } from "react-i18next";
import MetaComponent from "components/common/MetaComponent";

var delayTimer;
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 8,
  },
  filterBar: {
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    boxShadow: 3,
    color: theme.palette.text.secondary,
    paddingTop: 60,
  },
  filterItem: {
    margin: 8,
    marginBottom: 24,
  },
  rangeSlider: {
    // margin: 24,
  },
  filterTitlePrice: {
    marginBottom: 40,
  },
  filterTitle: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    lineHeight: 3,
    textTransform: "uppercase",
    marginTop: 10,
  },
  colorContainer: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 4,
  },
  filterColorContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
  },
  colorItem: {
    height: 20,
    boxShadow: "0 0 6px #ccc",
    borderRadius: 25,
    marginRight: 8,
    width: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      "& path": {
        fill: "#ccc",
      },
    },
  },
}));

const ProductFilter = ({
  getProductFilter,
  productFilterLoading,
  productFilterList,
  handleFilterCb,
  filterProps,
  queryParams,
  t,
  slug
}) => {
  const classes = useStyles();

  const [filter, setFilter] = useState({});
  const [active, setActive] = useState(null);

  const paramsToObject = (entries) => {
    const result = {};
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  };

  useEffect(() => {
    setFilter(filterProps || {});
  }, [filterProps]);

  useEffect(() => {
    const urlParam = new URLSearchParams(window.location.search);
    const entrie = urlParam.entries(); //returns an iterator of decoded [key,value] tuples
    let param = paramsToObject(entrie);
    // setFilter(param)
    let h = {};
    const selectArr = [
      "brand",
      "size",
      "color",
      "price",
      "flash_deal",
      "vendor_id",
      "similar",
      "category_id",
      "other_vendor",
      "search",
    ];
    Object.keys(param).map((itm) => {
      if (selectArr.indexOf(itm) > -1) {
        h = { ...h, [itm]: param[itm].split(",").map((it) => parseInt(it)) };
      }
    });
    setFilter(h);
    handleFilterCb(h);
  }, [queryParams]);

  useEffect(() => {
    getProductFilter(queryParams);
  }, [getProductFilter, slug]);

  useEffect(() => {
    if (productFilterList && productFilterList.max_price && !filter.price) {
      setFilter((flt) => ({
        ...flt,
        price: [0, parseInt(productFilterList.max_price)],
      }));
    }
  }, [productFilterList]);

  const handleFilter = (type, value) => {
    let newFilter = filter[type] || [];
    newFilter.indexOf(value) > -1
      ? newFilter.splice(newFilter.indexOf(value), 1)
      : newFilter.push(value);

    setFilter((flt) => ({
      ...flt,
      [type]: newFilter,
    }));

    handleFilterCb({ ...filter, [type]: newFilter });
  };

  const colorFilter = (itm) => {
    let h = [];
    itm.options.map((item) => {
      h.push(
        <Grid className={classes.colorContainer}>
          <div
            className={classes.colorItem}
            onClick={() => handleFilter(itm.code, item.id)}
            style={{
              backgroundColor: item.admin_name,
              cursor: "pointer",
            }}
          >
            {filter[itm.code] && filter[itm.code].indexOf(item.id) > -1 && (
              <CheckCircleIcon className={classes.tickIcon} />
            )}
          </div>
        </Grid>
      );
    });

    return h;
  };

  const sizeFilter = (itm) => {
    let h = [
      <Typography className={classes.filterTitle} component="h4">
        {itm.name}
        <span
          style={{ cursor: "pointer", float: "right" }}
          onClick={() => setActive(active === itm.name ? "" : itm.name)}
        >
          {active === itm.name ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
        </span>
      </Typography>,
    ];
    active === itm.name &&
      h.push(
        <FormGroup row>
          {itm.options.map((item, index) => (
            <FormControlLabel
              className={classes.size}
              control={
                <Checkbox
                  checked={
                    filter[itm.code] && filter[itm.code].indexOf(item.id) > -1
                      ? true
                      : false
                  }
                  onChange={() => handleFilter(itm.code, item.id)}
                  name={item.label}
                  key={index}
                  color="primary"
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      );
    return h;
  };

  const priceFilter = (itm) => {
    let h = [
      <>
        <Typography className={classes.filterTitle} component="h4">
          {itm.name}
          <span
            style={{ cursor: "pointer", float: "right" }}
            onClick={() => setActive(active === itm.name ? "" : itm.name)}
          >
            {active === itm.name ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
          </span>
        </Typography>
      </>,
    ];
    active === itm.name &&
      h.push(
        <FormGroup>
          {itm.options.map((item, index) => (
            <FormControlLabel
              className={classes.price}
              control={
                <Checkbox
                  checked={
                    filter[itm.code] && filter[itm.code].indexOf(item.id) > -1
                      ? true
                      : false
                  }
                  onChange={() => handleFilter(itm.code, item.id)}
                  name={item.label}
                  key={index}
                  color="primary"
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
      );
    return h;
  };

  const [brandViewAll, setBrandViewAll] = useState(false);

  const brandFilter = (itm) => {
    let h = [
      <Typography className={classes.filterTitle} component="h4">
        {itm.name}
        <span
          style={{ cursor: "pointer", float: "right" }}
          onClick={() => setActive(active === itm.name ? "" : itm.name)}
        >
          {active === itm.name ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
        </span>
      </Typography>,
    ];
    itm.name === active &&
      h.push(
        <FormGroup>
          {itm.options.map(
            (item, index) =>
              index < (brandViewAll ? itm.options.length : 7) && (
                <FormControlLabel
                  className={classes.brand}
                  control={
                    <Checkbox
                      checked={
                        filter[itm.code] &&
                          filter[itm.code].indexOf(item.id) > -1
                          ? true
                          : false
                      }
                      onChange={() => handleFilter(itm.code, item.id)}
                      name={item.label}
                      key={index}
                      color="primary"
                    />
                  }
                  label={item.label}
                />
              )
          )}
          {itm.options.length > 6 && (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setBrandViewAll(!brandViewAll)}
            >
              {brandViewAll ? "View less" : "View all"}
            </span>
          )}
        </FormGroup>
      );
    return h;
  };

  const handlePriceRange = (event, newValue) => {
    setFilter((flt) => ({
      ...flt,
      price: newValue,
    }));

    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
      handleFilterCb({ ...filter, price: newValue });
    }, 1000);
  };

  function valuetext(value) {
    return `$ ${value}`;
  }
  const priceRangeFilter = (max, currency) => {
    if (!(filter.price && filter.price.length)) return null;
    return (
      <Grid className={clsx(classes.filterItem, classes.rangeSlider)}>
        <Typography className={classes.filterTitle} component="h4">
          {t("price")}
        </Typography>
        <Slider
          className="filterslider"
          value={filter.price}
          onChange={handlePriceRange}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          marks={[
            { value: 0, label: `${currency} ${0}` },
            { value: max, label: `${currency} ${max}` },
          ]}
          //valueLabelDisplay="on"
          // min={1}
          max={max}
        />
      </Grid>
    );
  };

  const showProductFilter = (item, maxPrice, currency) => {
    let h = [];
    item.map((itm) => {
      switch (itm.code) {
        case "color":
          itm.options &&
            itm.options.length &&
            h.push(
              <Grid className={classes.filterItem}>
                <Typography className={classes.filterTitle} component="h4">
                  {itm.name}
                  <span
                    style={{ cursor: "pointer", float: "right" }}
                    onClick={() =>
                      setActive(active === itm.name ? "" : itm.name)
                    }
                  >
                    {active === itm.name ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}{" "}
                  </span>
                </Typography>
                {active === itm.name && (
                  <Grid className={classes.filterColorContainer}>
                    {colorFilter(itm)}
                  </Grid>
                )}
              </Grid>
            );
          break;
        case "price":
          itm.options &&
            itm.options.length &&
            h.push(
              <Grid className={classes.filterItem}>{priceFilter(itm)}</Grid>
            );
          break;
        case "size":
          itm.options &&
            itm.options.length &&
            h.push(
              <Grid className={classes.filterItem}>{sizeFilter(itm)}</Grid>
            );
          break;
        case "brand":
          itm.options &&
            itm.options.length &&
            h.push(
              <Grid className={classes.filterItem}>{brandFilter(itm)}</Grid>
            );
          break;
        default:
      }
    });
    maxPrice &&
      parseInt(maxPrice) > 0 &&
      h.push(priceRangeFilter(parseFloat(maxPrice).toFixed(2), currency));
    return h;
  };

  return productFilterLoading ? (
    <Simmer total={1} />
  ) : (
    <Grid style={{ width: "100%" }} className={classes.filterArea}>
      <h2 style={{ marginLeft: 8 }}>{t('Filters')}</h2>
      <hr></hr>
      {productFilterList &&
        productFilterList.attributes &&
        productFilterList.attributes.length > 0
        ? showProductFilter(
          productFilterList.attributes,
          productFilterList.max_price,
          productFilterList.currency
        )
        : "No filters available"}
      {productFilterList &&
        (<MetaComponent
          keyword={productFilterList.meta_keywords}
          title={productFilterList.meta_title}
          description={productFilterList.meta_description}
          image={productFilterList.meta_image}
        />)}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  productFilterLoading: state.product.productFilterLoading,
  productFilterList: state.product.productFilterList,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getProductFilter,
  })(ProductFilter)
);
