import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Alert from "../Notifications/Alert";
import { withTranslation } from "react-i18next";

import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { isNumber } from "../../helper";
import { forgotpassword } from "../../actions/auth";

const useStyles = makeStyles((theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    linkcls: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      width: "100%",
      textAlign: "center",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    phoneinput: {
      width: "100% !important",
    },
  })
);
const Forgot = ({ t }) => {
  const classes = useStyles();
  let history = useNavigate();
  const dispatch = useDispatch();

  const [loginWithPhone, setLoginWithPhone] = useState(false);

  const [formData, setFormData] = useState({
    email: null,
    phone: null,
    phone_code: null,
    password: null,
  });
  const checkEmail = (e) => {
    let is_number = isNumber(e.target.value) ? true : false;
    setLoginWithPhone(is_number);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handlePhoneCode = (e) => {
    setFormData({ ...formData, ["phone_code"]: e });
  };
  const onSubmit = (e) => {
    let loginData = {};
    e.preventDefault();
    if (loginWithPhone) {
      loginData = { ...loginData, ...formData, email: null };
    } else {
      loginData = { ...loginData, ...formData, phone: null, phone_code: null };
    }
    setFormData(loginData);
    //call register api
    dispatch(forgotpassword(loginData)).then((res) => {
      if (res.status) {
        history("/verify", {
          state: { formData: loginData, pagefrom: "forgot-password" },
        });
      }
    });
  };
  return (
    <Container maxWidth="sm" component="main">
      <Grid container>
        <Grid container item xs={12} sm={12}>
          <Box component="div" m={6} p={4} boxShadow={2} width={1}>
            <Box p={2}>
              <Typography variant="h3" component="h4" align="center">
                {t("Forgot Password")}
              </Typography>
              <Alert />
            </Box>
            <Box p={2}>
              <form onSubmit={(e) => onSubmit(e)}>
                <Grid container>
                  <Grid container item xs={6} sm={12}>
                    {loginWithPhone && (
                      <PhoneInput
                        country={"iq"}
                        value={formData.phone_code}
                        onChange={handlePhoneCode}
                        inputProps={{ readOnly: true, name: "phone_code" }}
                        inputClass={classes.phoneinput}
                        enableSearch={true}
                      />
                    )}
                  </Grid>
                  <Grid container item xs={6} sm={loginWithPhone ? 12 : 12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id={loginWithPhone ? "phone" : "email"}
                      label={t("Email or Phone")}
                      name={loginWithPhone ? "phone" : "email"}
                      type="text"
                      autoFocus={true}
                      onChange={(e) => checkEmail(e)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.submit}
                  className="text-white"
                >
                  {t("Send Otp")}
                </Button>
                <Grid container>
                  <Grid container item xs={12} sm={12}>
                    <Link
                      href="#"
                      to="/login"
                      variant="body2"
                      className={classes.linkcls}
                      className="text-white"
                    >
                      {t("Back to Login")}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withTranslation()(Forgot);
