import store from "./store";
import { REACT_APP_API_BASE_URL } from "utils/config";

const token = localStorage.getItem("token");
let param = {
  token: token ? token : "api",
};
const config = {
  headers: {
    "Content-Type": "application/json",
  },
  params: param,
};
export const authHeader = () => {
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("language")
    ? JSON.parse(localStorage.getItem("language")).value
    : "ar";
  const curr = localStorage.getItem("currency")
    ? JSON.parse(localStorage.getItem("currency")).value
    : "iqd";
  config.params.locale = lang ? lang : "ar";
  config.headers = {
    "Content-Type": "application/json",
  };
  config.params.currency = curr ? curr : "iqd";

  if (token) {
    config.params.token = token;
  }
  return config;
};
export const fileauthHeader = () => {
  config.headers = {
    "Content-Type": "multipart/form-data",
  };
  return config;
};
export const params = () => {
  return param;
};

export const BASE_URL =
  REACT_APP_API_BASE_URL || "https://sooqiraq.qa7.dbwsweb.com/api";
// export const BASE_URL = "http://sooqiraq.local:82/api"

export const handleCatchException = (error) => {
  store.dispatch({
    type: "LOADING_HIDE",
  });
  let message = "Something went wrong";
  if (error?.response?.status == 422) {
    message = error.response.data?.message;
  } else if (error?.response?.status == 400) {
    message = error.response.data?.message;
  } else if (error?.response?.status == 401) {
    message = error.response.data.error;
  } else if (error?.response?.status == 500) {
    message = error.response.data.error;
  }
  store.dispatch({
    type: "ALERT_SHOW",
    payload: {
      message: message,
      alertType: "error",
    },
  });
  return false;
};
export const showloader = () => {
  store.dispatch({
    type: "LOADING_SHOW",
  });
};
export const hideloader = () => {
  store.dispatch({
    type: "LOADING_HIDE",
  });
};
export const successmsg = (msg, type) => {
  store.dispatch({
    type: "ALERT_SHOW",
    payload: {
      message: msg,
      alertType: "success",
      type: type,
    },
  });
};
export const errormsg = (msg, type) => {
  store.dispatch({
    type: "ALERT_SHOW",
    payload: {
      message: msg,
      alertType: "error",
      type: type,
    },
  });
};
