import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { getProducts, getSuperMarketProducts } from "actions/product";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useLocation } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Box, Typography, Grid } from "@mui/material";
import ProductCard from "components/Product/productCard";
import ViewAll from "components/Home/ViewAll";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      backgroundColor: "#f2f6f4",
    },
    media: {
      width: "100%",
      height: 280,
      position: "relative",
    },
    miniQty: {
      background: theme.palette.primary.main,
      color: "#fff",
      position: "absolute",
      bottom: "16px",
      left: 0,
      padding: "6px 12px",
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    sliderContainer: {
      padding: 40,
      [theme.breakpoints.down("lg")]: {
        padding: "32px 0 0 0",
      },
    },
    linkcls: {
      textDecoration: "none",
      color: "#000",
    },
    rating: {
      color: "orange",
    },
  })
);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { min: 1920 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 4000, min: 1025 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const NewArrival = ({ getProducts, getSuperMarketProducts, productList, countryselected, t }) => {
  const classes = useStyles();
  const location = useLocation();
  const [prodList, setProdList] = useState([]);

  useEffect(() => {
    if (location?.pathname == '/supermarket') {
      getSuperMarketProducts({
        limit: 10,
        country_id: countryselected.id ? countryselected.id : 0,
        sort: "created_at",
        category_slug: 'supermarket'
      });
    } else {
      getProducts({
        limit: 10,
        country_id: countryselected.id ? countryselected.id : 0,
        sort: "created_at",
      });
    }
  }, [countryselected, location]);

  useEffect(() => {
    setProdList(productList);
  }, [productList, prodList])

  const Products = useMemo(() => {
    return (
      <Carousel
        swipeable={false}
        style={{ direction: "ltr" }}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        // customTransition=".5"
        transitionDuration={500}
        className="carousel-container-ltr slider-carousel"
        containerClass="carousel-container"
      >
        {prodList?.map((product) => {
          return (
            <Grid
              container
              spacing={4}
              className="fixsizeitems"
              key={product}
            >
              <ProductCard item={product} t={t} />
            </Grid>
          );
        })}
        {location?.pathname == '/supermarket'
          ? <ViewAll
            href="/supermarket/products?search=&sort=created_at&order=desc"
            t={t}
          />
          :
          <ViewAll
            href="/products?search=&sort=created_at&order=desc"
            t={t}
          />
        }
      </Carousel>
    )
  }, [prodList])

  return (
    <Box component="div" m={1}>
      {prodList && prodList.length > 0 ? (
        <Box component="div" m={1} className={classes.sliderContainer}>
          <Typography variant="h3" component="h3" className={classes.title}>
            {t("New Arrivals")}
            <div className={classes.bottomBorder}></div>
          </Typography>
          {Products}
        </Box>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  productList: state.product.productList,
  countryselected: state.countries.selected_country,
});
export default connect(mapStateToProps, {
  getProducts,
  getSuperMarketProducts,
})(NewArrival);
