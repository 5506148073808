const initialState = {
  user: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        ...payload
      };
      case "LOGOUT":
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
