import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Link,
  Popper,
  Tab,
  Tabs,
  Chip,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  ArrowDownward,
  ArrowDownwardTwoTone,
  ArrowDropDownSharp,
  ArrowDropDownTwoTone,
  InfoSharp,
  RemoveRedEye,
  Search,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getOrderById, cancelOrder, returnOrder } from "actions/order";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import AlertBox from "components/Notifications/Alert";
import ConfirmDialog from "./ConfirmPopup";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    backgroundColor: "#ffffff",
    "& .MuiChip-clickable": {
      height: 28,
      margin: 4,
    },
    "& .MuiGrid-root.MuiGrid-container": {
      alignItems: "flex-start",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  mainContainer: {
    paddingTop: 25,
    paddingBottom: 10,
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  card: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 12%),0px 0px 0px 0px rgb(0 0 0 / 12%),0px 0px 0px 0px rgb(0 0 0 / 12%),0px 0px 0px 1px rgb(0 0 0 / 12%)",
    borderRadius: 10,
  },
  cardHeader: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 7,
    backgroundColor: "#F0F2F2",
  },
  actionBtn: {
    borderRadius: 8,
    minWidth: 140,
    boxShadow: "0 0px 3px 2px rgb(213 217 217 / 50%)",
    textAlign: "center",
    marginLeft: 8,
    fontWeight: 800,
  },
  amountBtn: {
    fontSize: 18,
    float: "right",
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
}));
const Details = ({
  t,
  getOrderById,
  cancelOrder,
  returnOrder,
  cancelOrderStatus,
  orderDetail,
  orderDetailLoading,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [view, setView] = useState(false);

  const dispatch = useDispatch();
  const history = useNavigate();
  const [order, setOrder] = useState({});
  const [open, setOpen] = React.useState(false);
  const [confirm, setConfirm] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderStatus, setOrderStatus] = useState("orders");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  useEffect(() => {
    params.id && getOrderById(params.id);
  }, [params, getOrderById]);

  useEffect(() => {
    cancelOrderStatus && getOrderById(params.id);
  }, [cancelOrderStatus]);

  useEffect(() => {
    setOrder(orderDetail);
  }, [orderDetail]);

  const handleProductClick = (item) => {
    localStorage.removeItem("country");
    dispatch({ type: "SELECTED_COUNTRY", payload: false });

    setTimeout(() => {
      history(`/products/${item?.url_key}`);
    }, 10);
  };

  const cancelOrderConfirm = (id) => {
    setConfirm({
      id: id,
      type: "cancel",
    });
  };

  const returnOrderConfirm = (id) => {
    setConfirm({
      id: id,
      type: "return",
    });
  };

  const handleVendorClick = (item) => {
    localStorage.removeItem("country");
    dispatch({ type: "SELECTED_COUNTRY", payload: false });

    setTimeout(() => {
      history(`/vendor/profile/${item?.url}`);
    }, 10);
  };

  if (!order?.id) {
    return null;
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.mainContainer} style={{ paddingBottom: 0 }}>
        <AlertBox />
        <p style={{ fontWeight: 600, lineHeight: 0, fontSize: 23 }}>
          {t("Order Details")}
        </p>
        <p className="orderinvoice">
          {t("Ordered on")} {moment(new Date(order.created_at)).format("ll")} |{" "}
          {t("Order")} #{order.id}
          <a
            target="_blank"
            style={{ textDecoration: "none", float: "right", fontSize: 17 }}
            href={`/my-orders/invoice/${order.id}`}
          >
            {t("View Invoice")}
            {/* <ArrowDropDownTwoTone /> */}
          </a>
        </p>
      </Grid>
      <Grid className={classes.mainContainer} style={{ paddingTop: 0 }}>
        <Card className={classes.card}>
          <CardContent>
            <Grid>
              <Grid container style={{ lineHeight: 2 }}>
                <Grid xs="4">
                  <h4>{t("Shipping Address")}</h4>
                  <p style={{ lineHeight: 1.4, marginTop: 3 }}>
                    <b>{order.shipping_address?.additional?.address_label}</b>
                    <br></br>
                    {order.shipping_address?.first_name}
                    {order.shipping_address?.last_name}
                    <br></br>
                    {order.shipping_address?.address1},{" "}
                    {order.shipping_address?.address2},{" "}
                    {order.shipping_address?.additional?.landmark}
                    <br></br>
                    {order.shipping_address?.city}
                    <br></br>
                    {order.shipping_address?.country}
                  </p>
                </Grid>
                <Grid xs="4">
                  <h4>{t("Payment Method")}</h4>
                  <p style={{ lineHeight: 1.4, marginTop: 3 }}>
                    <span>{order.payment_title}</span>
                  </p>
                </Grid>
                <Grid xs="4">
                  <h4>{t("Order Summary")}</h4>
                  <div>
                    <span>
                      <span>{t("Item(s) SubTotal")}:</span>
                      <span style={{ float: "right", paddingRight: 25 }}>
                        {order.formated_sub_total}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span>
                      <span>{t("Shipping")} :</span>
                      <span style={{ float: "right", paddingRight: 25 }}>
                        {order.formated_shipping_amount || "0"}
                      </span>
                    </span>
                  </div>
                  {order.base_discount_amount > 0 && (
                    <div>
                      <span>
                        <span>{t("Total Discount ")}:</span>
                        <span style={{ float: "right", paddingRight: 25 }}>
                          {"-"}
                          {order.formated_base_discount_amount || "0"}
                        </span>
                      </span>
                    </div>
                  )}
                  <div>
                    <span>
                      <span>{t("Deduct From Wallet")}:</span>
                      <span style={{ float: "right", paddingRight: 25 }}>
                        {"-"}
                        {order.wallet_amount || "0"}
                      </span>
                    </span>
                  </div>
                  <div>
                    <span>
                      <span>
                        <strong>{t("Grand Total")}:</strong>
                      </span>
                      <span style={{ float: "right", paddingRight: 25 }}>
                        {order.formated_grand_total}
                      </span>
                    </span>
                  </div>
                  {/* <div>
                    <span>
                      <strong>{t("Grand Total")}:</strong>
                      <span style={{ float: "right", paddingRight: 25 }}>
                        <strong>$220</strong>
                      </span>
                    </span>
                  </div> */}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {order.items.map((item) => (
        <Grid className={classes.mainContainer}>
          <Card className={classes.card}>
            <CardContent>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ marginBlockStart: 0 }}>
                  {item.status},{" "}
                  {order.delivered_at
                    ? moment(new Date(order.delivered_at)).format("ll")
                    : moment(new Date(order.updated_at)).format("ll")}
                </h3>
                <Box className={classes.amountBtn}>
                  {item.formated_grant_total}
                </Box>
              </div>
              <Grid container style={{ marginTop: 15 }}>
                <Grid xs="1">
                  <Link
                    onClick={() => handleProductClick(item?.product)}
                    to="#"
                    className={classes.link}
                  >
                    <img
                      width="70"
                      height="70"
                      src={item.product.base_image?.small_image_url}
                      className="orderProductImage"
                    />
                  </Link>
                </Grid>
                <Grid xs="8" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  <Link
                    onClick={() => handleProductClick(item?.product)}
                    to="#"
                    className={classes.link}
                  >
                    <h3 style={{ marginBlockStart: 0, marginBlockEnd: 3 }}>
                      {item.product?.name}
                    </h3>
                  </Link>
                  <p style={{ marginBlockStart: 0 }}>
                    {t("Sold by")}{" "}
                    <Link
                      onClick={() => handleVendorClick(item?.product.seller)}
                      to="#"
                      className={classes.link}
                    >
                      {item?.product.seller?.shop_title}
                    </Link>
                  </p>
                  <Grid
                    spacing="2"
                    style={{ display: "flex", marginTop: 10, marginBottom: 10 }}
                  >
                    {Object.keys(item.additional?.attributes || {}).map(
                      (attr) => (
                        <Chip
                          style={{ margin: 2 }}
                          label={
                            t(item.additional.attributes[attr].attribute_name) +
                            ": " +
                            item.additional.attributes[attr].option_label
                          }
                        />
                      )
                    )}
                    {item.qty_ordered && (
                      <Chip
                        style={{ margin: 2 }}
                        label={t("Qty") + ": " + item.qty_ordered}
                      />
                    )}
                  </Grid>

                  {order.selected_shipping_rate?.estimated_days && (
                    <p style={{ marginBlockStart: 0 }}>
                      {t("Arriving on")}{" "}
                      {moment(new Date(order.created_at))
                        .add(
                          "days",
                          order.selected_shipping_rate?.estimated_days
                        )
                        .format("ll")}
                      <Tooltip title="This is tentative date and it may change">
                        <IconButton size="large">
                          <InfoSharp style={{ width: 20, color: "blue" }} />
                        </IconButton>
                      </Tooltip>
                    </p>
                  )}
                  {order.status == "completed" && (
                    <p style={{ marginBlockStart: 0 }}>
                      {t("Return window closed on")}{" "}
                      {moment(new Date(order.delivered_at))
                        .add("days", 7)
                        .format("ll")}
                    </p>
                  )}
                  {order.otp && order.status !== "delivered" && (
                    <>
                      <span
                        style={{
                          fontSize: 17,
                          fontWeight: 550,
                          marginRight: 10,
                        }}
                      >
                        Delivery Otp :
                      </span>
                      {order.otp == view ? (
                        <span style={{ fontSize: 17 }}>{view}</span>
                      ) : (
                        <>
                          <span style={{ fontSize: 20, verticalAlign: "sub" }}>
                            *******
                            <span
                              style={{ margin: 6, cursor: "pointer" }}
                              onClick={() => setView(order.otp)}
                            >
                              <RemoveRedEye />
                            </span>
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Grid>
                <Grid xs="3">
                  <Box
                    onClick={() => handleProductClick(item.product)}
                    component="div"
                    className={classes.actionBtn}
                    sx={{ p: 1 }}
                  >
                    {t("Write a product review")}
                  </Box>

                  {item.can_cancel &&
                    item.status_label.toLowerCase() !== "completed" && (
                      <Box
                        component="div"
                        style={{ cursor: "pointer" }}
                        onClick={() => cancelOrderConfirm(item.id)}
                        className={classes.actionBtn}
                        sx={{ p: 1 }}
                      >
                        {t("Cancel Order")}
                      </Box>
                    )}
                  {item.can_return &&
                    item.status_label.toLowerCase() === "completed" && (
                      <Box
                        component="div"
                        style={{ cursor: "pointer" }}
                        onClick={() => returnOrderConfirm(item.id)}
                        className={classes.actionBtn}
                        sx={{ p: 1 }}
                      >
                        {t("Return Order")}
                      </Box>
                    )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}

      <ConfirmDialog
        onConfirm={() => {
          confirm.type === "return"
            ? returnOrder(confirm.id)
            : cancelOrder(confirm.id);
          setConfirm({});
        }}
        setOpen={() => setConfirm({})}
        open={confirm?.id}
        title="Are you sure ?"
        children={`${t("You want to " + confirm.type + " item")}, ${t(
          `This step can not be undone`
        )}`}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  orderDetailLoading: state.order.orderDetailLoading,
  orderDetail: state.order.orderDetail,
  cancelOrderStatus: state.order.cancelOrderStatus,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getOrderById,
    cancelOrder,
    returnOrder,
  })(Details)
);
