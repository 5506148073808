const initialState = {
    list: [],
    Loading: false,
    listError: false,
    meta:{ current_page: 1, total:1, per_page:10},
    balance: {amount: "$0"}
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case "WALLET_LIST":
        return {
          ...state,
          list: payload,
        };
      case "WALLET_BALANCE":{
        return {
            ...state,
            balance: payload[0],
          };
      }  
      case "WALLET_LIST_ERROR":
        return {
          ...state,
          list: payload,
          listError: true,
        };
      case "WALLET_LIST_META":
        return {
            ...state,
            meta: payload,
          };  
      case "WALLET_LIST_LOADING":
        return {
          ...state,
          Loading: payload,
        };
      default:
        return state;
    }
  }
  