import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import { createStyles, makeStyles } from "@mui/styles";
import CSC from "./CSC";
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  NativeSelect,
  Select,
} from "@mui/material";
import AlertBox from "components/Notifications/Alert";
import { createAddress } from "actions/cart";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: 15,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    linkcls: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      width: "100%",
      textAlign: "center",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    phoneinput: {
      width: "100% !important",
      height: "56px !important",
    },
    formControl: {
      // margin: theme.spacing(1),
      minWidth: 120,
      width: 340,
    },
  })
);
const defaultProps = {
  formData: {
    name: "",
    phone_code: "+91",
    phone_number: "",
    address1: "",
    address2: "",
    landmark: "",
    country: "Iraq",
    city: "",
    state: "",
    defaultAddress: true,
    address_label: "home",
  },
};

const AddressForm = (props) => {
  const classes = useStyles();
  const { t } = props;

  const [formData, setFormData] = useState(defaultProps.formData);

  const [errorObj, setErrorObj] = useState({
    name: { error: true, msg: t("It should be valid") },
    phone_number: { error: true, msg: t("It should be valid") },
    address1: { error: true, msg: t("It should be valid") },
    address2: { error: true, msg: t("It should be valid") },
    landmark: { error: true, msg: t("It should be valid") },
    country: { error: true, msg: t("It should be valid") },
    city: { error: true, msg: t("It should be valid") },
    state: { error: true, msg: t("It should be valid") },
    defaultAddress: { error: true, msg: "" },
    address_label: { error: true, msg: "" },
  });

  useEffect(() => {
    if (props.formData && props.formData.id) {
      let obj = props.formData;
      setFormData({
        id: obj.id,
        name: obj.first_name + obj.last_name,
        phone_code: obj.phone_code,
        phone_number: obj.phone_code + obj.phone,
        address1: obj.address1,
        address2: obj.address2,
        landmark: obj.additional?.landmark,
        country: obj.country,
        city: obj.city,
        state: obj.state,
        defaultAddress: obj.default_address,
        address_label: obj.additional?.address_label,
      });
    }
  }, [props.formData]);

  const handleChange = (e, key, value) => {
    let field = {};
    field[key] = typeof value !== "undefined" ? value : e?.target?.value;
    setFormData((fieldOb) => ({ ...fieldOb, ...field }));

    let errOb = {};
    errOb[key] = {
      error: validateField(key, value ? value : e?.target?.value),
      msg: errorObj[key].msg,
    };
    setErrorObj((er) => ({ ...er, ...errOb }));
  };

  const handleCSCChange = (value, key) => {
    handleChange("", key, value);
  };

  const validateField = (key, value) => {
    value = value ? value : formData[key];
    switch (key) {
      case "name":
      case "phone_number":
      case "address1":
      case "address2":
      case "landmark":
        return (
          typeof value !== "undefined" &&
          value !== "" &&
          value &&
          value.trim().length !== 0
        );
      case "country":
      case "city":
        return value && value.length;
      default:
        return true;
    }
  };

  const handleClick = () => {
    let requiredObj = [
      "name",
      "phone_number",
      "address1",
      "address2",
      "state",
      "country",
      "city",
    ];
    let errOb = errorObj;

    let status = true;
    requiredObj.forEach((element) => {
      let errorStatus = validateField(element);
      errOb[element].error = errorStatus;
      status = status && errorStatus;
    });
    setErrorObj((errorOb) => ({ ...errorOb, errOb }));
    if (!status) return;

    let createReq = {
      first_name: formData.name,
      last_name: "",
      address1: formData.address1,
      address2: formData.address2,
      landmark: formData.landmark,
      phone_code: "+" + formData.phone_code,
      city: formData.city,
      country: formData.country,
      phone: extractNumber(formData.phone_number, formData.phone_code),
      state: formData.city,
      default_address: formData.defaultAddress === false ? 0 : 1,
      additional: {
        address_label: formData.address_label,
        landmark: formData.landmark,
      },
    };
    if (formData.id) {
      props.updateAddress(createReq, formData.id);
    } else {
      props.handleAddressCreate(createReq);
    }
  };

  const extractNumber = (intlNumber, intlCode) => {
    if (intlNumber && intlCode) {
      const ICL = intlCode.length;
      const pos = intlNumber.indexOf(intlCode);
      if (pos !== -1) {
        return intlNumber.substr(ICL);
      }
    }
    return intlNumber || "";
  };

  const handlePhoneCode = (e, countryCode) => {
    setFormData({
      ...formData,
      phone_code: countryCode.dialCode,
      phone_number: e,
    });
  };

  return (
    <Grid className={classes.root}>
      <Typography variant="h2" gutterBottom>
        {props.title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={!errorObj.name.error}
            helperText={!errorObj.name.error && t(errorObj.name.msg)}
            id="name"
            value={formData.name}
            variant="outlined"
            name="name"
            onChange={(e) => handleChange(e, "name")}
            label={t("Name")}
            fullWidth
            autoComplete="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneInput
            value={formData.phone_number}
            onChange={handlePhoneCode}
            inputProps={{ name: "phone_code" }}
            inputClass={classes.phoneinput}
            enableSearch={true}
            country={"iq"}
          />
          {!errorObj.phone_number.error && (
            <span style={{ color: "red" }}>{t(errorObj.phone_number.msg)}</span>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="address1"
            name="address1"
            value={formData.address1}
            error={!errorObj.address1.error}
            helperText={!errorObj.address1.error && t(errorObj.address1.msg)}
            onChange={(e) => handleChange(e, "address1")}
            label={t("House no, Building, Area")}
            fullWidth
            variant="outlined"
            autoComplete="address1"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="address2"
            name="address2"
            error={!errorObj.address2.error}
            value={formData.address2}
            helperText={!errorObj.address2.error && t(errorObj.address2.msg)}
            onChange={(e) => handleChange(e, "address2")}
            label={t("Locality/Town")}
            fullWidth
            autoComplete="address2"
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} spacing={3}>
          <CSC
            values={{
              country: formData.country || "",
              state: formData.state || "",
              city: formData.city || "",
            }}
            handleChange={handleCSCChange}
            errorObj={errorObj}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="landmark"
            name="landmark"
            value={formData.landmark}
            error={!errorObj.landmark.error}
            helperText={!errorObj.landmark.error && t(errorObj.landmark.msg)}
            onChange={(e) => handleChange(e, "landmark")}
            label={t("Landmark")}
            fullWidth
            autoComplete="landmark"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-age-native-simple">
              {t("Address Type")}
            </InputLabel>
            <Select
              native
              value={formData.address_label}
              onChange={(e) => handleChange(e, "address_label")}
              label="Address Type"
              inputProps={{
                name: t("Address Type"),
                id: "outlined-age-native-simple",
              }}
            >
              <option value={t("Home")}>{t("Home")}</option>
              <option value={t("Office")}>{t("Office")}</option>
              <option value={t("Others")}>{t("Others")}</option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                onChange={(e) =>
                  handleChange(e, "defaultAddress", e.target.checked)
                }
                checked={formData.defaultAddress}
                name="defaultAddress"
              />
            }
            label={t("Make this my default address")}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="secondary" name="billingAddress" value="yes" />
            }
            label="Check if the billing address is same"
          />
        </Grid> */}
        <Grid item xs={12}>
          {props.createAddressLoader ? (
            <CircularProgress />
          ) : (
            <>
              <Grid item container>
                <Button
                  onClick={props.cancelAddress}
                  style={{
                    marginLeft: "15%",
                    color: "white",
                    backgroundColor: "red",
                  }}
                  color="primary"
                  variant="contained"
                >
                  {t("Cancel Address")}
                </Button>
                <Button
                  onClick={handleClick}
                  style={{ marginLeft: "5%" }}
                  color="primary"
                  variant="contained"
                >
                  {formData.id ? t("Update Address") : t("Add Address")}
                </Button>
              </Grid>
            </>
          )}
          <AlertBox />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withTranslation()(AddressForm);
