import React from "react";
import LazyLoad from "react-lazyload";

import { Container, Grid } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import "../../main.css";

import Banner from "components/Home/Bannerslider";
import FeatureVendor from "components/Home/FeatureVendor";
import FeatureProduct from "components/Home/FeatureProduct";
import NewArrival from "./NewArrival";
import BecomeSeller from "./BecomeSeller";
import DealOfTheDaySlider from "./DealOfTheDaySlider";
import { withTranslation } from "react-i18next";
import TopSellinProduct from "./TopSellinProduct";
import RandomProduct from "./RandomProduct";
import MiniBanner from "./MiniBanner";
import Simmer from "components/common/skeleton";
import MetaComponent from "components/common/MetaComponent";

const props = {
  width: 400,
  height: 250,
  zoomWidth: 500,
  img: "https://ik.imagekit.io/sooqiraq/product/413/bEFCu0Igs9BLKlDMgs34qzUpboCGN9xk3lQ19V2k.jpg",
  zoomPosition: "original",
};
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: "0 !important",
    },
  })
);

const Home = ({ t }) => {
  const classes = useStyles();
  const baseurl = window.location.href+'logo.png';
  return (
    <Container className={classes.root} maxWidth={false} disableGutters={true}>
      <Grid container>
        <Grid container item xs={12} sm={12}>
          <Container
            className={classes.slider}
            disableGutters={true}
            maxWidth={false}
          >
            <MetaComponent
              title={t("HOME_PAGE_TITLE")}
              description={t("HOME_PAGE_DESCRIPTION")}
              image={baseurl}
            />
            <Banner />
            <MiniBanner t={t} />
            <LazyLoad
              height={150}
              offset={60}
              placeholder={<Simmer total={6} />}
            >
              <NewArrival t={t} />
            </LazyLoad>
            <BecomeSeller />
            <LazyLoad
              height={200}
              offset={50}
              placeholder={<Simmer total={6} />}
            >
              <FeatureProduct t={t} />
              <DealOfTheDaySlider />
            </LazyLoad>
            <LazyLoad
              height={200}
              offset={50}
              placeholder={<Simmer total={6} />}
            >
              <FeatureVendor t={t} />
            </LazyLoad>
            <LazyLoad
              height={200}
              offset={50}
              placeholder={<Simmer total={6} />}
            >
              <TopSellinProduct t={t} />
            </LazyLoad>
            <LazyLoad
              height={200}
              offset={50}
              placeholder={<Simmer total={6} />}
            >
              <RandomProduct isHome={true} t={t} />
            </LazyLoad>
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withTranslation()(Home);
