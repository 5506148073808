import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function Media(props) {
  return (
    <div>

        <Grid container spacing={3}>
            {Array.from(new Array(props.total || 3)).map((item, index) => (
                <Grid item xs={4}>
                    <Skeleton variant="rectangular" width={370} height={240} p="4"/>
                        <Box pt={0.5}>
                            <Skeleton width="80%" />
                            <Skeleton width="60%" />
                        </Box>
                        </Grid>

            ))}  
        </Grid>
  </div>
  );
}

export default function Simmer(props) {
  return (
    <Box overflow="hidden">
      <Media loading {...props} />
    </Box>
  );
}
