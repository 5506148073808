const initialState = {
  list: {
    main_banner: [],
    become_seller: [],
    deal_of_the_day: [],
    category_banner: [],
    brand: [],
  },
  Loading: false,
  listError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "BANNER_LIST":
      return {
        ...state,
        list: payload,
      };
    case "BANNER_LIST_ERROR":
      return {
        ...state,
        list: payload,
        listError: true,
      };
    case "BANNER_LIST_LOADING":
      return {
        ...state,
        Loading: payload,
      };
    default:
      return state;
  }
}
