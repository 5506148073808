import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { applyCoupon, removeCoupon } from "actions/cart";
import ConfirmDialog from "components/orders/ConfirmPopup";

import { Grid, Button, TextField } from "@mui/material";
const Coupon = ({ coupon_code, applyCoupon, removeCoupon, t }) => {
    const [code, setCode] = useState("");
    const [confirm, setConfirm] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        applyCoupon({ "code": code });
    }
    const handleRemoveCoupon = () => {
        setCode("");
        removeCoupon();
    }
    return (
        <>
            {coupon_code && coupon_code.length > 0 ? (<Grid item justify="space-between"><b>{t('Coupon applied')} <u>{coupon_code}</u> </b>
                <Button
                    size="small"
                    color="secondary"
                    style={{
                        fontWeight: 600,
                        fontSize: 15,
                        color: "red",

                    }}
                    onClick={() => setConfirm(true)}
                >
                    {t("Remove")}
                </Button></Grid>) : (
                <Grid container spacing={2}>

                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            label="Coupon code"
                            variant="outlined"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} align='center' alignItems="center" style={{display:"flex"}}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            onClick={handleSubmit}
                        >Apply
                        </Button>
                    </Grid>

                </Grid>

            )}
            <ConfirmDialog
                onConfirm={() => {
                    handleRemoveCoupon();
                    setConfirm(false);
                }}
                setOpen={() => setConfirm(false)}
                open={confirm}
                title={t("Are you sure ?")}
                children={`${t("You want to remove coupon")}, ${t(
                    `This step can not be undone`
                )}`}
            />
        </>
    );
};

const mapStateToProps = (state) => ({});
export default withTranslation()(
    connect(mapStateToProps, {
        applyCoupon,
        removeCoupon
    })(Coupon)
);