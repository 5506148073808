import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import Alert from "../Notifications/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ececec",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const PaymentOptions = ({
  open = false,
  savePaymentMethod = () => {},
  onUpdate = () => {},
  onClose = () => {},
  methods = [],
  t = () => {},
}) => {
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const updatePaymentMethod = (method) => {
    setLoading(true);
    // onUpdate(method);
    savePaymentMethod(method).then((res) => {
      if (res === false || !res) {
        // Show unable to place the order, retry
        setLoading(false);
      } else {
        setLoading(false);
        if (res.redirect_url) {
          window.location.href = res.redirect_url;
        } else {
          history("/payment/success", {
            state: { orderID: res.order.id, amount: res.order.base_grand_total },
          });
        }
      }
    });
  };

  return (
    <Modal
      keepMounted
      open={open}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography id="keep-mounted-modal-title" variant="h4">
            {t("Select payment method")}
          </Typography>
          <IconButton onClick={onClose} size="large">
            <CancelOutlined />
          </IconButton>
        </Box>
        <Alert />
        {loading && (
          <Box p={3} style={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box id="keep-mounted-modal-description">
            {methods.map((option) => {
              return (
                <Box p={1} key={option.method}>
                  <Button
                    variant="outlined"
                    color="primary"
                    // startIcon={<DollerIcon/>}
                    className={option.method == "zaincash" ? "zaincash" : "cod"}
                    onClick={() => updatePaymentMethod(option.method)}
                  >
                    {option.method_title}
                  </Button>
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PaymentOptions;
