import axios from "axios";
import { BASE_URL, authHeader, handleCatchException } from "../apihelper";

export const getSuperMarketCategory = () => async (dispatch) => {
    try {
      dispatch({
        type: "SUPERMARKET_CATEGORY_LIST_LOADING",
        payload: true,
      });
      const response = await axios.get(`${BASE_URL}/supermarket/categories`, authHeader());
  
      if (response?.data?.data) {
        dispatch({
          type: "SUPERMARKET_CATEGORY_LIST",
          payload: response.data?.data,
        });
      } else {
        dispatch({
          type: "SUPERMARKET_CATEGORY_LIST_ERROR",
          payload: null,
        });
      }
      dispatch({
        type: "SUPERMARKET_CATEGORY_LIST_LOADING",
        payload: false,
      });
    } catch (error) {
      return handleCatchException(error);
    }
  };