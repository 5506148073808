import axios from "axios";
import { convertObjectIntoQueryString } from "utils/global";
import {
  BASE_URL,
  authHeader,
  handleCatchException,
  hideloader,
  showloader,
  errormsg,
  successmsg,
} from "../apihelper";

export const createOrder = (data, history) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATING_ORDER",
      payload: true,
    });
    const response = await axios.post(
      `${BASE_URL}/checkout/save-order`,
      data,
      authHeader()
    );
    if (response?.data?.redirect_url) {
      // successmsg("Order created successfully");
      dispatch({
        type: "CREATING_ORDER",
        payload: false,
      });
      dispatch({
        type: "CREATE_ORDER_REDIRECT",
        payload: response?.data?.redirect_url,
      });
    } else {
      errormsg(response?.data?.error?.message || "Unable to create order");
      dispatch({
        type: "CREATING_ORDER",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "CREATING_ORDER",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const makePayment = (nonce, history) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATING_ORDER",
      payload: true,
    });
    const response = await axios.get(
      `${BASE_URL}/braintree/payment/transaction?payload[nonce]=${nonce}`,
      authHeader()
    );
    if (response?.data?.order) {
      // successmsg("Order created successfully");
      dispatch({
        type: "CREATING_ORDER",
        payload: false,
      });
      dispatch({
        type: "CART_DETAIL",
        payload: false,
      });
      history.push("/order/success");
    } else {
      errormsg(response?.data?.error?.message || "Unable to create order");
      dispatch({
        type: "CREATING_ORDER",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "CREATING_ORDER",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const createPayment = (redirect_url) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATING_PAYMENT",
      payload: true,
    });
    const response = await axios.get(`${redirect_url}`, authHeader());
    if (response?.data?.clientToken) {
      // successmsg("Order created successfully");
      dispatch({
        type: "CREATING_PAYMENT",
        payload: false,
      });
      dispatch({
        type: "CLIENT_TOKEN",
        payload: response?.data?.clientToken,
      });
    } else {
      errormsg(response?.data?.error?.message || "Unable to create payment");
      dispatch({
        type: "CREATING_PAYMENT",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "CREATING_PAYMENT",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const resetOrderRedirect = () => (dispatch) => {
  dispatch({
    type: "RESET_ORDER_REDIRECT",
  });
};
export const getOrders = (data) => async (dispatch) => {
  try {
    dispatch({
      type: "ORDER_LIST_LOADING",
      payload: true,
    });
    // showloader()
    const response = await axios.get(
      `${BASE_URL}/orders?${convertObjectIntoQueryString(data)}`,
      authHeader()
    );

    // hideloader();
    if (!response?.data?.data) {
      dispatch({
        type: "ORDER_LIST_LOADING",
        payload: false,
      });

      errormsg(response?.data?.error?.message || "Unable to View order");
      return;
    }
    if (response?.data?.data) {
      dispatch({
        type: "ORDER_LIST_LOADING",
        payload: false,
      });

      dispatch({
        type: "ORDER_LIST",
        payload: response.data.data,
      });
      dispatch({
        type: "ORDER_LIST_METADATA",
        payload: response.data.meta,
      });
    } else {
      errormsg(response?.data?.error?.message || "Unable to view order");
      dispatch({
        type: "ORDER_LIST_LOADING",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "ORDER_LIST_LOADING",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const getOrderById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "ORDER_DETAIL_LOADING",
      payload: true,
    });
    showloader();
    const response = await axios.get(`${BASE_URL}/orders/${id}?`, authHeader());

    hideloader();
    if (!response?.data?.data) {
      dispatch({
        type: "ORDER_DETAIL_LOADING",
        payload: true,
      });

      errormsg(response?.data?.error?.message || "Unable to View order");
      return;
    }
    if (response?.data?.data) {
      dispatch({
        type: "ORDER_DETAIL_LOADING",
        payload: true,
      });

      dispatch({
        type: "ORDER_DETAIL",
        payload: response.data.data,
      });
      dispatch({
        type: "ORDER_DETAIL_METADATA",
        payload: response.data.meta,
      });
    } else {
      errormsg(response?.data?.error?.message || "Unable to view order");
      dispatch({
        type: "ORDER_DETAIL_LOADING",
        payload: true,
      });
    }
  } catch (error) {
    dispatch({
      type: "CREATING_ORDER",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const cancelOrder = (id) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.get(
      `${BASE_URL}/orders/item/cancel/${id}?`,
      authHeader()
    );

    hideloader();
    if (!response?.data?.status) {
      errormsg(response?.data?.error?.message || "Unable to Cancel order");
      return;
    }
    if (response?.data?.status) {
      dispatch({
        type: "CANCEL_ORDER_SUCCESS",
        payload: true,
      });
      successmsg("Item Cancelled successfully");
    } else {
      errormsg(response?.data?.error?.message || "Unable to Cancel order");
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const returnOrder = (id) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.get(
      `${BASE_URL}/orders/item/return/${id}?`,
      authHeader()
    );

    hideloader();
    if (!response?.data?.status) {
      errormsg(response?.data?.error?.message || "Unable to Return order");
      return;
    }
    if (response?.data?.status) {
      dispatch({
        type: "CANCEL_ORDER_SUCCESS",
        payload: true,
      });
      successmsg("Request sent for return");
    } else {
      errormsg(response?.data?.error?.message || "Unable to Return order");
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
