import React, { useEffect, Fragment, useCallback } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { getBanner, getSuperMarketBanner } from "actions/banner";
import { connect } from "react-redux";
import Simmer from "components/common/skeleton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/material";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    sliders: {
      direction: "ltr",
      height: 330,
      [theme.breakpoints.down('lg')]: {
        height: "initial",
      },
    },
  })
);

const Bannerslider = ({
  main_banner,
  selected_country,
  getBanner,
  getSuperMarketBanner,
  loading,
}) => {
  const classes = useStyles();
  const location = useLocation();
  let navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    if (location?.pathname == '/supermarket') {
      getSuperMarketBanner();
    } else {
      getBanner();
    }
  }, [location]);

  const handleRedirect = useCallback((url) => {
    const path = location.pathname;
    if (path.startsWith('/supermarket')) {
      if (url.split('/')[0] == '') {
        navigate(url);
      } else {
        navigate(`/${url}`)
      }
    }
  }, []);

  return (
    <div>
      <div>
        {loading ? (
          <Simmer total={6} />
        ) : (
          <Carousel
            arrows={false}
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            className={classes.sliders}
          >
            {main_banner?.length > 0 && main_banner.map((item, i) => (
              <Fragment key={i}>
                {item.action ? (
                  location?.pathname == '/supermarket' ?
                    (<div onClick={(e) => handleRedirect(item.action)} style={{ cursor: "pointer" }}>
                      <img
                        src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                        key={i}
                        width="100%"
                      />
                    </div>) :
                    (<Link to={item.action}>
                      <img
                        src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                        key={i}
                        width="100%"
                      />
                    </Link>)
                ) : (
                  <img
                    src={item.image_url + "?tr=w-1440,h-422,cm-pad_resize"}
                    key={i}
                    width="100%"
                  />
                )}
              </Fragment>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  main_banner: state.banner.list.main_banner,
  selected_country: state.countries.selected_country,
  loading: state.banner.Loading,
});
export default connect(mapStateToProps, {
  getBanner,
  getSuperMarketBanner
})(Bannerslider);
