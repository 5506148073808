
import React from "react";
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: "rgba(0,0,0,0.25)",
      textAlign: "center",
    },
    copyText: {
      color: "#fff",
      fontSize: 12,
    },
  })
);
const CopyrightData = ({ t }) => {
  const classes = useStyles();
  const year = new Date().getFullYear();
  return (
    <Box className="black-bg w-100">
      <Box className={classes.root} width={1} alignItems="center">
        <Typography component="p" className={classes.copyText}>
          {t("Copyright",{year})}
        </Typography>
      </Box>
    </Box>
  );
};

export default CopyrightData;
