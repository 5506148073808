import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
  Popper,
  Tab,
  Tabs,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getOrders, cancelOrder, returnOrder } from "actions/order";
import Moment from "moment";
import { Pagination } from "@mui/material";
import AlertBox from "components/Notifications/Alert";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    backgroundColor: "#ffffff",
    "& .MuiChip-clickable": {
      height: 28,
      margin: 4,
    },
    "& .MuiGrid-root.MuiGrid-container": {
      alignItems: "flex-start",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  mainContainer: {
    paddingTop: 15,
    paddingBottom: 10,
    width: "70%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  card: {
    boxShadow:
      "0px 0px 0px 1px rgb(0 0 0 / 12%),0px 0px 0px 0px rgb(0 0 0 / 12%),0px 0px 0px 0px rgb(0 0 0 / 12%),0px 0px 0px 1px rgb(0 0 0 / 12%)",
    borderRadius: 10,
  },
  cardHeader: {
    paddingLeft: 10,
    paddingTop: 10,
    paddingRight: 7,
    backgroundColor: "#F0F2F2",
  },
  actionBtn: {
    borderRadius: 8,
    minWidth: 140,
    boxShadow: "0 0px 3px 2px rgb(213 217 217 / 50%)",
    textAlign: "center",
    fontWeight: 800,
  },
  link: {
    textDecoration: "none",
    cursor: "pointer",
  },
}));
const Orders = ({
  t,
  getOrders,
  orderListLoading,
  orderList,
  orderListMetaData,
  cancelOrder,
  returnOrder,
  cancelOrderStatus,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderStatus, setOrderStatus] = useState(
    "pending,processing,completed,return,out for delivery"
  );
  const [shippingAddress, setShippingAddress] = useState({});
  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const [paginationProps, setPagination] = useState({
    current_page: 1,
    per_page: 4,
  });
  const [orders, setOrders] = useState([]);
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  useEffect(() => {
    apiCall();
  }, [getOrders, orderStatus]);

  const apiCall = (options = {}) => {
    let params = {
      page: paginationProps.current_page,
      limit: paginationProps.per_page,
    };
    if (orderStatus) {
      params["status"] = orderStatus;
    }
    if (search) {
      params["search"] = search;
      params["page"] = 1;
    }
    params = { ...params, ...options };
    getOrders(params);
  };
  useEffect(() => {
    setPagination(orderListMetaData);
  }, [orderListMetaData]);

  const handlePaginationChange = (e, page) => {
    apiCall({ page: page });
  };

  useEffect(() => {
    cancelOrderStatus && apiCall();
  }, [cancelOrderStatus]);

  const handleSearch = () => {
    if (search) {
      setShowSearch(true);
      apiCall();
    }
  };
  useEffect(() => {
    setOrders(orderList);
  }, [orderList]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleProductClick = (item) => {
    localStorage.removeItem("country");
    dispatch({ type: "SELECTED_COUNTRY", payload: false });

    setTimeout(() => {
      history(`/products/${item?.url_key}`);
    }, 10);
  };

  return (
    <Grid className={classes.root}>
      {orderListLoading && (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" disableShrink />
        </Backdrop>
      )}

      <Grid
        container
        className={classes.mainContainer}
        style={{ marginTop: 30, display: "flex" }}
      >
        <Grid xs="6">
          <h2 style={{ fontSize: 24 }}>{t("My orders")}</h2>
        </Grid>
        <Grid xs="6" style={{ float: "right" }}>
          <FormControl
            className="orderformcontrol"
            variant="standard"
            style={{ width: "auto" }}
          >
            <TextField
              id="input-with-icon-textfield"
              // label="TextField"
              InputProps={{
                style: { height: 32 },
                value: search,
                onKeyDown: (e) => handleKeyDown(e),
                onChange: (e) => setSearch(e.target.value),
                placeholder: t("Search by product name/orderid"),
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{ width: 295, marginRight: 10 }}
              variant="outlined"
            />
          </FormControl>
          <Button
            onClick={handleSearch}
            size="small"
            color="primary"
            className="text-white"
            variant="contained"
          >
            {t("Search orders")}
          </Button>
        </Grid>
        <Divider />
      </Grid>
      <Grid className={classes.mainContainer}>
        <AlertBox />
        {search && showSearch && (
          <h2 style={{ display: "inline-flex" }}>
            {t("Search results for")} "{search}"{" "}
            <Close
              style={{ cursor: "pointer", marginLeft: 5, color: "red" }}
              onClick={() => {
                setShowSearch(false);
                setSearch("");
                apiCall({ search: "" });
              }}
            />
          </h2>
        )}
        <Tabs
          value={orderStatus}
          onChange={(e, value) =>
            value == "" ? setOrderStatus("") : setOrderStatus(value)
          }
          textColor="primary"
          indicatorColor="primary"
          style={{ fontSize: 16 }}
          aria-label="secondary tabs example"
        >
          <Tab
            style={{
              fontSize: 16,
              fontWeight: orderStatus === "" ? 600 : 0,
            }}
            value="pending,processing,completed,return,out for delivery"
            label={t("Orders")}
          />
          <Tab
            style={{
              fontSize: 16,
              fontWeight: orderStatus === "processing" ? 600 : 0,
            }}
            value="processing"
            label={t("Not Yet Dispatched")}
          />
          <Tab
            style={{
              fontSize: 16,
              fontWeight: orderStatus === "canceled" ? 600 : 0,
            }}
            value="canceled,closed"
            label={t("cancelled")}
          />
        </Tabs>
        <Divider />
      </Grid>
      {orders && orders.length ? (
        <>
          {orders.map((itm) => (
            <Grid className={classes.mainContainer}>
              <Card className={classes.card}>
                <CardContent>
                  <h3 style={{ marginBlockStart: 0 }}>
                    {itm.status_label},{" "}
                    {itm.delivered_at
                      ? Moment(new Date(itm.delivered_at)).format("ll")
                      : Moment(new Date(itm.updated_at)).format("ll")}
                  </h3>
                  <Grid container style={{ marginTop: 15 }}>
                    <Grid xs="1">
                      <Link
                        style={{ textDecoration: "none" }}
                        className={classes.link}
                        onClick={() => handleProductClick(itm.items)}
                        to={`/my-orders/${itm.id}`}
                      >
                        <img
                          width="70"
                          height="70"
                          src={itm.items?.image}
                          style={{ cursor: "pointer" }}
                          className="orderProductImage"
                        />
                      </Link>
                    </Grid>
                    <Grid xs="8" style={{ paddingLeft: 10 }}>
                      <h3 style={{ marginBlockStart: 0, marginBlockEnd: 3 }}>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/my-orders/${itm.id}`}
                        >
                          {t("Order")} #{itm?.id}
                        </Link>
                      </h3>
                      <Link
                        onClick={() => handleProductClick(itm.items)}
                        to="#"
                        className={classes.link}
                      >
                        <h3 style={{ marginBlockStart: 0, marginBlockEnd: 3 }}>
                          {itm.items?.name}
                        </h3>
                      </Link>
                      <p style={{ marginBlockStart: 0 }}>
                        {itm.formated_grand_total}
                      </p>
                      <p style={{ marginBlockStart: 0 }}>
                        {Moment(new Date(itm.created_at)).format("ll")}
                      </p>
                      <Grid
                        spacing="2"
                        style={{
                          display: "flex",
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      ></Grid>
                    </Grid>
                    <Grid xs="3">
                      <Grid>
                        <Link
                          to={`/my-orders/${itm.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            component="div"
                            className={classes.actionBtn}
                            sx={{ p: 1 }}
                          >
                            {t("View order detail")}
                          </Box>{" "}
                        </Link>
                        <Link
                          to={`/my-orders/invoice/${itm.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Box
                            component="div"
                            className={classes.actionBtn}
                            sx={{ p: 1 }}
                          >
                            {t("View Invoice")}
                          </Box>{" "}
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid className={classes.mainContainer}>
            <Pagination
              variant="outlined"
              page={paginationProps.current_page}
              onChange={handlePaginationChange}
              count={Math.ceil(
                paginationProps.total / paginationProps.per_page
              )}
              shape="rounded"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid className={classes.mainContainer}>
            {t("No orders available")}
          </Grid>
        </>
      )}
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        <Box
          style={{ boxShadow: "0 0px 3px 2px rgb(213 217 217 / 50%)" }}
          sx={{ border: 3, p: 2, bgcolor: "background.paper" }}
        >
          <b>
            {shippingAddress.first_name} {shippingAddress.last_name}
          </b>
          <br></br>
          {shippingAddress.address1} <br></br>
          {shippingAddress.address2} <br></br>
          {shippingAddress.city} {shippingAddress.state}{" "}
          {shippingAddress.postcode} <br></br>
          {shippingAddress.country} <br></br>
          Phone:{" "}
          <b>
            {shippingAddress.phone_code}
            {shippingAddress.phone}
          </b>
        </Box>
      </Popper>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  orderListMetaData: state.order.orderListMetaData,
  orderList: state.order.orderList,
  orderListLoading: state.order.orderListLoading,
  cancelOrderStatus: state.order.cancelOrderStatus,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getOrders,
    cancelOrder,
    returnOrder,
  })(Orders)
);
