import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./invoice.css";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getOrderById } from "actions/order";
import { useParams } from "react-router";
import Moment from "moment";
import ReactToPrint from "react-to-print";

const useStyles = makeStyles((theme) => ({
  brandSection: {
    // backgroundColor: "lightslategrey" || theme.palette.primary.main,
    padding: "10px 40px",
  },
}));
const Invoice = (props) => {
  const classes = useStyles();
  const componentRef = useRef();
  const params = useParams();
  const t = props.t;
  const downloadPdfDocument = (rootElementId) => {
    const input = document.getElementById(rootElementId);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        // unit: "in",
        // format: [4, 2]
      });
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("download.pdf");
    });
  };

  useEffect(() => {
    params.id && props.getOrderById(params.id);
  }, [params, props.getOrderById]);

  if (!props?.orderDetail?.id) {
    return null;
  }

  const orderDetail = props.orderDetail;
  return (
    <>
      <div
        className="invoiceAr"
        style={{ width: "80%", marginLeft: "auto", marginTop: 30 }}
        ref={componentRef}
      >
        <h2 style={{ marginBottom: 15 }}>{t("Invoice Details")}</h2>
        <div id="invoice" className="invoice">
          <div class="container">
            <div className={"body-section"}>
              <div class="row">
                <div class="col-6">
                  <img src="/logo.png" width="150px" height="53px" />

                  {/* <h1 class="text-white">SaharaGo</h1> */}
                </div>
                <div class="col-6">
                  <div class="company-details">
                    <p>{orderDetail?.vendor?.name}</p>
                    <p>
                      {orderDetail?.vendor?.address1}{" "}
                      {orderDetail?.vendor?.address2}
                    </p>
                    <p>
                      {orderDetail?.vendor?.city} {orderDetail?.vendor?.state}{" "}
                      {orderDetail?.vendor?.country}
                    </p>
                    <p>
                      {orderDetail?.vendor?.phone_code}{" "}
                      {orderDetail?.vendor?.phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="body-section">
              <div class="row">
                <div class="col-6">
                  <h2 class="heading">
                    {t("Order Id")}: {orderDetail.id}
                  </h2>
                  {/* <p class="sub-heading">Tracking No. fabcart2025 </p> */}
                  <p class="sub-heading">
                    <b>{t("Order Date")}:</b>{" "}
                    {Moment(new Date(orderDetail.created_at)).format("L")}{" "}
                  </p>
                  <p class="sub-heading">
                    <b>{t("Full Name")}:</b>{" "}
                    {orderDetail.shipping_address?.first_name}{" "}
                    {orderDetail.shipping_address?.last_name}
                  </p>
                  <p class="sub-heading">
                    <b>{t("Email Address")}:</b>{" "}
                    {orderDetail.billing_address?.email}{" "}
                  </p>
                </div>
                <div class="col-6">
                  <p class="sub-heading">
                    <b>{t("Billing Address")}:</b>{" "}
                    {orderDetail.billing_address?.first_name}
                    {orderDetail.billing_address?.last_name}
                    <br />
                    {orderDetail.billing_address?.address1},{" "}
                    {orderDetail.billing_address?.additional?.landmark}{" "}
                    {orderDetail.billing_address?.city},{" "}
                    {orderDetail.billing_address?.country},{" "}
                    {orderDetail.billing_address?.postcode}(
                    {orderDetail.billing_address?.additional?.address_label})
                  </p>
                  <p class="sub-heading">
                    <b>{t("Shipping Address")}:</b>{" "}
                    {orderDetail.shipping_address?.first_name}
                    {orderDetail.shipping_address?.last_name}
                    <br />
                    {orderDetail.shipping_address?.address1},{" "}
                    {orderDetail.shipping_address?.additional?.landmark}{" "}
                    {orderDetail.shipping_address?.city},{" "}
                    {orderDetail.shipping_address?.country},{" "}
                    {orderDetail.shipping_address?.postcode}(
                    {orderDetail.shipping_address?.additional?.address_label})
                  </p>
                  <p class="sub-heading">
                    <b>{t("Phone Number")}:</b>{" "}
                    {orderDetail.shipping_address?.phone_code}{" "}
                    {orderDetail.shipping_address?.phone}{" "}
                  </p>
                </div>
              </div>
            </div>

            <div class="body-section">
              <h3 class="heading">{t("Ordered Items")}</h3>
              <br></br>
              <table class="table-bordered">
                <thead>
                  <tr>
                    <th>{t("Product")}</th>
                    <th class="w-20">{t("Price")} </th>
                    <th class="w-20">{t("Quantity")}</th>
                    <th class="w-20">{t("Grand Total")} </th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetail.items.map((item) => (
                    <tr>
                      <td>{item.product.name}</td>
                      <td class="w-20">{parseFloat(item.price).toFixed(2)}</td>
                      <td class="w-20">{item.qty_ordered}</td>
                      <td class="w-20">{parseFloat(item.total).toFixed(2)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colspan="3"
                      class="text-right"
                      style={{ paddingRight: 5 }}
                    >
                      {t("Sub Total")}
                    </td>
                    <td> {orderDetail.formated_sub_total}</td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      class="text-right"
                      style={{ paddingRight: 5 }}
                    >
                      {t("Shipping Amount")}
                    </td>
                    <td> {orderDetail.formated_shipping_amount}</td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      class="text-right"
                      style={{ paddingRight: 5 }}
                    >
                      {t("Total Discount")}
                    </td>
                    <td>
                      {"-"}
                      {orderDetail.formated_discount_amount}
                    </td>
                  </tr>
                  {/* <tr>
                  <td colspan="3" class="text-right">
                    Tax Total %1X
                  </td>
                  <td> 2</td>
                </tr> */}
                  <tr>
                    <td
                      colspan="3"
                      class="text-right"
                      style={{ paddingRight: 5 }}
                    >
                      {t("Deduct From Wallet")}
                    </td>
                    <td>
                      {" "}
                      {"-"}
                      {orderDetail.wallet_amount}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colspan="3"
                      class="text-right"
                      style={{ paddingRight: 5 }}
                    >
                      {t("Grand Total")}
                    </td>
                    <td> {orderDetail.formated_grand_total} </td>
                  </tr>
                </tbody>
              </table>
              <br></br>
              {/* <h3 class="heading">Payment Status: Paid</h3> */}
              <h3 class="heading">
                {t("Payment Mode")}: {orderDetail.payment_title}
              </h3>
            </div>

            <div class="body-section">
              <p>
                &copy; {t("Copyright 2022 - Sooqiraq. All rights reserved")}.
                <a href="https://sooqiraq.com/" class="float-right">
                  www.sooqiraq.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: "47%", margin: "auto" }}>
        <ReactToPrint
          trigger={() => (
            <Button
              color="primary"
              style={{ float: "right", marginTop: 20 }}
              variant="contained"
            >
              {t("Print")}
            </Button>
          )}
          content={() => componentRef.current}
        />
        {/* <Button
            color="primary"
            style={{ float: "right", marginTop: 20}}
            variant="contained"
            onClick={(e) => downloadPdfDocument("invoice")}
        >
            {t("download")}
        </Button> */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  orderDetailLoading: state.order.orderDetailLoading,
  orderDetail: state.order.orderDetail,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getOrderById,
  })(Invoice)
);
