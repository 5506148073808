import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Share } from "@mui/icons-material";
import Sharepopup from "components/common/sharepopup";

const shareBox = {
  background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
  borderRadius: 3,
  border: 0,
  color: "white",
  padding: "0 30px",
  boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  position: "absolute",
};

const Gallery = (props) => {
  const [visibleShare, setVisibleSHare] = useState({});
  const toggleVisibleSHare = (index) => {
    setVisibleSHare({ ...visibleShare, [index]: !visibleShare[index] });
  };
  return (
    <Grid id="gallery">
      <h3>{props.t("Product Views")}</h3>
      <Grid style={{ marginTop: 20, marginBottom: 20 }}>
        {props.images &&
          props.images.map((itm, index) => (
            <>
              <Grid
                style={{
                  padding: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid>
                  <img
                    style={{
                      minWidth: 350,
                      minHeight: 300,
                      maxWidth: 800,
                      maxHeight: 800,
                    }}
                    src={itm.large_image_url || itm.medium_image_url}
                  />
                </Grid>
                <Grid className="shareiconup" style={{ float: "right" }}>
                  <Share
                    style={{
                      cursor: "pointer",
                      marginLeft: -30,
                      marginTop: -35,
                    }}
                    onClick={() => toggleVisibleSHare(index)}
                  />
                  {visibleShare[index] && (
                    <Sharepopup
                    setOpen={() => toggleVisibleSHare(index)}
                    open={visibleShare[index]}
                    title=""
                    url={itm.large_image_url || itm.medium_image_url}
                    
                  />
                  )}
                 
                  
                </Grid>
              </Grid>
            </>
          ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(Gallery);
