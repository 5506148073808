import React,{useEffect,useCallback} from 'react';
const JsonLd = ({ data }) => {
    useCallback((data) => {
        const script_json = document.getElementById('sooq-script');
        if (script_json) {
            script_json.remove();
        }
    }, [data]);
    
    return (<script
        type="application/ld+json" id="sooq-script"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />);
}

export default JsonLd;