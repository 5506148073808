
import { Theme } from "@mui/material";
import { basetheme } from "theme";
import axios from "axios";

import { BASE_URL, authHeader, handleCatchException } from "../apihelper";

export const getcountriesdata = (formData) => async (dispatch) => {
  try {
    // showloader();
    const country_params = {
      continent_code: "AF",
      sort: "code",
      order: "asc",
      pagination: 0,
    };
    const response = await axios.get(
      `${BASE_URL}/countries`,
      { params: country_params },
      authHeader()
    );
    // hideloader();
    const country_data = response.data.data.map((value) => {
      return { code: value.code, id: value.id };
    });
    dispatch({
      type: "GET_COUNTRY",
      payload: {
        countries: country_data,
        selected_country: false,
      },
    });
    return true;
  } catch (error) {
    // return handleCatchException(error);
  }
};

export const getallcountriesdata = (formData) => async (dispatch) => {
  try {
    // showloader();
    const country_params = {
      sort: "code",
      order: "asc",
      pagination: 0,
    };
    const response = await axios.get(
      `${BASE_URL}/countries`,
      { params: country_params },
      authHeader()
    );
    // hideloader();
    const country_data = response.data.data.map((value) => {
      return { code: value.code, id: value.id };
    });
    dispatch({
      type: "ALL_COUNTRY",
      payload: {
        allcountries: country_data,
      },
    });
    return true;
  } catch (error) {
    // return handleCatchException(error);
  }
};

export function getThemeByName(theme) {
  return themeMap[theme] ? themeMap[theme] : basetheme;
}

const themeMap = {
  basetheme,
};

export const getCmsBySlug = (slug) => async (dispatch) => {
  try {
    dispatch({
      type: "CMS_LIST_LOADING",
      payload: true,
    });
    const response = await axios.get(`${BASE_URL}/cms/${slug}`, authHeader());
    if (response?.data?.data) {
      dispatch({
        type: "CMS_DETAIL",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "CMS_LIST_ERROR",
        payload: null,
      });
    }
    dispatch({
      type: "CMS_LIST_LOADING",
      payload: false,
    });
  } catch (error) {
    return handleCatchException(error);
  }
};
export const getConfigData = () => async (dispatch) => {
  try {
    dispatch({
      type: "CONFIG_LIST_LOADING",
      payload: true,
    });
    const response = await axios.get(
      `${BASE_URL}/config_data_all`,
      authHeader()
    );
    if (response?.data?.data) {
      let payloaddata = {};
      response.data.data.filter((value, key) => {
        if (value["general.general.admin_contact.phone"] !== undefined) {
          payloaddata["admin_phone"] =
            value["general.general.admin_contact.phone"]["value"];
        }
        if (value["general.general.email_settings.admin_email"] !== undefined) {
          payloaddata["admin_email"] =
            value["general.general.email_settings.admin_email"]["value"];
        }
        if (value["general.general.admin_contact.whatsapp"] !== undefined) {
          payloaddata["admin_whatsapp"] =
            value["general.general.admin_contact.whatsapp"]["value"];
        }
         if (value["delivery.settings.message.fullfill"] !== undefined) {
           payloaddata["fullfill_message"] =
             value["delivery.settings.message.fullfill"]["value"];
         }
        if (value["delivery.settings.message.not_fullfill"] !== undefined) {
          payloaddata["not_fullfill_message"] =
            value["delivery.settings.message.not_fullfill"]["value"];
        }
      });
      dispatch({
        type: "CONFIG_DETAIL",
        payload: payloaddata,
      });
    } else {
      dispatch({
        type: "CONFIG_LIST_ERROR",
        payload: null,
      });
    }
    dispatch({
      type: "CONFIG_LIST_LOADING",
      payload: false,
    });
  } catch (error) {
    return handleCatchException(error);
  }
};
