import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  return token ? (
    <Outlet />
  ) : (
    <Navigate replace to={{ pathname: "/login", state: { from: location } }} />
  );
};

export default PrivateRoute;
