import React, { useState } from "react";
import { useDispatch } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import Alert from "../Notifications/Alert";

import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { updatePassword } from "../../actions/auth";
import { withTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const useStyles = makeStyles((theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
);
const UpdatePassword = ({ t }) => {
  const classes = useStyles();
  let history = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    old_password: null,
    new_password: null,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(updatePassword(formData)).then((res) => {
      if (res.status) {
        history({
          pathname: "/",
        });
      }
    });
  };
  return (
    <Container maxWidth="sm" component="main">
      <Box component="div" m={8}>
        <Typography variant="h5" align="center">
          {t("Change Password")}
        </Typography>
        <Alert />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container>
            <Grid container item xs={6} sm={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="password"
                label={t("Old Password")}
                name="old_password"
                autoComplete="password"
                type={showPassword ? "text" : "password"}
                required
                onChange={(e) => onChange(e)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton arial-label="toggle passwor visibility" edge="end"
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
            </Grid>
            <Grid container item xs={6} sm={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="new_password"
                label={t("New Password")}
                name="new_password"
                autoComplete="password"
                type={showPasswordNew ? "text" : "password"}
                required
                onChange={(e) => onChange(e)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton arial-label="toggle passwor visibility" edge="end"
                      onClick={() => setShowPasswordNew(!showPasswordNew)}>
                      {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit + " text-white"}
          >
            {t("Update Password")}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default withTranslation()(UpdatePassword);
