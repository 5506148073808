import axios from "axios";
import { BASE_URL, authHeader, handleCatchException } from "../apihelper";

export const getBanner = () => async (dispatch) => {
  try {
    dispatch({
      type: "BANNER_LIST_LOADING",
      payload: true,
    });
    const response = await axios.get(`${BASE_URL}/getbanner`, authHeader());

    if (response?.data?.data) {
      dispatch({
        type: "BANNER_LIST",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "BANNER_LIST_ERROR",
        payload: null,
      });
    }
    dispatch({
      type: "BANNER_LIST_LOADING",
      payload: false,
    });
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getSuperMarketBanner = () => async (dispatch) => {
  try {
    dispatch({
      type: "BANNER_LIST_LOADING",
      payload: true,
    });
    const response = await axios.get(`${BASE_URL}/supermarket/getbanner`, authHeader());
    
    if (response?.data?.data) {
      dispatch({
        type: "BANNER_LIST",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "BANNER_LIST_ERROR",
        payload: null,
      });
    }
    dispatch({
      type: "BANNER_LIST_LOADING",
      payload: false,
    });
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getFooterCategory = () => async (dispatch) => {
  try {
    let paramsData = {
      limit: 5,
      sort: "id",
      order: "asc",
    };
    const response = await axios.get(
      `${BASE_URL}/categories`,
      { params: paramsData },
      authHeader()
    );
    if (response?.data?.data) {
      return response.data.data;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getMainCategory = () => async (dispatch) => {
  try {
    let country =
      localStorage.getItem("country") &&
      JSON.parse(localStorage.getItem("country"));

    const response = await axios.get(
      `${BASE_URL}/descendant-categories?${
        country && country.id ? "&country_id=" + country.id : ""
      }`,
      {},
      authHeader()
    );
    if (response?.data?.data) {
      return response.data.data;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
