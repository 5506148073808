import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Spinner = () => {
  const classes = useStyles();
  const loading = useSelector((state) => state.loading);

  return (
   
    loading && (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" disableShrink />
        </Backdrop>
      </div>
    )
  );
};

export default Spinner;
