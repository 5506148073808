import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Header from "components/Layouts/Header/Header";
import Footer from "components/Layouts/Footer/Footer";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import PrivateRoute from "./PrivateRoute";
import Login from "components/Auth/Login";
import VerifyOtp from "components/Auth/VerifyOtp";
import VerifyCredential from "components/Auth/VerifyCredential";
import Forgot from "components/Auth/Forgot";
import ResetPassword from "components/Auth/ResetPassword";
import UpdatePassword from "components/Auth/UpdatePassword";
import Profile from "components/Auth/Profile";
import GuestRoute from "./GuestRoute";
import ProductList from "components/Product";
import RandomProductList from "components/Home/RandomProduct";
import ProductDetail from "components/Product/detail";
import CmsDetail from "components/Cms/Detail";
import VendorList from "components/Vendor";
import Home from "components/Home";
import VendorDetail from "components/Vendor/detail";
import PreferredVendor from "components/Vendor/preferredVendor";
import Cart from "components/cart/index";
import Wishlist from "components/User/Wishlist";
import Checkout from "components/checkout/index.js";
import AddressCheckout from "components/checkout/address";
import Address from "components/address";
import ThankYou from "components/Payment/ThankYou.js";
import Orders from "components/orders";
import OrderDetail from "components/orders/detail";
import Invoice from "components/orders/invoice";
import Wallet from "components/wallet";
import TopSelling from "components/Home/TopSelling";
import PopularVendor from "components/Vendor/PopularVendor";
import NotFound from "components/common/NotFound";
import SitemapElement from "components/Sitemap/SitemapElement";
import SuperMarket from "components/supermarket/SuperMarket";

const WebRoute = ({ themenew }) => {
  const HeaderFooterLayout = ({ children }) => (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themenew}>
        <CssBaseline />
        <Header />
        <Box className="webouter">
          {children}
        </Box>
        <LazyLoad height={200} offset={70}>
          <Footer />
        </LazyLoad>
      </ThemeProvider>
    </StyledEngineProvider>

  );
  return (
    <Routes>
      <Route exact path="/sitemap.xml" element={<SitemapElement />} />
      <Route exact path="/sitemap/:id" element={<SitemapElement />} />

      <Route exact path="/" element={<HeaderFooterLayout><Home /></HeaderFooterLayout>} />
      <Route exact path="/supermarket" element={<HeaderFooterLayout><SuperMarket /></HeaderFooterLayout>} />
      <Route exact path="/verify" element={<HeaderFooterLayout><VerifyOtp /></HeaderFooterLayout>} />
      <Route exact path="/reset-password" element={<HeaderFooterLayout><ResetPassword /></HeaderFooterLayout>} />
      <Route exact path="/products" element={<HeaderFooterLayout><ProductList /></HeaderFooterLayout>} />
      <Route exact path="/supermarket/products" element={<HeaderFooterLayout><ProductList /></HeaderFooterLayout>} />
      <Route exact path="/products/:id" element={<HeaderFooterLayout><ProductDetail /></HeaderFooterLayout>} />
      <Route
        exact
        path="/products/home/random"
        element={<RandomProductList />}
      />
      <Route exact path="/best-selling" element={<HeaderFooterLayout><TopSelling /></HeaderFooterLayout>} />
      <Route exact path="/popular-seller" element={<HeaderFooterLayout><PopularVendor /></HeaderFooterLayout>} />
      <Route exact path="/products/category/:slug" element={<HeaderFooterLayout><ProductList /></HeaderFooterLayout>} />
      <Route exact path="/page/:slug" element={<HeaderFooterLayout><CmsDetail /></HeaderFooterLayout>} />
      <Route exact path="/vendors" element={<HeaderFooterLayout><VendorList /></HeaderFooterLayout>} />
      <Route exact path="/vendor/profile/:slug" element={<HeaderFooterLayout><VendorDetail /></HeaderFooterLayout>} />
      <Route exact path="/Login" element={<HeaderFooterLayout><GuestRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Login />} />
      </Route>
      <Route exact path="/forgot-password" element={<HeaderFooterLayout><GuestRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Forgot />} />
      </Route>
      <Route exact path="/cart" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Cart />} />
      </Route>
      <Route exact path="/checkout" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Checkout />} />
      </Route>
      <Route exact path="/payment/success" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<ThankYou />} />
      </Route>
      <Route exact path="/address" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<AddressCheckout />} />
      </Route>
      <Route exact path="/my-address" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Address />} />
      </Route>
      <Route exact path="/update-password" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<UpdatePassword />} />
      </Route>
      <Route exact path="/change-password" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<UpdatePassword />} />
      </Route>
      <Route exact path="/profile" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Profile />} />
      </Route>
      <Route exact path="/my-preferred-vendors" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<PreferredVendor />} />
      </Route>
      <Route exact path="/my-wishlist" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Wishlist />} />
      </Route>
      <Route exact path="/my-wallet" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Wallet />} />
      </Route>
      <Route exact path="/update-credentails" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<VerifyCredential />} />
      </Route>
      <Route exact path="/my-orders" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Orders />} />
      </Route>
      <Route exact path="/my-orders/:id" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<OrderDetail />} />
      </Route>
      <Route exact path="/my-orders/invoice/:id" element={<HeaderFooterLayout><PrivateRoute /></HeaderFooterLayout>}>
        <Route path="" element={<Invoice />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default WebRoute;

