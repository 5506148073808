const initialState = {
  countries: [],
  selected_country: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "GET_COUNTRY":
      return {
        ...state,
        ...payload,
      };
    case "ALL_COUNTRY":
      return {
        ...state,
        ...payload,
      };
    case "SELECTED_COUNTRY":
      localStorage.setItem("country", JSON.stringify(payload));
      return {
        ...state,
        selected_country: payload,
      };
    default:
      return state;
  }
}
