import React from "react";
import { withTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import { Box,Button} from "@mui/material";
import { Link,useNavigate } from "react-router-dom";


const NotFound = (props) => {
  let navigate = useNavigate();
  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true });
    window.location.reload();
  }
  return (
    <Container maxWidth="sm" component="main">
      <Box className="itemfounfouter">
        <h1>{props.t("Requested data not found")}</h1>
        <p>
          {props.t("Your search did not match any records")} <br />
          {props.t("Please try again")}
        </p>
        <Button
          type="button"
          variant="contained"
          color="primary"
          style={{ color: "#ffffff", width: "65%" }}
          onClick={() => changeLocation("/products?search=")}
        >
          {props.t("continue shopping")}
        </Button>
      </Box>
    </Container>
  );
};

export default withTranslation()(NotFound);
