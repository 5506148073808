import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  CircularProgress,
  Button,
  Slider,
  Typography,
  TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { AccessTimeOutlined, StarOutline } from "@mui/icons-material";
import Rating from "@mui/material/Rating";
import React, { useEffect, useState } from "react";
import { getReviews, createReview } from "actions/product";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Moment from "moment";

// const ratingObj = [
//   { text: "5 Star", rating: 5, value: 155, total: 400 },
//   { text: "4 Star", rating: 4, value: 85, total: 400 },
//   { text: "3 Star", value: 80, rating: 3, total: 400 },
//   { text: "2 Star", value: 120, rating: 2, total: 400 },
//   { text: "1 Star", value: 100, rating: 1, total: 400 },
// ];
const useStyles = makeStyles((theme) => ({
  // root: {
  //   padding: 15,
  //   marginLeft: 20,
  //   marginRight: 20,
  //   marginTop: 15,
  // },
  row: {
    display: "flex",
  },
  h2: {
    fontSize: 21,
    lineHeight: 1,
  },
  headingText: {
    fontSize: "16px",
    fontWeight: 700,
    marginLeft: 10,
  },
  slider: {
    height: 10,
    backgroundColor: "#f1f1f1",
  },
  badge: {
    display: "inline-block",
    padding: ".25em .4em",
    fontWeight: 700,
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".25rem",
    transition:
      "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
}));

const Rating5Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#2196F3",
  },
})(LinearProgress);

const Rating4Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#2196F3",
  },
})(LinearProgress);
const Rating3Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#00bcd4",
  },
})(LinearProgress);
const Rating2Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#ff9800",
  },
})(LinearProgress);
const Rating1Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#f44336",
  },
})(LinearProgress);

const MyComponent = {
  Rating1Progress: Rating1Progress,
  Rating2Progress: Rating2Progress,
  Rating3Progress: Rating3Progress,
  Rating4Progress: Rating4Progress,
  Rating5Progress: Rating5Progress,
};
const limit = 3;

const Review = (props) => {
  const classes = useStyles();
  const { t } = props;
  const [ratingObj, setRatingObj] = useState([
    { text: t("5 Star"), rating: 0, value: 5, total: 0 },
    { text: t("4 Star"), rating: 0, value: 4, total: 0 },
    { text: t("3 Star"), value: 3, rating: 0, total: 0 },
    { text: t("2 Star"), value: 2, rating: 0, total: 0 },
    { text: t("1 Star"), value: 1, rating: 0, total: 0 },
  ]);
  const [ratings, setRatings] = useState({});

  const [reviewList, setReviewList] = useState([]);
  const [page, setPage] = useState(1);
  const [userRating, setRating] = useState("");
  const [userComment, setComment] = useState("");
  const [userTitle, setTitle] = useState("");
  const [showReview, setShowReview] = useState(true);

  useEffect(() => {
    props.reviewSubmit && setShowReview(false);
  }, [props.reviewSubmit]);

  useEffect(() => {
    props.getReviews(props.slug, { page: page, limit: limit });
  }, [props.getReviews]);

  useEffect(() => {
    setReviewList(props.reviewList);
  }, [props.reviewList]);

  const handleShowMoreClick = () => {
    props.getReviews(props.slug, { page: page + 1, limit: limit });
    setPage(page + 1);
  };

  const handleReviewSubmit = () => {
    props.createReview(
      {
        comment: userComment,
        title: userTitle,
        rating: userRating,
      },
      props.slug
    );
  };

  useEffect(() => {
    const userRating = props.ratingObj;
    if (userRating && userRating.percentage) {
      let ratings = Array.isArray(userRating.percentage)
        ? userRating.percentage
        : JSON.parse(userRating.percentage);
      ratings &&
        setRatingObj([
          {
            text: t("5 Star"),
            value: 5,
            rating: ratings["5"],
            total: (ratings["5"] / 100) * parseInt(userRating.total),
          },
          {
            text: t("4 Star"),
            value: 4,
            rating: ratings["4"],
            total: (ratings["4"] / 100) * parseInt(userRating.total),
          },
          {
            text: t("3 Star"),
            value: 3,
            rating: ratings["3"],
            total: (ratings["3"] / 100) * parseInt(userRating.total),
          },
          {
            text: t("2 Star"),
            value: 2,
            rating: ratings["2"],
            total: (ratings["2"] / 100) * parseInt(userRating.total),
          },
          {
            text: t("1 Star"),
            value: 1,
            rating: ratings["1"],
            total: (ratings["1"] / 100) * parseInt(userRating.total),
          },
        ]);
    }
    setRatings(props.ratingObj);
  }, [props.ratingObj]);
  const renderRating = (itm) => {
    const NewComponent = MyComponent[`Rating${itm.value.toString()}Progress`];
    return (
      <NewComponent
        strokeLinecap="round"
        color={itm.rating == 5 ? "primary" : "secondary"}
        className={classes.slider}
        value={itm.rating || 0}
        variant="determinate"
      />
    );
  };
  return (
    <Grid className={classes.root} id="reviews">
      <Grid container className={classes.row}>
        <Grid xs={12} md={userRating && showReview ? 2 : 4}>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <p className={classes.h2}>{t("Overall rating")}</p>
            <Rating
              name="read-only"
              value={ratings.average_rating || 0}
              readOnly
              precision={0.5}
              name="half-rating-read"
            />
            {ratings.total ? (
              <p>
                {ratings.average_rating} {props.t("average based on")}{" "}
                {ratings.total} {props.t("Reviews")}.
              </p>
            ) : (
              <p>{t("No rating yet")}</p>
            )}
          </Box>
        </Grid>
        {localStorage.getItem("token") && (
          <Grid xs={12} md={userRating && showReview ? 6 : 4}>
            <Box component="fieldset" mb={3} borderColor="transparent">
              <p className={classes.h2}>{props.t("Rate this Product")}</p>
              <Box component="fieldset" borderColor="transparent">
                <Rating
                  name="simple-controlled"
                  value={userRating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                    // setShowReview(true);
                  }}
                />
              </Box>
              {userRating && showReview && (
                <Box component="fieldset" borderColor="transparent">
                  <TextField
                    id="outlined-multiline-static"
                    label="Review Title *"
                    multiline
                    style={{ width: "100%", marginBottom: 10 }}
                    rows={1}
                    value={userTitle}
                    placeholder={t("Write your review title here")}
                    variant="outlined"
                    onChange={(e) => setTitle(e.target.value)}
                  />

                  <TextField
                    id="outlined-multiline-static"
                    label="Your Comment *"
                    multiline
                    style={{ width: "100%" }}
                    rows={3}
                    value={userComment}
                    placeholder={t("Write your review here")}
                    variant="outlined"
                    onChange={(e) => setComment(e.target.value)}
                  />
                </Box>
              )}
              {userRating &&
                userTitle &&
                userComment &&
                showReview &&
                (props.submitReviewLoading ? (
                  <CircularProgress style={{ marginLeft: "45%" }} />
                ) : (
                  <Button
                    className={classes.button}
                    style={{ marginLeft: "30%" }}
                    disabled={!userRating}
                    variant="contained"
                    color="primary"
                    onClick={handleReviewSubmit}
                  >
                    Submit
                  </Button>
                ))}
            </Box>
          </Grid>
        )}
        <Grid xs={12} md={4}>
          <Box component="fieldset" mb={3} borderColor="transparent">
            {ratingObj.map((itm) => (
              <Grid style={{ height: 30 }} className={classes.row}>
                <Grid xs="2" md="2">
                  <p>{itm.text}</p>
                </Grid>
                <Grid xs="8" md="8" style={{ height: 21, paddingTop: 21 }}>
                  {renderRating(itm)}
                </Grid>
                <Grid xs="2" md="2">
                  <p style={{ marginLeft: 5 }}>{Math.round(itm.total || 0)}</p>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
      {reviewList && reviewList.length ? (
        <>
          {reviewList.map((itm) => (
            <>
              <Divider style={{ marginBottom: 20, height: 3 }} />
              <Card style={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <CardContent>
                  <Grid className={classes.row}>
                    <span
                      className={classes.badge}
                      style={{ backgroundColor: "#28a745", color: "white" }}
                    >
                      <span> {itm.rating}</span>
                      <StarOutline style={{ width: 15, height: 12 }} />
                    </span>
                    <span className={classes.headingText}>{itm.title}</span>
                  </Grid>
                  <p style={{ color: "#222" }}>{itm.comment}</p>
                  <span>
                    <span style={{ fontSize: 14, fontWeight: 600 }}>
                      {itm.customer?.first_name} {itm.customer?.last_name}{" "}
                    </span>
                    {itm.created_at && (
                      <span
                        style={{
                          color: "#666",
                          marginLeft: 5,
                          display: "inline-block",
                        }}
                      >
                        |
                        <span>
                          {" "}
                          <AccessTimeOutlined
                            style={{ width: 15, height: 14 }}
                            size="small"
                          />{" "}
                        </span>{" "}
                        {Moment(itm.created_at).fromNow()}
                      </span>
                    )}
                  </span>
                </CardContent>
              </Card>
            </>
          ))}
          <Divider style={{ marginBottom: 20, height: 3 }} />

          {props.reviewListLoading ? (
            <CircularProgress style={{ float: "right" }} />
          ) : (
            page !== props.reviewListMeta.last_page && (
              <Button style={{ float: "right" }} onClick={handleShowMoreClick}>
                {t("Show More")}
              </Button>
            )
          )}
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  reviewList: state.product.reviewList,
  reviewListMeta: state.product.reviewListMeta,
  reviewListLoading: state.product.reviewListLoading,
  submitReviewLoading: state.product.submitReviewLoading,
  reviewSubmit: state.product.reviewSubmit,
});

const mapDispatchToProps = {
  getReviews,
  createReview,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Review)
);
