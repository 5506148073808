
export default function (state = false, action) {
  const { type, payload } = action;

  switch (type) {
    case "LOADING_SHOW":
      return  true;
    case "LOADING_HIDE":
      return false;
    default:
      return state;
  }
}
