import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import { withTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import Alert from "../Notifications/Alert";
import { getWishlist, moveToCart, removeWhishlist } from "actions/product";
import ProductCard from "components/Product/productCard";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    backgroundColor: "#f2f6f4",
  },
  productContainer: {
    width: "25%",
    [theme.breakpoints.down('lg')]: {
      width: "33.33%",
    },
    [theme.breakpoints.down('md')]: {
      width: "50%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(1),
  },
  noRecord: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    // alignItems:'center'
  },
}));

const Wishlist = ({ getWishlist, moveToCart, removeWhishlist, t }) => {
  const classes = useStyles();
  const [wishlistItem, setWishlistItem] = useState([]);
  useEffect(() => {
    getWishlist().then((res) => {
      setWishlistItem(res.data);
    });
  }, []);
  const movetocart = (id) => {
    moveToCart(id).then((res) => {
      if (res.status) {
        setWishlistItem(wishlistItem.filter((item) => item.id !== id));
      }
    });
  };
  const deleteWhistlist = (id) => {
    removeWhishlist(id).then((res) => {
      if (res.status) {
        setWishlistItem(wishlistItem.filter((item) => item.id !== id));
      }
    });
  };

  return (
    <Container className={classes.root} maxWidth={false} disableGutters={true}>
      <Grid container>
        <Grid className="mywishlist-outer" container item xs={12} md={12}>
          <Grid item xs="12" sm="12">
            <Typography variant="h3" className={classes.formControl}>
              {t("My Wishlist")}
            </Typography>
            <Alert />
          </Grid>
          <Grid container className="mywishlistouter" direction="row">
            {wishlistItem.length ? (
              wishlistItem.map((itm) => (
                <>
                  <Grid className={classes.productContainer} key={itm.id}>
                    <ProductCard
                      item={itm.product}
                      wishlist={itm.id}
                      movetocart={movetocart}
                      removeFromWishlist={deleteWhistlist}
                      t={t}
                    />
                  </Grid>
                </>
              ))
            ) : (
              <Grid className={classes.noRecord}>
                <h3 style={{ textAlign: "center" }}>
                  <strong> {t("Wishlist Empty")}</strong>
                </h3>
                <Link
                  to="/products"
                  style={{ textAlign: "center", textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    className="sooqiraq-btn"
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginTop: 15,
                    }}
                  >
                    {t("continue shopping")}
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  countryselected: state.countries.selected_country,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getWishlist,
    moveToCart,
    removeWhishlist,
  })(Wishlist)
);
