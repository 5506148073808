import { createTheme } from "@mui/material/styles";

import HelveticaNeue from "./components/fonts/HelveticaNeue.ttc";

const Helvetica = {
  fontFamily: "HelveticaNeue",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('HelveticaNeue'),
    url(${HelveticaNeue}) format('ttc')
  `,
};

export const basetheme = createTheme({
  root: {
    margin: 0,
    padding: 0,
  },
  palette: {
    primary: {
      main: "#45B2CB",
      light: "#000000",
      dark: "#059BE5",
    },
    secondary: {
      main: "#03091D",
      light: "#03091D",
      dark: "#03091D",
    },
  },
  typography: {
    fontFamily: "Helvetica, Arial",
    h1: {
      fontSize: "36px !important",
    },
    h2: {
      fontSize: "28px !important",
    },
    h3: {
      fontSize: "24px !important",
    },
    h4: {
      fontSize: "20px !important",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "16px !important",
      fontWeight: "bold",
    },
    h6: {
      fontSize: "12px !important",
    },
  },
});

basetheme.overrides = {
  MuiCssBaseline: {
    "@global": {
      "@font-face": [Helvetica],
    },
  },

  MuiButton: {
    contained: {
      backgroundColor: basetheme.palette.primary.main,
      color: `#ffffff !important`,
      "&:hover": {
        backgroundColor: `${basetheme.palette.primary.light} !important`,
        color: `#ffffff`,
      },
    },
  },
};
