import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { getSuperMarketCategory } from "actions/supermarket";
import { Box, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      backgroundColor: "#f2f6f4",
    },
    media: {
      width: "100%",
      height: 280,
      position: "relative",
    },
    miniQty: {
      background: theme.palette.primary.main,
      color: "#fff",
      position: "absolute",
      bottom: "16px",
      left: 0,
      padding: "6px 12px",
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    sliderContainer: {
      padding: 40,
      [theme.breakpoints.down("lg")]: {
        padding: "32px 0 0 0",
      },
    },
    linkcls: {
      textDecoration: "none",
      color: "#000",
    },
    rating: {
      color: "orange",
    },
  })
);

const SuperMarketCard = ({
    getSuperMarketCategory,
    category,
    t
}) => {
    const classes = useStyles();
    const [filter, setFilter] = useState('');
    const [categoryData, setCategoyData] = useState([]);

    const handleChange = useCallback((event) => {
        setFilter(event.target.value);
        if (event.target.value === 'desc') {
            setCategoyData(category?.sort((a, b) => {
                if (a.slug < b.slug)
                    return 1;
                if (a.slug > b.slug)
                    return -1;
            }));
        }
        if (event.target.value === 'asce') {
            setCategoyData(category?.sort((a, b) => {
                if (a.slug < b.slug)
                    return -1;
                if (a.slug > b.slug)
                    return 1;
            }));
        }
        if(event.target.value === '') {
            getSuperMarketCategory();
        }
    }, [categoryData, filter]);

    useEffect(() => {
        setCategoyData(category);
    }, [category]);

    useEffect(() => {
        getSuperMarketCategory();
    }, []);

    return (
        <Box component="div" m={1} className={classes.sliderContainer}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h3" component="h3" className={classes.title}>
                    {t("SuperMarket Categories")}
                    <div className={classes.bottomBorder}></div>
                </Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={filter}
                        onChange={handleChange}
                        displayEmpty
                        size='small'
                    >
                        <MenuItem value="">
                            <em>Sort By</em>
                        </MenuItem>
                        <MenuItem value={'asce'}>A - Z</MenuItem>
                        <MenuItem value={'desc'}>Z - A</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='supermarket-card'>
                {categoryData?.length > 0 && categoryData.map((item, i) => (
                    <div className='supermarket-card-inner' key={item?.id}>
                        <Link to={`/products/category/${item.slug}`}>
                            <Card >
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={item?.image_url}
                                        alt="green iguana"
                                    />
                                </CardActionArea>
                            </Card >
                        </Link>
                        <h3>{item?.name}</h3>
                    </div>
                ))}
            </div>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    category: state.supermarket.category,
    loading: state.supermarket.Loading,
});
export default connect(mapStateToProps, {
    getSuperMarketCategory
})(SuperMarketCard);