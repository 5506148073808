import React from "react";
import { Container, Grid } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

import "../../main.css";

import Banner from "components/Home/Bannerslider";
import { withTranslation } from "react-i18next";
import MetaComponent from "components/common/MetaComponent";
import SuperMarketCard from "./SuperMarketCard";
import NewArrival from "components/Home/NewArrival";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            margin: 0,
            padding: "0 !important",
        },
    })
);

const SuperMarket = ({ t }) => {
    const classes = useStyles();
    const baseurl = window.location.href + 'logo.png';
    return (
        <Container  maxWidth={false} disableGutters={true}>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <div
                        className='supermarket-bg'
                        disableGutters={true}
                        maxWidth={false}
                    >
                        <MetaComponent
                            title={t("HOME_PAGE_TITLE")}
                            description={t("HOME_PAGE_DESCRIPTION")}
                            image={baseurl}
                        />
                        <Banner />
                        <SuperMarketCard t={t}/>
                        <NewArrival t={t} />
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default withTranslation()(SuperMarket);
