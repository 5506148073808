import axios from "axios";
import {
  BASE_URL,
  authHeader,
  handleCatchException,
  errormsg,
  successmsg,
} from "../apihelper";

export const getCompleteCartCount = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/checkout/cart?item_count=1`,
      authHeader()
    );
    if (typeof response?.data?.data != "undefined") {
      dispatch({
        type: "CART_ITEM_COUNT",
        payload: response?.data?.data,
      });
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getCompleteCart = () => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    const response = await axios.get(`${BASE_URL}/checkout/cart`, authHeader());
    if (response?.data?.data) {
      dispatch({
        type: "CART_DETAIL",
        payload: response?.data?.data,
      });

      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
      dispatch({
        type: "CART_DETAIL",
        payload: {},
      });
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const removeItemFromCart = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    const response = await axios.get(
      `${BASE_URL}/checkout/cart/remove-item/${id}`,
      authHeader()
    );
    if (!response?.data?.status) {
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
      errormsg(response?.data?.error?.message || "Unable to update cart");
      return;
    }
    if (response?.data?.data) {
      dispatch({
        type: "CART_DETAIL",
        payload: response?.data?.data,
      });
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "CART_DETAIL",
        payload: [],
      });
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    }
    dispatch(getCompleteCartCount());
  } catch (error) {
    return handleCatchException(error);
  }
};

export const moveItemToWishList = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    const response = await axios.get(
      `${BASE_URL}/checkout/cart/move-to-wishlist/${id}`,
      authHeader()
    );
    if (!response?.data?.status) {
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
      errormsg(response?.data?.error?.message || "Unable to update cart");
      return;
    }
    if (response?.data?.data) {
      dispatch({
        type: "CART_DETAIL",
        payload: response?.data?.data,
      });
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "CART_DETAIL",
        payload: [],
      });
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    }
    dispatch(getCompleteCartCount());
  } catch (error) {
    return handleCatchException(error);
  }
};
export const updateProductQuantity = (id, qty) => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    const options = {
      qty: {
        [id]: qty,
      },
    };
    const response = await axios.put(
      `${BASE_URL}/checkout/cart/update`,
      options,
      authHeader()
    );
    if (!response?.data?.status) {
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
      errormsg(response?.data?.error?.message || "Unable to update cart");
      return;
    }
    if (response?.data?.data) {
      dispatch({
        type: "CART_DETAIL",
        payload: response?.data?.data,
      });
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "CART_DETAIL",
        payload: [],
      });
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: false,
    });

    return handleCatchException(error);
  }
};

export const createAddress = (data, qty) => async (dispatch) => {
  try {
    dispatch({
      type: "CREATING_ADDRESS",
      payload: true,
    });
    const response = await axios.post(
      `${BASE_URL}/addresses/create`,
      data,
      authHeader()
    );
    if (!response?.data?.data) {
      dispatch({
        type: "CREATING_ADDRESS",
        payload: false,
      });

      errormsg(response?.data?.error?.message || "Unable to create address");
      return;
    }
    if (response?.data?.data) {
      dispatch(getAddresses());
      successmsg("Address saved successfully");
      dispatch({
        type: "OPEN_UPDATE_ADDRESS",
        payload: false,
      });
      dispatch({
        type: "CREATING_ADDRESS",
        payload: false,
      });
    } else {
      errormsg(response?.data?.error?.message || "Unable to create address");
      dispatch({
        type: "CREATING_ADDRESS",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "CREATING_ADDRESS",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const openUpdateAddress = (status) => (dispatch) => {
  dispatch({
    type: "OPEN_UPDATE_ADDRESS",
    payload: status,
  });
};

export const getAddresses = () => async (dispatch) => {
  try {
    dispatch({
      type: "ADDRESS_LIST_LOADING",
      payload: true,
    });
    const response = await axios.get(`${BASE_URL}/addresses`, authHeader());
    if (response?.data?.data) {
      dispatch({
        type: "ADDRESS_LIST",
        payload: response?.data?.data,
      });

      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const removeAddress = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "ADDRESS_LIST_LOADING",
      payload: true,
    });
    const response = await axios.delete(
      `${BASE_URL}/addresses/${id}`,
      authHeader()
    );
    if (response?.data?.status) {
      dispatch(getAddresses());
      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const updateAddress = (formData, id) => async (dispatch) => {
  try {
    dispatch({
      type: "ADDRESS_LIST_LOADING",
      payload: true,
    });

    const response = await axios.put(
      `${BASE_URL}/addresses/${id}`,
      formData,
      authHeader()
    );
    if (!response?.data?.data) {
      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
      errormsg(response?.data?.error?.message || "Unable to update cart");
      return;
    }
    if (response?.data?.data) {
      dispatch(getAddresses());
      successmsg("Address Details updated");
      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "ADDRESS_LIST_LOADING",
        payload: false,
      });
      errormsg(response?.data?.message);
    }
  } catch (error) {
    dispatch({
      type: "ADDRESS_LIST_LOADING",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const updateCartAddress =
  (selectedAddress, billingAddress, cb) => async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_CART_ADDRESS",
        payload: true,
      });
      const obj = {
        billing: {
          address1: {
            0: billingAddress.address1,
          },
          use_for_shipping: false,
          first_name: billingAddress.first_name,
          last_name: billingAddress.last_name,
          email: billingAddress.email,
          address_id: billingAddress.id,
        },
        shipping: {
          address1: {
            0: selectedAddress.address1,
          },
          first_name: selectedAddress.first_name,
          last_name: selectedAddress.last_name,
          email: selectedAddress.email,
          address_id: selectedAddress.id,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/checkout/save-address`,
        obj,
        authHeader()
      );
      if (!response?.data?.data) {
        dispatch({
          type: "UPDATE_CART_ADDRESS",
          payload: false,
        });
        return;
      }
      if (response?.data?.data) {
        const saveShipping = await axios.post(
          `${BASE_URL}/checkout/save-shipping`,
          {
            shipping_method: "citywise",
          },
          authHeader()
        );
        if (saveShipping.data.data) {
          dispatch({
            type: "UPDATE_CART_ADDRESS",
            payload: false,
          });
          dispatch({
            type: "CART_SHIPPING_METHODS",
            payload: saveShipping.data.data,
          });
          typeof cb == "function" && cb();
        }
      } else {
        dispatch({
          type: "UPDATE_CART_ADDRESS",
          payload: false,
        });
      }
    } catch (error) {
      // return handleCatchException(error);
    }
  };

export const saveOrder = () => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    const saveOrder = await axios.post(
      `${BASE_URL}/checkout/save-order?checkout_method=web`,
      {},
      authHeader()
    );
    dispatch(getCompleteCartCount());
    if (saveOrder?.data) {
      return saveOrder?.data;
    } else {
      errormsg("Something went wrong while saving data");
    }
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
  } catch (error) {
    return handleCatchException(error);
  }
};

export const savePaymentMethod = (method) => async (dispatch) => {
  try {
    const obj = {
      payment: {
        method,
      },
    };
    const response = await axios.post(
      `${BASE_URL}/checkout/save-payment`,
      obj,
      authHeader()
    );
    if (!response?.data?.data) {
      dispatch({
        type: "UPDATE_CART_PAYMENT",
        payload: false,
      });
      return false;
    }
    if (response?.data?.data && response.data.data.cart) {
      const saveOrder = await axios.post(
        `${BASE_URL}/checkout/save-order?checkout_method=web`,
        {},
        authHeader()
      );
      dispatch(getCompleteCartCount());
      return saveOrder.data;
    } else {
      dispatch({
        type: "UPDATE_CART_PAYMENT",
        payload: false,
      });
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getCityList = async () => {
  try {
    const lang = localStorage.getItem("language")
      ? JSON.parse(localStorage.getItem("language")).value
      : "en";
    const response = await axios.get(
      `${BASE_URL}/city?locale=${lang}`,
      authHeader()
    );
    return response.data;
  } catch (error) {
    return handleCatchException(error);
  }
};

export const applyCoupon = (code) => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    
    const response = await axios.post(
      `${BASE_URL}/checkout/cart/coupon`,
      code,
      authHeader()
    );
    if (!response?.data?.status) {
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
      errormsg(response?.data?.message || "Unable to update cart");
      return;
    }
    if (response?.data?.sttaus) {
     
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    }
    successmsg(response?.data?.message || "Added Coupon");

    dispatch(getCompleteCart());
  } catch (error) {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: false,
    });
    return handleCatchException(error);
  }
};
export const removeCoupon = () => async (dispatch) => {
  try {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: true,
    });
    
    const response = await axios.delete(
      `${BASE_URL}/checkout/cart/coupon`,
      authHeader()
    );
    if (!response?.data?.status) {
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
      errormsg(response?.data?.error?.message || "Unable to update cart");
      return;
    }
    if (response?.data?.sttaus) {
      successmsg(response?.data?.message || "Remove Coupon");
     
      dispatch({
        type: "CART_DETAIL_LOADING",
        payload: false,
      });
    }
    dispatch(getCompleteCart());
  } catch (error) {
    dispatch({
      type: "CART_DETAIL_LOADING",
      payload: false,
    });
    return handleCatchException(error);
  }
};