import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  Button,
  Link,
} from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 8,
    boxShadow: "none",
    border: "1px solid #efefef",
  },
  bottomBorder: {
    width: "50px",
    height: "5px",
    background: theme.palette.primary.main,
    margin: "16px 0",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  cardInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "100%",
  },
  image: {
    objectFit: "fill",
    borderRadius: 5,
  },
  card: {
    padding: "10px",
  },
  cardContent: {
    marginBottom: 8,
  },
}));
const ViewAll = ({ href, t }) => {
  const classes = useStyles();
  return (
    <Grid container className="viewallitem" spacing={4} key="view all">
      <Card className={classes.root}>
        <CardActionArea>
          <Link href={href} target="_blank" className={classes.link}>
            <Button variant="contained">{t("View All")}</Button>
          </Link>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ViewAll;
