import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { getTopSellingProducts } from "actions/product";
import ProductCard from "components/Product/productCard";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
      backgroundColor: "#f2f6f4",
    },
    media: {
      width: "100%",
      height: 280,
      position: "relative",
    },
    bottomBorder: {
      width: "50px",
      height: "5px",
      background: theme.palette.primary.main,
      margin: "16px 0",
    },
    title: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    cardInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    miniQty: {
      background: theme.palette.primary.main,
      color: "#fff",
      position: "absolute",
      bottom: "16px",
      left: 0,
      padding: "6px 12px",
    },
    sliderContainer: {
      padding: 40,
      [theme.breakpoints.down("lg")]: {
        padding: "32px 0 0 0",
      },
    },
    linkcls: {
      textDecoration: "none",
      color: "#000",
    },
    rating: {
      color: "orange",
    },
  })
);

const TopSelling = (props) => {
  const classes = useStyles();
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState({});

  useState(() => {
    props
      .getTopSellingProducts({
        random: 1,
        page: page,
        limit: 10,
      })
      .then((res) => {
        setMeta(res.data?.meta);
        setProduct(res.data?.data);
      });
  }, []);
  const fetchMoreData = () => {
    // if(props.isHome && page > 5){
    //   return;
    // }
    props
      .getTopSellingProducts({
        random: 1,
        page: page + 1,
        limit: 10,
      })
      .then((res) => {
        setProduct((ev) => [...ev, ...res.data?.data]);
        setMeta(res.data?.meta);
        setPage(page + 1);
      });
  };

  return (
    <Box
      component="div"
      style={{ marginLeft: "70px", marginRight: "30px", marginTop: 20 }}
    >
      <InfiniteScroll
        dataLength={product.length}
        next={fetchMoreData}
        hasMore={
          !(props.isHome && page > 5) &&
          (meta?.last_page ? meta.last_page >= page : true)
        }
        loader={
          <h1 style={{ textAlign: "center" }}>
            <CircularProgress />
          </h1>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>{props.t("Yay! You have seen it all")}</b>
          </p>
        }
      >
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)" }}>
          {product.map((item, index) => {
            return (
              <Grid container spacing={4} className="fixsizeitems" key={index}>
                <ProductCard item={item} t={props.t} />
              </Grid>
            );
          })}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};

const mapStateToProps = (state) => ({});
export default withTranslation()(
  connect(mapStateToProps, {
    getTopSellingProducts,
  })(TopSelling)
);
