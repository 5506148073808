import React from "react";
import { makeStyles, createStyles } from "@mui/styles";

import {
    Typography,
    IconButton,
} from "@mui/material";

import {
    Facebook,
    Instagram,
    Pinterest,
    Twitter,
    YouTube,
    LinkedIn
} from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import { createSvgIcon } from '@mui/material/utils';


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        margin: theme.spacing(1),
    },
    iconButton: {
        marginLeft: theme.spacing(1),
    },
    skype:{
        fontSize: "33px",
        position: "relative",
        top: "6px"
    }

}));

const SkypeIcon = createSvgIcon(
    <path d="M4.671 0c.88 0 1.733.247 2.468.702a7.423 7.423 0 0 1 6.02 2.118 7.372 7.372 0 0 1 2.167 5.215c0 .344-.024.687-.072 1.026a4.662 4.662 0 0 1 .6 2.281 4.645 4.645 0 0 1-1.37 3.294A4.673 4.673 0 0 1 11.18 16c-.84 0-1.658-.226-2.37-.644a7.423 7.423 0 0 1-6.114-2.107A7.374 7.374 0 0 1 .529 8.035c0-.363.026-.724.08-1.081a4.644 4.644 0 0 1 .76-5.59A4.68 4.68 0 0 1 4.67 0zm.447 7.01c.18.309.43.572.729.769a7.07 7.07 0 0 0 1.257.653c.492.205.873.38 1.145.523.229.112.437.264.615.448.135.142.21.331.21.528a.872.872 0 0 1-.335.723c-.291.196-.64.289-.99.264a2.618 2.618 0 0 1-1.048-.206 11.44 11.44 0 0 1-.532-.253 1.284 1.284 0 0 0-.587-.15.717.717 0 0 0-.501.176.63.63 0 0 0-.195.491.796.796 0 0 0 .148.482 1.2 1.2 0 0 0 .456.354 5.113 5.113 0 0 0 2.212.419 4.554 4.554 0 0 0 1.624-.265 2.296 2.296 0 0 0 1.08-.801c.267-.39.402-.855.386-1.327a2.09 2.09 0 0 0-.279-1.101 2.53 2.53 0 0 0-.772-.792A7.198 7.198 0 0 0 8.486 7.3a1.05 1.05 0 0 0-.145-.058 18.182 18.182 0 0 1-1.013-.447 1.827 1.827 0 0 1-.54-.387.727.727 0 0 1-.2-.508.805.805 0 0 1 .385-.723 1.76 1.76 0 0 1 .968-.247c.26-.003.52.03.772.096.274.079.542.177.802.293.105.049.22.075.336.076a.6.6 0 0 0 .453-.19.69.69 0 0 0 .18-.496.717.717 0 0 0-.17-.476 1.374 1.374 0 0 0-.556-.354 3.69 3.69 0 0 0-.708-.183 5.963 5.963 0 0 0-1.022-.078 4.53 4.53 0 0 0-1.536.258 2.71 2.71 0 0 0-1.174.784 1.91 1.91 0 0 0-.45 1.287c-.01.37.076.736.25 1.063z" />
    , 'Home',
);

const VendorSocialLink = (props) => {
    const { linked_in, facebook, youtube, instagram, pinterest, skype, twitter } = props.vendor;
    const classes = useStyles();
    const handleClick = (event, provider) => {
        event.preventDefault();

        switch (provider) {
            case 'facebook':
                return window.open(facebook ? facebook : `https://www.facebook.com`, '_blank');
            case 'instagram':
                return window.open(instagram ? instagram : `https://www.instagram.com`, '_blank');
            case 'twitter':
                return window.open(twitter ? twitter : `https://twitter.com`, '_blank');
            case 'linked_in':
                return window.open(linked_in ? linked_in : `https://www.linkedin.com`, '_blank');
            case 'pinterest':
                return window.open(pinterest ? pinterest : `http://pinterest.com/`, '_blank');
            case 'youtube':
                return window.open(youtube ? youtube : `https://youtube.com/`, '_blank');
            case 'skype':
                return window.open(skype ? skype : `https://web.skype.com/`, '_blank');
            default:
                return null;
        }


    };

    return (
        <div className={classes.root}>
            <Typography variant="subtitle2" >{props.t("Reach Out:")}</Typography>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'facebook')}
            >
                <Facebook />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'twitter')}
            >
                <Twitter />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'instagram')}
            >
                <Instagram />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'youtube')}
            >
                <YouTube />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'pinterest')}
            >
                <Pinterest />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'linked_in')}
            >
                <LinkedIn />
            </IconButton>
            <IconButton
                className={classes.iconButton}
                color="primary"
                onClick={(event) => handleClick(event, 'skype')}
            >
                <SkypeIcon  className={classes.skype}/>
            </IconButton>

        </div>

    );

}
export default withTranslation()(VendorSocialLink);