import { Grid, Container, Box, ButtonGroup, Button, CircularProgress, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Spinner from "components/Notifications/Spinner";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Alert from "../Notifications/Alert";
import { useParams } from "react-router-dom";
import { getCmsBySlug } from "actions/common";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
    minHeight: "calc(100vh - 633px)",
  },
  description: {
    overflow: "hidden",
    fontSize: 15,
    lineHeight: "1.4",
    padding: 20,
  },
}));
const Detail = (props) => {
  const classes = useStyles();
  let { slug } = useParams();

  useEffect(() => {
    props.getCmsBySlug(slug);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [slug]);

  return (
    <Container className={classes.root + " mobile-space"} maxWidth="xl">
      <Grid container spacing={1}>
        <Grid xs={12} md={8} spacing={5}>
          <Grid>
            <Typography variant="h2">
              {props.cmsDetail ? props.cmsDetail.title : ""}
            </Typography>
          </Grid>
          <Grid>
            <div
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: props.cmsDetail ? props.cmsDetail.content : "",
              }}
            ></div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cmsDetail: state.cms.detail,
});
export default connect(mapStateToProps, {
  getCmsBySlug,
})(Detail);
