const initialState = {
  cartDetailError : null,
  cartDetail : null,
  shippingMethods: [],
  cartCount : 0
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
      case "CART_DETAIL_LOADING": 
          return {
           ...state,
           cartDetailLoading: payload   
          };
      case "CART_SHIPPING_METHODS":
        localStorage.setItem("payment_methods", JSON.stringify(payload.methods));
        return {
          ...state,
          cartDetail: payload.cart,
          shippingMethods: payload.methods
        };
      case "CART_ITEM_COUNT":
        return {
          ...state,
          cartCount : payload,
        };
      case "CART_DETAIL" :
        return {
          ...state,
          cartDetail : payload,
          cartDetailError: null
        };
      case "CART_DETAIL_ERROR" :
        return {
          ...state,
          cartDetailError : payload
        };
      case "CREATING_ADDRESS" : 
        return {
          ...state,
          createAddressLoader: payload
        }
      case "OPEN_UPDATE_ADDRESS" : 
        return {
          ...state,
          openAddressPopup: payload
        }
      case "ADDRESS_LIST_LOADING" : 
      return{
        ...state,
        addressListLoading : payload
      }
      case "ADDRESS_LIST" : 
      return{
        ...state,
        addressList : payload
      }
      case "UPDATE_CART_ADDRESS" :
        return{
          ...state,
          loading_order_create : payload
        }    
    default:
      return state;
  }
}
