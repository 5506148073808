import {
  Grid,
  Container,
  ButtonGroup,
  Button,
  Card,
  Typography,
  CardContent,
  Chip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCompleteCart,
  removeItemFromCart,
  moveItemToWishList,
  updateProductQuantity,
  createAddress,
  openUpdateAddress,
  getAddresses,
  removeAddress,
  updateAddress,
  updateCartAddress,
  savePaymentMethod,
  saveOrder,
} from "actions/cart";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router";

import Simmer from "../common/skeleton";
import AlertBox from "components/Notifications/Alert";
import PaymentOptions from "./PaymentSelection";
import DeliveryMessage from "components/common/DeliveryMessage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    marginBottom: 60,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  borderBox: {
    border: "1px solid #ccc",
    padding: theme.spacing(2),
    boxShadow: "none",
    marginBottom: 16,
  },
  borderBoxAddress: {
    border: "1px solid #ccc",
    padding: 27,
    boxShadow: "none",
    marginBottom: 16,
  },

  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    padding: "6px !important",
  },
  noRecord: {
    display: "flex",
    flexDirection: "column",
    width: 300,
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
  },
  button: {
    "&.MuiButton-containedPrimary:hover": {
      color: "#fff",
    },
  },
}));
const Checkout = (props) => {
  const { t } = props;
  const classes = useStyles();
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [productList, setProductList] = useState({});
  const [cartDetail, setCart] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(
    location?.state?.shippingAddress
  );
  const [billingAddress, setBillingAddress] = useState(
    location?.state?.billingAddress
  );
  const [paymentModal, setPaymentModal] = useState(false);

  useEffect(() => {
    props.getCompleteCart();
  }, [props.getCompleteCart]);

  useEffect(() => {
    if (props.cartDetail) {
      setProductList(props.cartDetail?.items);
      setCart(props.cartDetail);
    }
  }, [props.cartDetail]);

  const handleVendorClick = (item) => {
    localStorage.removeItem("country");
    dispatch({ type: "SELECTED_COUNTRY", payload: false });

    setTimeout(() => {
      history(`/products/${item?.url_key}`);
    }, 10);
  };
  const handleOrderClick = () => {
    if (!selectedAddress || !billingAddress) {
      alert("Please select shipping and billing address");
      return;
    }
    if (cartDetail.payable_amount <= 0) {
      props.saveOrder().then((res) => {
        if (res) {
          history("/payment/success", {
            state: { orderID: res.order.id,amount: res.order.base_grand_total },
          });
        }
      });
      return;
    }

    setPaymentModal(true);
  };
  return (
    <>
      <Container className={classes.root} maxWidth="lg">
        {(props.cartDetailLoading ||
          props.addressListLoading ||
          props.orderCreateLoader) && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" disableShrink />
          </Backdrop>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                {productList && productList.length ? (
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      {/* <h2>{t("Shipping Address")}</h2> */}
                      <Grid>
                        <Link
                          to="/address"
                          style={{
                            display: "flex",
                            paddingBottom: 15,
                            textDecoration: "none",
                          }}
                        >
                          <Button variant="contained" color="primary">
                            {t("Back")}
                          </Button>
                        </Link>
                      </Grid>

                      <Card className={classes.borderBoxAddress}>
                        <Grid container spacing={3}>
                          <Grid style={{ padding: 10 }} xs={6}>
                            <Card style={{ padding: 10 }}>
                              <h3>{t("Delivery Address")}</h3>
                              <Grid style={{ paddingLeft: 5, paddingTop: 5 }}>
                                <b>
                                  {selectedAddress.first_name}{" "}
                                  {selectedAddress.last_name}
                                  <Chip
                                    size="small"
                                    style={{ minWidth: 80, marginLeft: 10 }}
                                    color="primary"
                                    variant="outlined"
                                    label={
                                      selectedAddress?.additional?.address_label?.toUpperCase() ||
                                      "OTHERS"
                                    }
                                  />
                                </b>
                                <Grid style={{ marginTop: 5 }}>
                                  {selectedAddress.address1},{" "}
                                  {selectedAddress.address2},{" "}
                                  {selectedAddress.city}
                                  <Grid style={{ marginTop: 7 }}>
                                    Mobile: <b>{selectedAddress.phone}</b>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                          <Grid style={{ padding: 10 }} xs={6}>
                            <Card style={{ padding: 10 }}>
                              <h3>{t("Billing Address")}</h3>
                              <Grid style={{ paddingLeft: 5 }}>
                                <b>
                                  {billingAddress.first_name}{" "}
                                  {billingAddress.last_name}
                                  <Chip
                                    size="small"
                                    style={{ minWidth: 80, marginLeft: 10 }}
                                    color="primary"
                                    variant="outlined"
                                    label={
                                      billingAddress?.additional?.address_label?.toUpperCase() ||
                                      "OTHERS"
                                    }
                                  />
                                </b>
                                <Grid style={{ marginTop: 5 }}>
                                  {billingAddress.address1},{" "}
                                  {billingAddress.address2},{" "}
                                  {billingAddress.city}
                                  <Grid style={{ marginTop: 7 }}>
                                    Mobile: <b>{billingAddress.phone}</b>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={12}>
                <AlertBox />
                {cartDetail.items_count && (
                  <h2>
                    {t("Items")} ({cartDetail.items_count}){" "}
                  </h2>
                )}
                {productList && productList.length ? (
                  productList.map((itm) => (
                    <Card className={classes.borderBox}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <Link
                            style={{ textDecoration: "none", color: "black" }}
                            onClick={() => handleVendorClick(itm.product)}
                            to="#"
                          >
                            <img
                              className={classes.media}
                              src={itm.product?.base_image?.medium_image_url}
                              title="Contemplative Reptile"
                              height="160"
                              width="140"
                            />
                          </Link>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <CardContent style={{ padding: 0 }}>
                            <Typography variant="h4" component="h2">
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                }}
                                onClick={() => handleVendorClick(itm.product)}
                                to="#"
                              >
                                {itm.product.name}
                              </Link>
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                              style={{ lineHeight: 2 }}
                            >
                              {t("Sold by")}{" "}
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/vendor/profile/${itm.product.seller.url}`}
                              >
                                {itm.product.seller.shop_title}
                              </Link>
                            </Typography>{" "}
                            <Grid spacing="2">
                              {Object.keys(
                                itm.additional?.attributes || {}
                              ).map((attr) => (
                                <Chip
                                  style={{ margin: 2 }}
                                  label={
                                    t(
                                      itm.additional.attributes[attr]
                                        .attribute_name
                                    ) +
                                    ": " +
                                    itm.additional.attributes[attr].option_label
                                  }
                                />
                              ))}
                              {itm.additional?.quantity && (
                                <Chip
                                  style={{ margin: 2 }}
                                  label={t("Qty") + ": " + itm.quantity}
                                />
                              )}
                            </Grid>
                            <DeliveryMessage
                              full_fill_by_meairaq={
                                itm.product.full_fill_by_meairaq
                              }
                            />
                          </CardContent>
                        </Grid>
                        <Grid item xs={3} md={3}>
                          <Typography variant="h4" component="h2">
                            {itm.formated_total}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  ))
                ) : (
                  <Grid className={classes.noRecord}>
                    <h3 style={{ textAlign: "center" }}>
                      <strong> {t("Your Cart is Empty")}</strong>
                    </h3>
                    <Link
                      to="/products"
                      style={{ textAlign: "center", textDecoration: "none" }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          color: "white",
                          textAlign: "center",
                          marginTop: 15,
                        }}
                      >
                        {t("continue shopping")}
                      </Button>
                    </Link>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              {cartDetail &&
              cartDetail.formated_grand_total &&
              cartDetail.items_count ? (
                <Grid xs={12}>
                  <h2>
                    {t("Price Details")} ({cartDetail.items_count})
                  </h2>
                  {props.loading_order_create ? (
                    <Simmer total={1} />
                  ) : (
                    <>
                      <Card className={classes.borderBox}>
                        <Grid xs={12} container spacing={0}>
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Total Price")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.formated_sub_total}
                            </p>
                          </Grid>
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Shipping Charges")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.selected_shipping_rate
                                ?.formated_price || "0.00"}
                            </p>
                          </Grid>
                          {cartDetail.discount > 0 ? (
                            <Grid xs={8} md={8}>
                              <p style={{ color: "grey" }}>
                                {t("Total Discount ")}:
                              </p>
                            </Grid>
                          ) : (
                            " "
                          )}
                          {cartDetail.discount > 0 ? (
                            <Grid xs={4} md={4}>
                              <p style={{ fontWeight: "600" }}>
                                {"-"}
                                {cartDetail.formated_discount}
                              </p>
                            </Grid>
                          ) : (
                            " "
                          )}
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Deduct From Wallet")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {"-"}
                              {cartDetail.formated_wallet_detected}
                            </p>
                          </Grid>

                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Grand Total")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.formated_payable_amount}
                            </p>
                          </Grid>
                        </Grid>
                      </Card>
                      <Grid xs={12} container spacing={0}>
                        <Button
                          className={classes.checkoutButton}
                          variant="contained"
                          color="primary"
                          onClick={handleOrderClick}
                        >
                          {t("Proceed to checkout")}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
        <PaymentOptions
          open={paymentModal}
          onUpdate={() => {}}
          t={t}
          savePaymentMethod={props.savePaymentMethod}
          onClose={() => setPaymentModal(false)}
          methods={
            props.paymentMethods?.length
              ? props.paymentMethods
              : JSON.parse(localStorage.getItem("payment_methods")) || []
          }
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartDetail: state.cart.cartDetail,
  cartDetailLoading: state.cart.cartDetailLoading,
  openAddressPopup: state.cart.openAddressPopup,
  createAddressLoader: state.cart.createAddressLoader,
  addressList: state.cart.addressList,
  addressListLoading: state.cart.addressListLoading,
  loading_order_create: state.cart.loading_order_create,
  paymentMethods: state.cart.shippingMethods,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getCompleteCart,
    removeItemFromCart,
    moveItemToWishList,
    updateProductQuantity,
    createAddress,
    openUpdateAddress,
    getAddresses,
    removeAddress,
    updateAddress,
    updateCartAddress,
    savePaymentMethod,
    saveOrder,
  })(Checkout)
);
