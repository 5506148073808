const initialState = {
    orderList: [],
    orderListMetaData: {
        "current_page": 1,
        "per_page": 12,
        "total" : 0
    },
    orderCreateLoader: false,
    orderListLoading: false,
    orderDetail : {},
    orderDetailLoading : false,
    cancelOrderStatus: false,
    paymentCreateLoader: false,
    clientToken: false
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case "CREATING_PAYMENT":
        return {
          ...state,
          paymentCreateLoader: payload,
        };
      case "CLIENT_TOKEN":
        return {
          ...state,
          clientToken: payload,
        };
      case "CREATING_ORDER":
        return {
          ...state,
          orderCreateLoader: payload,
        };
      case "ORDER_LIST":
        return {
          ...state,
          orderList: payload,
          cancelOrderStatus: false
        };
      case "CREATE_ORDER_REDIRECT":
        return{
          ...state,
          redirect_url : payload
        }
      case "RESET_ORDER_REDIRECT":
        return{
          ...state,
          redirect_url : null
        }
      case "ORDER_LIST_METADATA":
        return {
          ...state,
          orderListMetaData: payload,
        };
      case "ORDER_LIST_LOADING" : 
        return {
          ...state,
          orderListLoading : payload
        } 
        
      case "ORDER_DETAIL":
        return {
          ...state,
          orderDetail: payload,
        };
      case "ORDER_DETAIL_LOADING" : 
        return {
          ...state,
          orderDetailLoading : payload
        }  
      case "CANCEL_ORDER_SUCCESS" :
        return {
          ...state,
          cancelOrderStatus : payload
        } 
      default:
        return state;
    }
  }
  