import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { getBanner } from "actions/banner";
import { connect } from "react-redux";
import {
  Grid,
  Link,
  Box,
  Typography,
  ImageList,
  ImageListItem,
} from "@mui/material";

const MiniBanner = ({ main_banner, t, getBanner }) => {
  const [banner, setBanner] = useState([]);
  const getRandomFour = (itm = []) => {
    if (itm.length <= 4) {
      return itm;
    }
    let min = getRandom(itm.length - 4);
    return itm.slice(min, min + 4);
  };
  const getRandom = (len) => {
    return parseInt(Math.random() * (len - 0) + 0);
  };
  useEffect(() => {
    let h = [];
    main_banner["best_of_mobile_&_laptop"]?.length &&
      h.push({
        title: t("Best of Laptops and Mobile"),
        banner:
          main_banner["best_of_mobile_&_laptop"][
            getRandom(main_banner["best_of_mobile_&_laptop"].length)
          ],
      });
    main_banner["category_wise_banner"]?.length &&
      h.push({
        title: t("Choose by category"),
        banner: getRandomFour(main_banner["category_wise_banner"]),
        multiple: true,
      });
    main_banner["gift_on_budget"]?.length &&
      h.push({
        title: t("Gifts on Budget"),
        banner: getRandomFour(main_banner["gift_on_budget"]),
        multiple: true,
      });
    main_banner["beauty_products"]?.length &&
      h.push({
        title: t("Beauty Products"),
        banner:
          main_banner["beauty_products"][
            getRandom(main_banner["beauty_products"].length)
          ],
      });
    setBanner(h);
  }, [main_banner]);
  return (
    <Box className="mainboxproduct">
      <Grid container spacing={2} className="usemobile">
        {banner &&
          banner.map((itm) => (
            <Grid item xs={12} sm={6} md={3} key={itm.title}>
              <Box className="BoxInner">
                <Box className="BoxInnercontent">
                  <Typography variant="h3">{itm.title}</Typography>
                  {itm.multiple ? (
                    <>
                      <ImageList
                        className="Someinnerproduct"
                        rowHeight={itm.banner.length > 1 ? 226 : 452}
                        cols={itm.banner.length > 1 ? 2 : 1}
                      >
                        {itm.banner?.map((item, i) => (
                          <ImageListItem
                            key={i}
                            className="productcat"
                            cols={itm.banner.length === 2 ? 2 : 1}
                          >
                            <Link
                              href={item.action ? item.action : "#"}
                              color="inherit"
                            >
                              <img src={item.image_url} alt="#"></img>
                              <Typography variant="body2">
                                {item.title}
                              </Typography>
                            </Link>
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </>
                  ) : (
                    <>
                      <Link
                        href={itm.banner?.action ? itm.banner?.action : "#"}
                        color="inherit"
                      >
                        <img src={itm.banner?.image_url} alt="#"></img>
                      </Link>
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  main_banner: state.banner.list,
  selected_country: state.countries.selected_country,
  loading: state.banner.Loading,
});
export default connect(mapStateToProps, {
  getBanner,
})(MiniBanner);
