import axios from "axios";
import { convertObjectIntoQueryString } from "utils/global";
import {
  BASE_URL,
  authHeader,
  params,
  handleCatchException,
  hideloader,
  showloader,
  errormsg,
  successmsg,
} from "../apihelper";

export const getWallets =
  (data = {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "WALLET_LIST_LOADING",
        payload: true,
      });
      const response = await axios.get(
        `${BASE_URL}/wallet/wallet_transactions?${convertObjectIntoQueryString(
          data
        )}`,
        authHeader()
      );
      if (response?.data?.data) {
        dispatch({
          type: "WALLET_LIST",
          payload: response.data.data.data,
        });
        dispatch({
          type: "WALLET_LIST_META",
          payload: {
            current_page: response.data.data.current_page,
            per_page: response.data.data.per_page,
            total: response.data.data.total,
          },
        });
      } else {
        dispatch({
          type: "WALLET_LIST_ERROR",
          payload: null,
        });
      }
      dispatch({
        type: "WALLET_LIST_LOADING",
        payload: false,
      });
    } catch (error) {
      return handleCatchException(error);
    }
  };

export const getUserWallet = () => async (dispatch) => {
  try {
    dispatch({
      type: "WALLET_LIST_LOADING",
      payload: true,
    });
    const response = await axios.get(
      `${BASE_URL}/wallet/balance`,
      authHeader()
    );
    if (response?.data?.data) {
      dispatch({
        type: "WALLET_BALANCE",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "WALLET_LIST_ERROR",
        payload: null,
      });
    }
    dispatch({
      type: "WALLET_LIST_LOADING",
      payload: false,
    });
  } catch (error) {
    return handleCatchException(error);
  }
};
