import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, CircularProgress, Divider, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { AccessTimeOutlined, StarOutline } from "@mui/icons-material";
import { connect } from "react-redux";
import { getReviews } from "actions/vendor";
import { withTranslation } from "react-i18next";
import Moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
  },
  headingTitle: {
    fontSize: 21,
    fontWeight: 600,
  },
  row: {
    display: "flex",
  },
  badge: {
    display: "inline-block",
    padding: ".25em .4em",
    fontWeight: 700,
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".25rem",
    transition:
      "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  headingText: {
    fontSize: "16px",
    fontWeight: 700,
    marginLeft: 10,
  },
  icon: {
    width: 17,
    height: 17,
    color: theme.palette.primary.main,
  },
}));
const limit = 3;
const Review = (props) => {
  const classes = useStyles();
  const [reviewList, setReviewList] = useState([]);
  const [page, setPage] = useState(1);
  const { t } = props;
  useEffect(() => {
    props.getReviews(props.slug, { page: page, limit: limit });
  }, [props.getReviews]);

  useEffect(() => {
    setReviewList(props.reviewList);
  }, [props.reviewList]);

  const handleShowMoreClick = () => {
    props.getReviews(props.slug, { page: page + 1, limit: limit });
    setPage(page + 1);
  };

  return (
    <>
      {reviewList && reviewList.length ? (
        <>
          {reviewList.map((itm) => (
            <>
              <Divider style={{ marginBottom: 20, height: 3 }} />
              <Card style={{ backgroundColor: "inherit", boxShadow: "none" }}>
                <CardContent>
                  <Grid className={classes.row}>
                    <span
                      className={classes.badge}
                      style={{ backgroundColor: "#28a745", color: "white" }}
                    >
                      <span> {itm.rating}</span>
                      <StarOutline style={{ width: 15, height: 12 }} />
                    </span>
                    {/* <span className={classes.headingText}>{itm.comment}</span> */}
                  </Grid>
                  <p style={{ color: "#222" }}>{itm.comment}</p>
                  <span>
                    <span style={{ fontSize: 14, fontWeight: 600 }}>
                      {itm.customer?.first_name} {itm.customer?.last_name}{" "}
                    </span>
                    {itm.created_at && (
                      <span
                        style={{
                          color: "#666",
                          marginLeft: 5,
                          display: "inline-block",
                        }}
                      >
                        |
                        <span>
                          {" "}
                          <AccessTimeOutlined
                            style={{ width: 15, height: 14 }}
                            size="small"
                          />{" "}
                        </span>{" "}
                        {Moment(itm.created_at).fromNow()}
                      </span>
                    )}
                  </span>
                </CardContent>
              </Card>
            </>
          ))}

          {props.reviewListLoading ? (
            <CircularProgress style={{ float: "right" }} />
          ) : (
            page !== props.reviewListMeta.last_page && (
              <Button style={{ float: "right" }} onClick={handleShowMoreClick}>
                {t("Show More")}
              </Button>
            )
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  reviewList: state.vendor.reviewList,
  reviewListMeta: state.vendor.reviewListMeta,
  reviewListLoading: state.vendor.reviewListLoading,
});

const mapDispatchToProps = {
  getReviews,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Review)
);
