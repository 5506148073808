import React, { useEffect } from "react";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useSelector, useDispatch } from "react-redux";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const AlertBox = (props) => {
  const alerts = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (alerts.message !== null) {
      setTimeout(() => dispatch({ type: "REMOVE_ALERT" }), 5000);
    }
  }, [alerts]);

  return (
    alerts.message !== null && (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity={`${alerts.alertType}`}>{alerts.message}</Alert>
      </Stack>
    )
  );
};

export default AlertBox;
