import React, { useEffect, useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Button, Card, Chip, Divider, Grid, Radio } from "@mui/material";
import AddressForm from "./addressForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 560,
    backgroundColor: theme.palette.background.paper,
    // display: "flex"
  },
  buttonAction: {
    marginTop: 12,
  },
  button: {
    marginRight: 10,
  },
  listItem: {
    marginBottom: 2,
    marginTop: 13,
  },
  addButton: {
    // float: "right",
    padding: 8,
  },
  addressGrid: {
    paddingLeft: "13%",
  },
}));

export default function CheckboxList(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState();
  const [openAddAddress, setAddAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const handleToggle = (value) => () => {
    setAddAddress(false);
    setEditAddress(false);
    props.handleSelection(value);
  };

  useEffect(() => {
    if (props.addressList.length) {
      setEditAddress(false);
    }
  }, [props.addressList]);
  const handleCacelAddress = () => {
    setAddAddress(false);
    setEditAddress(false);
  };
  useEffect(() => {
    setChecked(props.selectedAddress);
    setEditAddress(false);
  }, [props.selectedAddress]);
  return (
    <>
      <List
        className={classes.root}
        style={{ minWidth: 35, paddingInlineStart: 2 }}
      >
        <Grid container spacing="3">
          {props.addressList.map((value) => {
            const labelId = `checkbox-list-label-${value.id}`;

            return (
              <>
                <Grid style={{ padding: 10 }} xs="6">
                  <Card style={{ padding: 10, minHeight: 203 }}>
                    <ListItem
                      key={value}
                      role={""}
                      dense
                      button
                      onClick={handleToggle(value)}
                      className={classes.listItem}
                    >
                      <ListItemIcon style={{ minWidth: 32 }}>
                        <Radio
                          name="address"
                          id="address"
                          edge="start"
                          checked={
                            props.compareKey && checked?.additional?.address_id
                              ? checked?.additional?.address_id == value.id
                              : checked?.id == value.id
                          }
                          tabIndex={-1}
                          disableRipple
                          // inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <b>
                          {value.first_name} {value.last_name}{" "}
                        </b>{" "}
                        <Chip
                          size="small"
                          style={{ minWidth: 80, marginLeft: 10 }}
                          color="primary"
                          variant="outlined"
                          label={
                            value?.additional?.address_label?.toUpperCase() ||
                            "OTHERS"
                          }
                        />
                      </ListItemText>
                    </ListItem>
                    <Grid className={classes.addressGrid}>
                      {value.address1}, {value.address2}, {value.additional?.landmark || ''}, {value.city}
                      <Grid style={{ marginTop: 10 }}>
                        Mobile: <b>{value.phone}</b>
                      </Grid>
                      <Grid className={classes.buttonAction}>
                        <Button
                          style={{
                            color: "red",
                            borderColor: "red",
                            fontWeight: 600,
                          }}
                          className={classes.button}
                          color="primary"
                          variant="outlined"
                          onClick={() => props.removeAddress(value.id)}
                        >
                          {props.t("Delete")}
                        </Button>

                        <Button
                          className={classes.button}
                          color="primary"
                          variant="outlined"
                          style={{ fontWeight: 600 }}
                          onClick={() => {
                            setChecked(value);
                            setAddAddress(true);
                            setEditAddress(true);
                          }}
                        >
                          {props.t("Edit")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </>
            );
          })}
        </Grid>
      </List>
      {openAddAddress ? (
        <>
          <Divider />
          <AddressForm
            createAddressLoader={props.createAddressLoader}
            handleAddressCreate={props.handleAddressCreate}
            cancelAddress={handleCacelAddress}
            updateAddress={props.updateAddress}
            title={props.title}
            formData={editAddress ? checked : {}}
          />
        </>
      ) : (
        <>
          <Card>
            <Grid className={classes.addButton}>
              <Button
                style={{ fontSize: 15, paddingLeft: 17, fontWeight: 600 }}
                // variant="outlined"
                color="primary"
                onClick={() => {
                  setAddAddress(true);
                  setEditAddress(false);
                }}
              >
                + {props.t("Add  New Address")}
              </Button>
            </Grid>
          </Card>
        </>
      )}
    </>
  );
}
