import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Alert from "../Notifications/Alert";

import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { changePhone } from "../../actions/auth";
import { withTranslation } from "react-i18next";

const useStyles = makeStyles((theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    linkcls: {
      color: "#268751",
      textDecoration: "none",
      width: "100%",
      textAlign: "center",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    phoneinput: {
      width: "100% !important",
    },
  })
);
const VerifyCredential = ({ t }) => {
  const location = useLocation();

  const classes = useStyles();

  let history = useNavigate();

  const dispatch = useDispatch();

  const [loginWithPhone, setLoginWithPhone] = useState(false);

  useEffect(() => {
    let is_number = location.state.type == "phone" ? true : false;
    setFormData({ ...formData, ["type"]: location.state.type });
    setLoginWithPhone(is_number);
  }, [location]);

  const [formData, setFormData] = useState({
    email: null,
    phone: null,
    phone_code: null,
    type: null,
  });

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneCode = (e) => {
    setFormData({ ...formData, ["phone_code"]: "+" + e });
  };

  const onSubmit = (e) => {
    let loginData = {};
    e.preventDefault();
    if (loginWithPhone) {
      loginData = { ...loginData, ...formData, email: null };
    } else {
      loginData = { ...loginData, ...formData, phone: null, phone_code: null };
    }
    setFormData(loginData);
    //call register api
    dispatch(changePhone(loginData)).then((res) => {
      if (res.status) {
        history("/verify", {
          state: { formData: loginData, pagefrom: "change-phone" },
        });
      }
    });
  };

  return (
    <Container maxWidth="sm" component="main">
      <Box component="div" m={8}>
        <Typography variant="h5" align="center">
          {t("Update " + formData.type)}
        </Typography>
        <Alert />
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container>
            <Grid container item xs={6} sm={12}>
              {loginWithPhone && (
                <PhoneInput
                  country={"iq"}
                  value={formData.phone_code}
                  onChange={handlePhoneCode}
                  inputProps={{ readOnly: true, name: "phone_code" }}
                  inputClass={classes.phoneinput}
                  enableSearch={true}
                />
              )}
            </Grid>
            <Grid container item xs={6} sm={loginWithPhone ? 12 : 12}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id={loginWithPhone ? "phone" : "email"}
                label={t("Email or Phone")}
                name={loginWithPhone ? "phone" : "email"}
                type="text"
                autoFocus={true}
                onChange={(e) => onChange(e)}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
          >
            {t("Send Otp")}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default withTranslation()(VerifyCredential);
