import React, { useEffect, useState } from "react";
import { AppBar, Container, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Divider, Link, Hidden } from "@mui/material";

import Topbar from "components/Layouts/Header/Topbar";
import Menubar from "components/Layouts/Header/Menubar";
import Spinner from "components/Notifications/Spinner";
import "main.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: theme.spacing(8) - 4,
      display: "flex",
      alignContent: "center",
      padding: 16,
    },
    drawerPaper: {
      width: 250,
      "& .MuiListItemText-primary": {
        fontSize: theme.spacing(2),
      },
      "& .MuiDrawer-paper": {
        background: "#01061f",
        width: 250,

        paddingTop: 60,
      },
      "& .MuiLink-root": {
        color: "#fff",
        "&:hover": {
          color: theme.palette.primary.dark,
          textDecoration: "none",
        },
      },
    },

    menuButton: {
      marginLeft: "auto",
      color: "#000",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  })
);

const Header = () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 150;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  return (
    <Container className="App" maxWidth={false} disableGutters={true}>
      <Grid container>
        <Grid container item xs={12} sm={12}>
          <AppBar position="static" className={classes.appbar + " menubutton"}>
            <Hidden>
              <Topbar />
              <Menubar isScroll={scroll} />
            </Hidden>

            <Spinner />
          </AppBar>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
