import React, { useState, useEffect } from "react";
import {
  Typography,
  MenuItem,
  Container,
  MenuList,
  Link as SocialLink,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Phone, Email, Facebook, Instagram } from "@mui/icons-material";
import CopyrightData from "./Copyright";
import DownloadApp from "./DownloadApp";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getFooterCategory } from "actions/banner";

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      paddingTop: 15,
      paddingLeft: 5,
      color: "#ffffff",
    },
    title1: {
      textDecoration: "none",
    },
    footmenu: {
      lineHeight: "1rem",
      "& .MuiListItem-button": {
        "&:hover": {
          backgroundColor: "none !important",
        },
      },
      "& span.MuiTouchRipple-root": {
        display: "none",
      },
    },
  })
);
const Footer = ({ t, getFooterCategory, config }) => {
  const classes = useStyles();
  const [category, setCategory] = useState({});

  useEffect(() => {
    getFooterCategory().then((res) => {
      if (res) {
        setCategory(res);
      }
    });
  }, []);
  return (
    <Container maxWidth={false} disableGutters={true}>
      <Grid container className={classes.toolbar}>
        <DownloadApp t={t} />
      </Grid>
      <Grid container className="${classes.toolbar} gray footertext">
        <Grid container item xs={12} md={3} sm={6}>
          <MenuList>
            <MenuItem className={classes.footmenu}>
              <Typography className={classes.title} variant="h4">
                {t("Company Info")}
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/about-us" className={classes.title1}>
                  {t("About Us")}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/contact-us" className={classes.title1}>
                  {t("Contact Us")}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/our-policy" className={classes.title1}>
                  {t("Terms & Conditions")}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/our-policy" className={classes.title1}>
                  {t("Shipping Terms")}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/our-policy" className={classes.title1}>
                  {t("Privacy Policy")}
                </Link>
              </Typography>
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid container item xs={12} md={3} sm={6}>
          <MenuList>
            <MenuItem className={classes.footmenu}>
              <Typography className={classes.title} variant="h4">
                {t("Sell On Sooqiraq")}
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/become-seller" className={classes.title1}>
                  {t("Become a Vendor")}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/selling-policy" className={classes.title1}>
                  {t("Selling Policy")}
                </Link>
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/faq" className={classes.title1}>
                  {t("FAQ")}
                </Link>
              </Typography>
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid container item xs={12} md={3} sm={6}>
          <MenuList>
            <MenuItem className={classes.footmenu}>
              <Typography className={classes.title} variant="h4">
                {t("Buy On Sooqiraq")}
              </Typography>
            </MenuItem>
            {category.length &&
              category.map((item, i) => {
                if (item.id != 1)
                  return (
                    <MenuItem className={classes.footmenu} key={i}>
                      <Typography variant="inherit" className={classes.title}>
                        <Link
                          to={`/products/category/${item.slug}`}
                          className={classes.title1}
                        >
                          {item.name}
                        </Link>
                      </Typography>
                    </MenuItem>
                  );
              })}
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title}>
                <Link to="/page/return-policy" className={classes.title1}>
                  {t("Return Policy")}
                </Link>
              </Typography>
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid container item xs={12} md={3} sm={6}>
          <MenuList>
            <MenuItem className={classes.footmenu}>
              <Typography className={classes.title} variant="h4">
                {" "}
                {t("Contact Us")}
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title1}>
                <IconButton color="inherit" edge="start" className={classes.menuButton} size="large">
                  <Phone />
                </IconButton>
                {config && config.admin_phone.replace("+964 ", "0")}
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography variant="inherit" className={classes.title1}>
                <IconButton color="inherit" edge="start" className={classes.menuButton} size="large">
                  <Email />
                </IconButton>
                {config && (
                  <SocialLink href={"mailto:" + config?.admin_email}>
                    {config?.admin_email}
                  </SocialLink>
                )}
              </Typography>
            </MenuItem>
            <MenuItem className={classes.footmenu}>
              <Typography className={classes.title} variant="h4">
                {" "}
                {t("Follow Us")}
              </Typography>
            </MenuItem>
            <MenuItem>
              <SocialLink
                href="https://www.facebook.com/soooqiraq"
                target="_blank"
                className={classes.socialCls}
              >
                <IconButton color="inherit" edge="start" className={classes.title1} size="large">
                  <Facebook />
                </IconButton>
              </SocialLink>
              <SocialLink
                href="https://www.instagram.com/meairaqinfo"
                target="_blank"
                className={classes.socialCls}
              >
                <IconButton color="inherit" edge="start" className={classes.title1} size="large">
                  <Instagram />
                </IconButton>
              </SocialLink>
            </MenuItem>
          </MenuList>
        </Grid>
      </Grid>
      <Grid container className={classes.toolbar}>
        <CopyrightData t={t} />
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  config: state.config.detail,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getFooterCategory,
  })(Footer)
);
