const initialState = {
  productList: [],
  productListMetaData: {
      "current_page": 1,
      "per_page": 12,
      "total" : 0
  },
  productFilterLoading : false,
  productListLoading: true,
  productDetail : {},
  productDetailError : null,
  productAddingInCart : false,
  cartDetailError : null,
  cartDetail : null,
  productConfigDetail: null,
  topSellingProduct:[],
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "TOP_SELLING_PRODUCT_LIST":
      return{
        ...state,
        topSellingProduct:payload,
      }
    case "RESET_PRODUCT_DETAIL":
      return{
        ...state,
        productDetail:null,
        productConfigDetail:null,
        productList: []
      }
    case "PRODUCT_LIST":
      return {
        ...state,
        productList: payload,
      };
      case "PRODUCT_LIST_METADATA":
        return {
          ...state,
          productListMetaData:payload
        };
      case "PRODUCT_FILTER_LOADING":
        return {
          ...state,
          productFilterLoading : payload
        };
      case "PRODUCT_FILTER_LIST":
        return {
          ...state,
          productFilterList : payload
        };
      case "PRODUCT_LIST_LOADING":
        return {
          ...state,
          productListLoading : payload
        };
      case "CART_PRODUCT_ADD" :
        return {
          ...state,
          productAddingInCart : payload
        };

      case "CART_DETAIL" :
        return {
          ...state,
          cartDetail : payload,
          cartDetailError: null
        };
      case "CART_DETAIL_ERROR" :
        return {
          ...state,
          cartDetailError : payload
        };
      case "PRODUCT_DETAIL":
        return {
          ...state,
          productDetail : payload,
          productDetailError : null,
          productConfigDetail: null
        };
      case "PRODUCT_CONFIG_DETAIL":
        return {
          ...state,
          productConfigDetail : payload,
        }
      case "PRODUCT_DETAIL_ERROR":
        return {
          ...state,
          productDetail : payload,
          productDetailError : true
        };
      
        case "SUBMIT_PRODUCT_REVIEW_SUCCESS":
          return {
            ...state,
            reviewSubmit: payload
          }
        case "SUBMIT_PRODUCT_REVIEW_LOADING":
          return {
            ...state,
            submitReviewLoading: payload
          };
        case "PRODUCT_REVIEW_LIST": 
          if(payload.page === 1){
            return {
              ...state,
              reviewList: payload.data
            }  
          }
          let arr = state.reviewList;
          arr.push(...payload.data)
          
          return {
            ...state,
            reviewList: arr
          }

        case "PRODUCT_REVIEW_LIST_METADATA": 
          return{
            ...state,
            reviewListMeta: payload
          }
        case "PRODUCT_REVIEW_LIST_LOADING":
          return{
            ...state,
            reviewListLoading: payload
          }
    default:
      return state;
  }
}
