import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { getSearchProducts } from "../../actions/product";
import { useNavigate } from "react-router-dom";
import { makeStyles, createStyles } from "@mui/styles";
import { withTranslation } from "react-i18next";
import { Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiInputLabel-root": {
        display: "none",
      },
      "& .MuiAutocomplete-inputRoot": {
        // paddingLeft:44,
        paddingRight: "9px !important",
        "& .MuiAutocomplete-input": {
          padding: 0,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
      "& .MuiAutocomplete-endAdornment": {
        display: "none",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 1),
      height: "100%",
      position: "right",
      // pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.main,
    },
  })
);

function SearchBar(props) {
  const history = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const loading = open && options.length === 0;
  const classes = useStyles();

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleTextSearch = (e) => {
    setSearchText(e.target.value);

  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSelect();
    }
  };
  const handleSelect = () => {
    history("/products?search=" + searchText, {
      state: { search: searchText, pagefrom: "search" },
    });
  };
  return (
    <>
      <Autocomplete
        id="searchBar"
        className={classes.root}
        style={{ width: 620 }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onKeyDown={(e) => handleKeyDown(e)}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        clearOnBlur={false}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => handleTextSearch(e)}
            placeholder={
              props.t ? props.t("Search Products...") : "Search Products..."
            }
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              style: { height: 50 },
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <Box className={classes.searchIcon}>
        <SearchIcon onClick={handleSelect} />
      </Box>
    </>
  );
}

export default withTranslation()(React.memo(SearchBar));
