import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getVendorsBySlug,
  createReview,
  reportVendor,
  followVendor,
  getVendorsProductBySlug,
  resetComponent,
} from "actions/vendor";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Card,
  CardContent,
  Box,
  Button,
  Grid,
  LinearProgress,
  Link,
  Divider,
  Typography,
  TextField,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  AccessTimeOutlined,
  Email,
  LocationOn,
  MobileOff,
  Phone,
  PhoneAndroid,
  Share,
  StarOutline,
  WhatsApp,
  Favorite,
  FavoriteBorder,
  Flag,
  ReportOff,
  Report,
} from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import Rating from "@mui/material/Rating";
import Alert from "../Notifications/Alert";
import VendorProduct from "./vendorProduct";
import Review from "./review";
import AlertBox from "components/Notifications/Alert";
import FlashDealProduct from "./FlashDealProduct";
import SimilarVendorSameCountry from "./SimilarVendorSameCountry";
import { useDispatch } from "react-redux";
import NotFound from "components/common/NotFound";
import Sharepopup from "components/common/sharepopup";
import VendorSocialLink from "./VendorSocialLink";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 15,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 15,
  },
  banner_img: {
    width: "100%",
    height: 460,
    padding: 10,
  },
  profile_img: {
    width: 130,
    height: 130,
    marginTop: -195,
    marginLeft: 50,
  },
  headingTitle: {
    fontSize: 21,
    fontWeight: 600,
  },
  row: {
    display: "flex",
  },
  button: {
    marginTop: 25,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  icon: {
    width: 17,
    height: 17,
    color: theme.palette.primary.main,
  },
  
  share: {
    color: theme.palette.primary.main,
    padding: 3,
    marginLeft: 10,
    cursor: "pointer",
  },
  subHeading: {
    fontSize: 20,
    fontWeight: 500,
  },
  description: {
    marginBottom: 0,
    display: "-webkit-box",
    "-webkit-line-clamp": 3,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  readMore: {
    marginTop: 5,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  row: {
    display: "flex",
  },
  h2: {
    fontSize: 21,
    lineHeight: 1,
  },
  headingText: {
    fontSize: "16px",
    fontWeight: 700,
    marginLeft: 10,
  },
  slider: {
    height: 10,
    backgroundColor: "#f1f1f1",
  },
  badge: {
    display: "inline-block",
    padding: ".25em .4em",
    fontWeight: 700,
    lineHeight: 1,
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "baseline",
    borderRadius: ".25rem",
    transition:
      "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  },
  preferred: {
    position: "absolute",
    right: "20%",
    marginRight: "50px",
    transform: "translate(30%, -40%)",
    cursor: "pointer",
    background: "#ffff",
    borderRadius: "10px",
    height: "35px",
    [theme.breakpoints.down("md")]: {
      padding: "16px",
      transform: "translate(-50%, -50%)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "16px",
      width: "80%",
      transform: "translate(-50%, -42%)",
    },
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Rating5Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#2196F3",
  },
})(LinearProgress);

const Rating4Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#2196F3",
  },
})(LinearProgress);
const Rating3Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#00bcd4",
  },
})(LinearProgress);
const Rating2Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#ff9800",
  },
})(LinearProgress);
const Rating1Progress = withStyles({
  barColorSecondary: {
    backgroundColor: "#f44336",
  },
})(LinearProgress);

const MyComponent = {
  Rating1Progress: Rating1Progress,
  Rating2Progress: Rating2Progress,
  Rating3Progress: Rating3Progress,
  Rating4Progress: Rating4Progress,
  Rating5Progress: Rating5Progress,
};

const Detail = (props) => {
  const { t } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [vendor, setvendor] = useState({});
  const [descriptionPopup, setDesPopup] = useState(false);
  const [reportedPopup, setReportPopup] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [visibleShare, setShareVisible] = useState(false);
  const [ratingObj, setRatingObj] = useState([
    { text: "5 Star", rating: 0, value: 0, total: 0 },
    { text: "4 Star", rating: 0, value: 0, total: 0 },
    { text: "3 Star", value: 0, rating: 0, total: 0 },
    { text: "2 Star", value: 0, rating: 0, total: 0 },
    { text: "1 Star", value: 0, rating: 0, total: 0 },
  ]);
  const [ratings, setRatings] = useState({});

  const [userRating, setRating] = useState("");
  const [userReview, setReview] = useState("");
  const [reason, setReason] = useState(null);
  const [showReview, setShowReview] = useState(true);
  let { slug } = useParams();
  useEffect(() => {
    props.getVendorsBySlug(slug);
    window.scrollTo(0, 0);
  }, [props.getVendorsBySlug, slug, props.selected_country]);

  // useEffect(() => {
  //   props.resetComponent()
  // }, [props.resetComponent])
  useEffect(() => {
    props.reviewSubmit && setShowReview(false);
  }, [props.reviewSubmit]);
  useEffect(() => {
    props.vendorDetail && setvendor(props.vendorDetail?.seller);

    const vendorRating = props.vendorDetail?.seller?.reviews;
    if (vendorRating && vendorRating.percentage) {
      let ratings = Array.isArray(vendorRating.percentage)
        ? vendorRating.percentage
        : JSON.parse(vendorRating.percentage);
      setRatingObj([
        {
          text: "5 Star",
          value: 5,
          rating: ratings["5"],
          total: parseInt((ratings["5"] / 100) * vendorRating.total),
        },
        {
          text: "4 Star",
          value: 4,
          rating: ratings["4"],
          total: parseInt((ratings["4"] / 100) * vendorRating.total),
        },
        {
          text: "3 Star",
          value: 3,
          rating: ratings["3"],
          total: parseInt((ratings["3"] / 100) * vendorRating.total),
        },
        {
          text: "2 Star",
          value: 2,
          rating: ratings["2"],
          total: parseInt((ratings["2"] / 100) * vendorRating.total),
        },
        {
          text: "1 Star",
          value: 1,
          rating: ratings["1"],
          total: parseInt((ratings["1"] / 100) * vendorRating.total),
        },
      ]);
      setRatings(vendorRating);
    }
    if (
      (localStorage.getItem("country") === "false" ||
        !localStorage.getItem("country")) &&
      props.countries &&
      props.countries.length
    ) {
      let prductCountryId = props.vendorDetail?.seller?.country_id;
      if (prductCountryId) {
        let country = props.countries.filter(
          (itm) => itm.id == prductCountryId
        );
        country && dispatch({ type: "SELECTED_COUNTRY", payload: country[0] });
      }
    }
    return () => {
      props.resetComponent();
    };
  }, [props.vendorDetail, props.countries]);

  const follow = (id) => {
    props.followVendor(id).then((res) => {
      if (res.status) {
        setvendor({ ...vendor, is_follow: !vendor.is_follow });
      }
    });
  };

  const report = (e) => {
    e.preventDefault();
    let formData = {
      reason: reason,
      vendor_id: vendor.id,
    };
    props.reportVendor(formData).then((res) => {
      if (res.status) {
        setvendor({ ...vendor, is_reported: !vendor.is_reported });
        setReportPopup(false);
        setReason(null);
      }
    });
  };

  const renderRating = (itm) => {
    const NewComponent = MyComponent[`Rating${itm.value.toString()}Progress`];
    return (
      <NewComponent
        strokeLinecap="round"
        color={itm.rating == 5 ? "primary" : "secondary"}
        className={classes.slider}
        value={itm.rating || 0}
        variant="determinate"
      />
    );
  };

  const handleReviewSubmit = () => {
    props.createReview(
      {
        comment: userReview,
        rating: userRating,
      },
      vendor.url
    );
  };

  if (!vendor?.id) {
    return <NotFound />;
  }

  return (
    <Grid className={classes.root}>
      <Grid className={classes.row + " widthfixouterbox"}>
        <Grid md="12" className="w-100">
          <Alert />
          {
            <img
              className={classes.banner_img}
              src={vendor.banner_url || "/vendor_banner.png"}
            ></img>
          }
          <Avatar
            className={classes.profile_img + " avatararebic"}
            src={vendor.logo ? vendor.logo : "/featured_vendor.png"}
          />
          {props.user && (
            <>
              <Box
                component="div"
                className={classes.preferred + " wishlistarebic"}
                onClick={() => follow(vendor.id)}
              >
                {vendor.is_follow ? (
                  <Favorite color="primary" fontSize="large" />
                ) : (
                  <FavoriteBorder color="primary" fontSize="large" />
                )}
              </Box>
            </>
          )}

          <Grid
            container
            style={{
              marginTop: 50,
              borderRadius: 10,
              backgroundColor: "white",
              borderRadius: 10,
            }}
          >
            <Grid md="8">
              <Grid
                style={{
                  backgroundColor: "white",
                  padding: "5px ​0px 5px 26p",
                  borderRadius: 10,
                }}
              >
                <Grid className={classes.row} container>
                  <Grid md="6" className="icon-orange" style={{ padding: 10 }}>
                    <span className={classes.headingTitle}>
                      {vendor.shop_title}
                    </span>
                    <div style={{ marginBottom: 6, marginTop: 6 }}>
                      <LocationOn className={classes.icon} />
                      <span style={{ marginLeft: 5 }}>
                        {vendor.address1} {vendor.address2}, {vendor.city},{" "}
                        {vendor.state}, {vendor.postcode}
                      </span>
                    </div>
                    <div style={{ marginBottom: 6 }}>
                      <PhoneAndroid className={classes.icon} />
                      <span style={{ marginLeft: 5 }}>
                        {"+"}
                        {vendor.phone_code} {vendor.phone}
                      </span>
                    </div>
                    <div style={{ marginBottom: 6 }}>
                      <Email className={classes.icon} />
                      <span style={{ marginLeft: 5 }}>{vendor.email}</span>
                    </div>
                    <Grid
                      className={classes.row + " shareiconup"}
                      style={{ marginTop: 10 }}
                    >
                      <span
                        onClick={() => setShareVisible(!visibleShare)}
                        className={classes.share}
                      >
                        <Share
                          className={classes.iconShare}
                          fontSize="medium"
                        // className="shareiconcolor"
                        />
                      </span>

                      <span className={classes.share}>
                        <Link
                          target="_blank"
                          title={t("share profile")}
                          href={`https://web.whatsapp.com/send?phone=${vendor.phone_code}${vendor.phone}&text=${window.location.href}`}
                        >
                          <WhatsApp
                            className={classes.iconShare}
                            fontSize="medium"
                          />
                        </Link>
                      </span>
                      {props.user && (
                        <>
                          <span className={classes.share}>
                            {vendor.is_reported ? (
                              <Link title={props.t("Reported Vendor")}>
                                <ReportOff
                                  className={classes.iconShare}
                                  fontSize="medium"
                                />
                              </Link>
                            ) : (
                              <Link
                                title={props.t("Report Vendor")}
                                onClick={() => setReportPopup(true)}
                              >
                                <Report
                                  className={classes.iconShare}
                                  fontSize="medium"
                                />
                              </Link>
                            )}
                          </span>
                        </>
                      )}
                    </Grid>
                    <Sharepopup
                      setOpen={() => setShareVisible(false)}
                      open={visibleShare}
                      title={vendor.shop_title}
                      url={window.location.href}
                      
                    />
                  </Grid>
                  <Grid md="6" style={{ padding: 10 }}>
                    <Grid p="4">
                      <span className={classes.subHeading}>
                        {" "}
                        {t("About Vendor")}
                      </span>
                      <p
                        className={classes.description}
                        style={{
                          fontSize: 14,
                          color: "#999999",
                          lineHeight: 1.8,
                        }}
                      >
                        {vendor.description}
                      </p>
                      {vendor.description && (
                        <span
                          className={classes.readMore}
                          onClick={() => setDesPopup(true)}
                        >
                          {t("Read More")}
                        </span>
                      )}
                      <VendorSocialLink vendor={vendor}/>
                     
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              md="4"
              style={{
                backgroundColor: "white",
                borderRadius: 10,
                padding: 10,
              }}
            >
              <span
                className={classes.subHeading}
                style={{ marginLeft: 13, marginTop: 5 }}
              >
                {t("Ratings & Reviews")}
              </span>

              <Box component="fieldset" mb={3} borderColor="transparent">
                {ratingObj.map((itm) => (
                  <Grid style={{ height: 22 }} container>
                    <Grid item xs="2" md="2">
                      <p>{itm.text}</p>
                    </Grid>
                    <Grid
                      item
                      xs="8"
                      md="8"
                      style={{ height: 21, paddingTop: 21 }}
                    >
                      {renderRating(itm)}
                    </Grid>
                    <Grid xs="2" md="2">
                      <p style={{ marginLeft: 5 }}>{itm.total || 0}</p>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>

          <VendorProduct slug={slug} id={vendor?.id} />
          <FlashDealProduct vendor={vendor} />
          <SimilarVendorSameCountry vendor={vendor} />
          <Grid>
            <Card style={{ backgroundColor: "inherit", boxShadow: "none" }}>
              <CardContent>
                <Grid className={classes.row}>
                  <Grid xs={12} md={6}>
                    <Box component="fieldset" mb={3} borderColor="transparent">
                      <p className={classes.h2}>{t("Seller Rating")}</p>
                      <Rating
                        name="read-only"
                        value={ratings.average_rating}
                        readOnly
                      />
                      <p>
                        {ratings.average_rating} {t("average based on")}{" "}
                        {ratings.total} {t("Reviews")}.
                      </p>
                    </Box>
                  </Grid>
                  {localStorage.getItem("token") && (
                    <Grid xs={12} md={6}>
                      <Box
                        component="fieldset"
                        mb={3}
                        borderColor="transparent"
                      >
                        <p className={classes.h2}>{t("Rate this Seller")}</p>
                        <Box component="fieldset" borderColor="transparent">
                          <Rating
                            name="simple-controlled"
                            value={userRating}
                            onChange={(event, newValue) => {
                              setRating(newValue);
                              setShowReview(true);
                            }}
                          />
                        </Box>
                        {userRating && showReview && (
                          <Box component="fieldset" borderColor="transparent">
                            <TextField
                              id="outlined-multiline-static"
                              label="Review *"
                              multiline
                              style={{ width: 400 }}
                              rows={3}
                              placeholder={t("Write your review here")}
                              variant="outlined"
                              onChange={(e) => setReview(e.target.value)}
                            />
                          </Box>
                        )}
                        {userRating &&
                          userReview &&
                          showReview &&
                          (props.submitReviewLoading ? (
                            <CircularProgress style={{ marginLeft: "45%" }} />
                          ) : (
                            <Button
                              className={classes.button}
                              style={{ marginLeft: "45%" }}
                              disabled={!userRating}
                              variant="contained"
                              color="primary"
                              onClick={handleReviewSubmit}
                            >
                              {t("Submit")}
                            </Button>
                          ))}
                        <AlertBox type="REVIEW_SUBMIT" />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
            <Review slug={slug} />
          </Grid>
        </Grid>
        {/* <Grid xs="12" md="2">
          <SimilarVendorOtherCountry vendor={vendor} />
        </Grid> */}
      </Grid>
      {vendor.description && (
        <Dialog
          open={descriptionPopup}
          onClose={(value) => setDesPopup(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogContent>
          <DialogTitle><h2>{t("About Vendor")}</h2></DialogTitle>
             <Typography>{vendor.description}</Typography>
           
         </DialogContent>
        </Dialog>
      )}
      {props.user && (
        <Dialog
          open={reportedPopup}
          onClose={(value) => setReportPopup(false)}
          aria-labelledby="report-modal-title"
          aria-describedby="report-modal-body"
        >
           <DialogContent>
            <Box component="div" align="center">
              <Typography variant="h5" align="center">
                {props.t("Report Vendor")}
              </Typography>
              <form
                onSubmit={(e) => {
                  report(e);
                }}
              >
                <TextField
                  variant="outlined"
                  multiline
                  margin="normal"
                  fullWidth
                  id="reason"
                  name="reason"
                  autoComplete="reason"
                  rows={4}
                  cols={8}
                  type="text"
                  placeholder={props.t("Enter your reason here")}
                  required
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setReason(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {props.t("Submit")}
                </Button>
              </form>
            </Box>
        </DialogContent>
        </Dialog>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  vendorDetail: state.vendor.vendorDetail,
  vendorProduct: state.vendor.vendorProduct,
  user: state.auth.user,
  submitReviewLoading: state.vendor.submitReviewLoading,
  selected_country: state.countries.selected_country,
  countries: state.countries.countries,
  reviewSubmit: state.vendor.reviewSubmit,
});

const mapDispatchToProps = {
  getVendorsBySlug,
  getVendorsProductBySlug,
  followVendor,
  reportVendor,
  createReview,
  resetComponent,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Detail)
);


