const initialState = {
    category: [],
    Loading: false,
    listError: false,
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case "SUPERMARKET_CATEGORY_LIST":
        return {
          ...state,
          category: payload,
        };
      case "SUPERMARKET_CATEGORY_LIST_ERROR":
        return {
          ...state,
          category: payload,
          listError: true,
        };
      case "SUPERMARKET_CATEGORY_LIST_LOADING":
        return {
          ...state,
          Loading: payload,
        };
      default:
        return state;
    }
  }
  