const initialState = {
    message: null,
    alertType: "success",
    error: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "ALERT_SHOW":
      return {
        ...state,
        ...payload
      };
    case "REMOVE_ALERT":
      return {
          ...state,
        ...initialState
      };
    default:
      return state;
  }
}
