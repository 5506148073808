import axios from "axios";
import {
  BASE_URL,
  authHeader,
  params,
  handleCatchException,
  hideloader,
  showloader,
  errormsg,
  successmsg,
  fileauthHeader,
} from "../apihelper";

export const register = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/loginwithotp`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const verifyotp = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/verifyotp`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data?.data) {
      localStorage.setItem("token", response.data.token);
      successmsg(response.data.message);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      return response.data.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const login = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/login`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status) {
      localStorage.setItem("token", response.data.token);
      successmsg(response.data.message);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      return response.data.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const loaduser = () => async (dispatch) => {
  try {
    // showloader();
    const response = await axios.get(
      `${BASE_URL}/customer/get`,
      { params: params() },
      authHeader()
    );
    // hideloader();
    if (response.data.status) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      return true;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const logout = () => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/customer/logout`,
      { params: params() },
      authHeader()
    );
    if (response.data.status) {
      localStorage.removeItem("token");
      dispatch({
        type: "LOGOUT",
      });
    }
    return response.data;
  } catch (error) {
    return handleCatchException(error);
  }
};
export const forgotpassword = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/forgot-password`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const resetPassword = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/reset-password`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const profileUpdate = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.put(
      `${BASE_URL}/customer/profile`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const updatePassword = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/update-password`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
//verification-send
export const verificationSend = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/verification-send`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

//verification-complete
export const verificationComplete = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/verification-complete`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

//customer/change_phone
export const changePhone = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/change_phone`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

//customer/verify_phone

export const verifyPhone = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/verify_phone`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status === true) {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

//profile-picture
export const updateProfilePicture = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/customer/profile-picture`,
      formData,
      fileauthHeader()
    );
    hideloader();
    if (response.data.status === true) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

//social-login

export const socialLogin = (formData) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.post(
      `${BASE_URL}/social-login`,
      formData,
      authHeader()
    );
    hideloader();
    if (response.data.status) {
      localStorage.setItem("token", response.data.token);
      successmsg(response.data.message);
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: response.data.data },
      });
      return response.data.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
