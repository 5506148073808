import React from "react";
import { makeStyles, createStyles } from "@mui/styles";

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    TextField,
} from "@mui/material";

import {
    Facebook,
    Instagram,
    Pinterest,
    Twitter,
    WhatsApp,
} from "@mui/icons-material";
import { withTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(2),
    },
    iconButton: {
        marginLeft: theme.spacing(1),
    },
    shareText: {
        margin: theme.spacing(2, 0),
    },
}));

const SharePopUpModal = (props) => {
    const { title, url, open, setOpen, t,picture,description } = props;
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    const handleCopyToClipboard = () => {
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(url);
            console.log(`Copied URL: ${url}`);
        } else {
            unsecuredCopyToClipboard(url);
        }
        setOpen(false);
    };
    const unsecuredCopyToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand('copy')
        }
        catch (err) {
            console.error('Unable to copy to clipboard', err)
        }
        document.body.removeChild(textArea)
    };

    const handleClick = (event, provider) => {
        event.preventDefault();
        switch (provider) {
            case 'facebook':
                return window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&title=${title}&description=${description}&picture=${picture}`, 'Popup','width=600,height=400');
            case 'instagram':
                return window.open(`https://www.instagram.com/?url=${encodeURIComponent(url)}&title=${title}`, '_blank');
            case 'twitter':
                return window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${title}`, '_blank');
            case 'linkedin':
                return window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${title}`, '_blank');
            case 'pinterest':
                return window.open(`http://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&description=${title}`, '_blank');
            case 'mail':
                return window.location.href = `mailto:?subject=${title}&body=${encodeURIComponent(url)}`;
            case 'whatsapp':
                return window.open(`https://web.whatsapp.com/send?text=${title} ${encodeURIComponent(url)}`, '_blank');
            case 'copy':
                return navigator.clipboard.writeText(url);
            default:
                return null;
        }
    };

    return (
        <div className={classes.root}>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t("Share")} {title}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="url"
                        label="URL"
                        type="text"
                        fullWidth
                        value={url}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <IconButton
                        className={classes.iconButton}
                        color="primary"
                        onClick={(event) => handleClick(event, 'facebook')}
                    >
                        <Facebook />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        color="primary"
                        onClick={(event) => handleClick(event, 'twitter')}
                    >
                        <Twitter />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        color="primary"
                        onClick={(event) => handleClick(event, 'instagram')}
                    >
                        <Instagram />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        color="primary"
                        onClick={(event) => handleClick(event, 'whatsapp')}
                    >
                        <WhatsApp />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        color="primary"
                        onClick={(event) => handleClick(event, 'pinterest')}
                    >
                        <Pinterest />
                    </IconButton>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCopyToClipboard} color="primary">
                        {t("Copy Link")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

    );

}
export default withTranslation()(SharePopUpModal);