import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActions, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import Rating from "@mui/material/Rating";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 8,
    boxShadow: "none",
    border: "1px solid #efefef",
  },
  media: {
    width: "100%",
    height: 280,
    position: "relative",
  },
  bottomBorder: {
    width: "50px",
    height: "5px",
    background: theme.palette.primary.main,
    margin: "16px 0",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  cardInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  image: {
    objectFit: "fill",
    borderRadius: 5,
  },
  card: {
    padding: "10px",
  },
  cardContent: {
    marginBottom: 8,
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
  rating: {
    color: "orange",
  },
}));

const VendorCard = ({ item, remove, follow }) => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const handleVendorClick = (item) => {
    localStorage.removeItem("country");
    dispatch({ type: "SELECTED_COUNTRY", payload: false });

    history(`/vendor/profile/${item.url}`);
  };
  return (
    <>
      <Box component="div">
        <Card className={classes.root}>
          <CardActionArea>
            <Link to={`/vendor/profile/${item.url}`} className={classes.link}>
              <CardMedia
                className={classes.media}
                image={item.logo ? item.logo : "/featured_vendor.png"}
                title={item.name}
              ></CardMedia>
            </Link>
            <CardContent>
              <Box
                justifyContent="flex-start"
                alignItems="center"
                display="flex"
              >
                <Box
                  justifyContent="flex-start"
                  alignItems="center"
                  display="flex"
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h5"
                    className={classes.title}
                  >
                    <Link
                      to={() => handleVendorClick(item)}
                      className={classes.link}
                      to="#"
                    >
                      {item.shop_title}
                    </Link>
                  </Typography>
                </Box>
                <Box
                  justifyContent="flex-start"
                  alignItems="right"
                  display="flex"
                ></Box>
              </Box>
              <Box
                width={1}
                justifyContent="space-between"
                alignItems="center"
                display="flex"
              >
                <Box
                  justifyContent="flex-start"
                  alignItems="center"
                  display="flex"
                >
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.ratingCount}
                  ></Typography>
                </Box>
                <Box
                  justifyContent="flex-start"
                  alignItems="center"
                  display="flex"
                >
                  <Tooltip
                    placement="top-start"
                    title={
                      item.reviews.average_rating == 0
                        ? "No rating"
                        : item.reviews.average_rating + " ratings out of 5"
                    }
                  >
                    <span>
                      <Rating
                        value={item.reviews.average_rating || 0}
                        readOnly
                        precision={0.5}
                        name="half-rating-read"
                        size="small"
                        title="test"
                        className="reviewrating"
                      />
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </CardContent>
          </CardActionArea>
          {remove && (
            <CardActions>
              <Button
                startIcon={<Delete />}
                size="small"
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => follow(item.id)}
              >
                Remove
              </Button>
            </CardActions>
          )}
        </Card>
      </Box>
    </>
  );
};

export default VendorCard;
