import React, { useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import JsonLd from './Jsonld';
import { Helmet } from 'react-helmet';

const MetaComponent = (props) => {
    useEffect(() => {
      //Update the meta tags dynamically
      const newMetaTags = [
        { name: 'description', content: props.description },
        { property: 'og:title', content: props.title },
        { property: 'og:description', content: props.description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:image', content: props.image },
        

         // Add more dynamic meta tags
       ];
      
      const metaTags = document.getElementsByTagName('meta');
      Array.from(metaTags).forEach((tag) => {
        if (tag.getAttribute('name') === 'description') {
          tag.setAttribute('content', props.description);
        } else if (tag.getAttribute('property') === 'og:title') {
          tag.setAttribute('content', props.title);
        } else if (tag.getAttribute('name') === 'og:description') {
          tag.setAttribute('content', props.description);
        } else if (tag.getAttribute('property') === 'og:image') {
          tag.setAttribute('content', props.image);
        } else if (tag.getAttribute('property') === 'og:url') {
          tag.setAttribute('content', window.location.href);
        } else if (tag.getAttribute('name') === 'og:keywords') {
          tag.setAttribute('content', props.keywords);
        } else if (tag.getAttribute('name') === 'twitter:url') {
          tag.setAttribute('content', window.location.href);
        } else if (tag.getAttribute('name') === 'twitter:title') {
          tag.setAttribute('content', props.title);
        } else if (tag.getAttribute('name') === 'twitter:description') {
          tag.setAttribute('content', props.description);
        } else if (tag.getAttribute('name') === 'twitter:image') {
          tag.setAttribute('content', props.image);
        }
        // Update more dynamic meta tags if needed
      });
    }, []);

//const MetaComponent = (props) => {
    return (
        <div className="wrapper" >
            <Helmet>
             {/* <MetaTags id="sooq-tag"> */}
                <title>{props.title}</title>
                <meta name="description" content={props.description} />
                <meta property="og:type" content="website" />
                
                <meta name="og:description" content={props.description} />
                <meta property="og:title" content={props.title} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={props.image || ""} />
                <meta property="og:site_name" content="Sooqiraq" />
                <meta name="keywords" content={props.keyword || ""}/>

                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:url" content={window.location.href}/>
                <meta name="twitter:title" content={props.title}/>
                <meta name="twitter:description" content={props.description} />
                <meta name="twitter:image" content={props.image}/>
                <meta name="twitter:site_name" content="@SooqIraq" />

                {
                    props.jsonLd &&
                    <JsonLd data={props.jsonLd} />
                }
                <link rel="image_src" href={props.image || ""} /> 
                {props.images && props.image.length > 1 ? (
                    props.images.map((item) =>{
                        <meta property="og:image" content={item.url || ""} />
                    })
                ) : ( <meta property="og:image" content={props.image || ""} />)}
            {/* </MetaTags> */}
            </Helmet>
        </div>
    );
}
export default MetaComponent;
