import React, { useEffect, useState, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { logout } from "actions/auth";
import {
  Typography,
  Menu,
  MenuList,
  MenuItem,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";

import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { Divider, Link, Hidden, Badge, Box } from "@mui/material";
import { getMainCategory } from "actions/banner";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useRef } from "react";
import SearchBar from "components/Home/searchBar";
import { getCompleteCartCount } from "actions/cart";
import { withStyles } from "@mui/styles";

import {
  AccountBalanceWallet,
  AccountBox,
  Menu as MenuIcon,
  PersonOutline,
  EditLocation,
  ExitToApp,
  FavoriteRounded,
  ListAlt,
  ShoppingCart,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: 0,
      padding: 0,
    },
    toolbar1: {
      backgroundColor: "#F3F5F4",
      height: "50px",
    },

    topbarLeft: {
      display: "flex",
      justifyContent: "flex-end",
    },
    menuButton: {
      marginRight: theme.spacing(1),
      height: "35px !important",
      width: "163px",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.light} !important`,
      },
      borderRadius: "6px",
      fontSize: "16px",
      margin: "8px",
      color: "#ffffff",
      marginTop: theme.spacing(3),
      fontWeight: "bold",
      [theme.breakpoints.down('md')]: {
        height: "initial !important",
        width: "initial !important",
        background: "transparent",
        margin: "0",
        alignSelf: "flex-end",
        display: "flex",
        marginLeft: "auto",
      },
    },

    title: {
      paddingTop: 15,
      color: "#070707",
    },
    menuItem: {
      color: "#070707",
      display: "inherit",
      textTransform: "none",
      textDecoration: "none",
      fontWeight: "bold",
    },
    isSticky: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: "999",
    },
    drawerPaper: {
      minWidth: 300,
    },
    megaMenu: {
      zIndex: 9999,
      boxShadow: "0 4px 4px #ccc",
      "& a": {
        color: "#000",
        "&:hover": {
          textDecoration: "none",
        },
      },

      "& ul": {
        listStyle: "none",
        margin: 0,
        padding: 0,
        "& li": {
          padding: "4px 16px",
        },
      },
    },
    search: {
      position: "relative",
      height: 36,
      margin: 12,
      display: "flex",
      alignItems: "center",
      borderRadius: 4,
      backgroundColor: "#f3f5f4",
      border: "2px solid #f3f5f4",
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main),
        borderColor: theme.palette.primary.main,
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      [theme.breakpoints.down('md')]: {
        display: "none",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 1),
      height: "100%",
      position: "right",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.primary.main,
    },
  })
);
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}))(Badge);
const Menubar = ({
  isScroll,
  getMainCategory,
  t,
  user,
  getCompleteCartCount,
  cartCount,
}) => {
  let history = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    user && getCompleteCartCount();
  }, [getCompleteCartCount, user]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [category, setCategory] = useState([]);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [anchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  useEffect(() => {
    getMainCategory().then((res) => {
      if (res) {
        setCategory(res);
      }
    });
  }, []);

  const classes = useStyles();
  const open = Boolean(anchorEl);
  const Logout = async () => {
    dispatch(logout()).then((res) => {
      handleClose();
      if (res.status) {
        history("/");
      }
    });
  };

  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = useCallback((anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }, [open]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    // console.log(ref);

    const handleClickOutside = useCallback((event) => {
      if (ref.current && !ref.current.contains(event.target) &&
        !event.target.textContent.includes(t("All Category"))) {
        // Click occurred outside the specified element
        setCategoryOpen(false);
      }
    }, [ref]);

    useEffect(() => {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [handleClickOutside]);


    // useCallback(() => {
    //   function handleClickOutside(event) {
    //     if (
    //       ref.current &&
    //       !ref.current.contains(event.target) &&
    //       !event.target.textContent.includes(t("All Category"))
    //     ) {
    //       setCategoryOpen(false);
    //     }
    //   }
    //   // Bind the event listener
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     // Unbind the event listener on clean up
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [ref]);
  }
  return (
    <div className={isScroll == 1 ? classes.isSticky : ""}>
      <Toolbar className={classes.toolbar1} className="topbarouter">
        <Hidden mdDown>
          <Grid container spacing={2}>
            <Grid
              container
              item
              xs={12}
              sm={2}
              md={2}
              className="allcatbtn"
              onClick={() => setCategoryOpen(!categoryOpen)}
            >
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => setCategoryOpen(!categoryOpen)}
                size="large">
                <MenuIcon />
                &nbsp; {t("All Category")}
              </IconButton>
            </Grid>
            <Hidden smDown>
              <Grid
                container
                item
                xs={12}
                sm={7}
                md={6}
                className="searchproduct"
              >
                <Box className={classes.search}>
                  <SearchBar classNameIcon={classes.searchIcon} />
                </Box>
              </Grid>
            </Hidden>
            <Grid
              container
              item
              xs={12}
              sm={3}
              md={4}
              className={classes.topbarLeft}
            >
              <MenuList className={classes.menuItem}>
                <MenuItem className={classes.menuItem}>
                  <NavLink className={classes.menuItem} to="/supermarket">
                    {t("Sooqiraq SuperMarket")}
                  </NavLink>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <NavLink className={classes.menuItem} to="/vendors">
                    {t("Vendors")}
                  </NavLink>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <IconButton
                    color="inherit"
                    //className={classes.menuButton1}
                    edge="start"
                    size="large">
                    {user ? (
                      <Avatar
                        alt={user.name}
                        src={user.url}
                        className={classes.profile}
                        onClick={handleMenu}
                      />
                    ) : (
                      <NavLink className={classes.menuItem} to="/login">
                        <PersonOutline />
                      </NavLink>
                    )}
                  </IconButton>
                  {!user ? (
                    <NavLink className={classes.menuItem} to="/login">
                      {t("Login / Register")}
                    </NavLink>
                  ) : (
                    <Typography
                      className={classes.menuItem}
                      onClick={handleMenu}
                    >
                      {user.name}{" "}
                    </Typography>
                  )}
                </MenuItem>
                {user && (
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    // transformOrigin={{
                    //   vertical: "top",
                    //   horizontal: "right",
                    // }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose} className="headerright">
                      <NavLink className={classes.menuItem} to="/profile">
                        <AccountBox
                          style={{ marginRight: 0, color: "#45B2CB" }}
                        />
                        {""}
                        {t("Profile")}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink className={classes.menuItem} to="/my-orders">
                        <ListAlt style={{ marginRight: 0, color: "#45B2CB" }} />{" "}
                        {t("My Orders")}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink className={classes.menuItem} to="/my-wishlist">
                        <FavoriteRounded
                          style={{ marginRight: 0, color: "#45B2CB" }}
                        />{" "}
                        {t("Wishlist")}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink className={classes.menuItem} to="/my-address">
                        <EditLocation
                          style={{ marginRight: 0, color: "#45B2CB" }}
                        />{" "}
                        {t("My Addresses")}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                      <NavLink className={classes.menuItem} to="/my-wallet">
                        <AccountBalanceWallet
                          style={{ marginRight: 0, color: "#45B2CB" }}
                        />{" "}
                        {t("My Wallet")}
                      </NavLink>
                    </MenuItem>
                    <MenuItem onClick={Logout}>
                      <NavLink className={classes.menuItem} to="/">
                        <ExitToApp
                          style={{ marginRight: 0, color: "#45B2CB" }}
                        />{" "}
                        {t("Logout")}
                      </NavLink>
                    </MenuItem>
                  </Menu>
                )}
                <MenuItem className={classes.menuItem}>
                  <NavLink className={classes.menuItem} to="/my-wallet">
                    <IconButton
                      className={classes.socialCls}
                      style={{ marginRight: 0, marginTop: 7 }}
                      color="default"
                      edge="start"
                      size="large">
                      <AccountBalanceWallet />
                    </IconButton>
                  </NavLink>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <NavLink className={classes.menuItem} to="/cart">
                    <StyledBadge
                      badgeContent={user && cartCount ? cartCount : null}
                      color="primary"
                    >
                      <ShoppingCart />
                    </StyledBadge>
                  </NavLink>
                </MenuItem>
              </MenuList>
            </Grid>
          </Grid>
        </Hidden>
        {categoryOpen && (
          <Grid
            ref={wrapperRef}
            container
            spacing={2}
            className={classes.megaMenu}
            style={{
              maxWidth: "80vw",
              height: "70vh",
              overflowY: "auto",
              backgroundColor: "white",
              top: "060px",
              position: "absolute",
            }}
          >
            {category &&
              category.map((item) => (
                <>
                  <Grid container item xs={12} sm={4} md={3}>
                    <ul>
                      <NavLink
                        style={{ textDecoration: "none" }}
                        onClick={() => setCategoryOpen(false)}
                        to={`/products/category/${item.slug}`}
                      >
                        <li
                          style={{
                            color: "black",
                            listStyle: "none",
                            marginBottom: 8,
                            fontWeight: "bold",
                            fontSize: 18,
                            textTransform: "uppercase",
                          }}
                        >
                          {item.name}
                        </li>
                      </NavLink>
                      {item.children &&
                        item.children.map((itm) => (
                          <NavLink
                            style={{ textDecoration: "none" }}
                            onClick={() => setCategoryOpen(false)}
                            to={`/products/category/${itm.slug}`}
                          >
                            <li>{itm.name}</li>
                          </NavLink>
                        ))}
                    </ul>
                  </Grid>
                </>
              ))}
          </Grid>
        )}
        <Hidden only={["md", "lg", "xl"]}>
          <Grid container spacing={2}>
            <Grid container item xs={6} className="allcatbtn">
              {/* <img src="/logo.png" className="logoimg" /> */}
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={() => setCategoryOpen(!categoryOpen)}
                size="large">
                <MenuIcon />
                &nbsp; {t("All Category")}
              </IconButton>
            </Grid>
            <Grid container item xs={6} className="togglebutton">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    onClick={toggleDrawer(anchor, true)}
                    className={classes.menuButton}
                    size="large">
                    <MenuIcon />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={classes.drawerPaper}
                  >
                    <List>
                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="Login / Register" />
                        </Link>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="All Categories" />
                        </Link>
                      </ListItem>
                      <Divider />

                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="Arts & Crafts" />
                        </Link>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="Produce" />
                        </Link>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="Vendors" />
                        </Link>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="Innovations" />
                        </Link>
                      </ListItem>
                      <Divider />
                      <ListItem button>
                        <Link color="inherit">
                          <ListItemText primary="Messages" />
                        </Link>
                      </ListItem>
                    </List>
                  </Drawer>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Hidden>
      </Toolbar>
    </div >
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  cartCount: state.cart.cartCount,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getMainCategory,
    getCompleteCartCount,
  })(Menubar)
);
