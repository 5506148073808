import React, { Component, PureComponent } from "react";
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
export default class CropModal extends Component {
  state = {
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1
    },
    croppedImageUrl: null
  };

  handleClickOpen = () => {
    this.setState({ openModal: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = async crop => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "profile-" + new Date().getTime() + ".png"
      );
      
      this.setState({ croppedImageUrl: croppedImageUrl });
      this.props.setCroppedImageUrl(croppedImageUrl);
      
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    // As a blob
    return base64Image;
  }
  render() {
    const { openCropModal, setCropModal, setCroppedImageUrl } = this.props;
    return (
      <div>
        <Dialog
          open={openCropModal}
          onClose={() => setCropModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Crop Image"}</DialogTitle>
          <DialogContent>
            <ReactCrop
              src={this.props.src}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setCropModal(false);
                setCroppedImageUrl(null);
              }}
              color="primary"
            >
              cancel
            </Button>
            <Button
              onClick={() => setCropModal(false)}
              color="primary"
              autoFocus
            >
              done
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}