import { Grid, Container, Button, Card } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCompleteCart,
  removeItemFromCart,
  moveItemToWishList,
  updateProductQuantity,
  createAddress,
  openUpdateAddress,
  getAddresses,
  removeAddress,
  updateAddress,
  updateCartAddress,
} from "actions/cart";
import { withTranslation } from "react-i18next";

import Simmer from "../common/skeleton";
import AddressForm from "./../cart/addressForm";
import AddressList from "./../cart/AddressList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    marginBottom: 60,
  },
  borderBox: {
    border: "1px solid #ccc",
    padding: theme.spacing(2),
    boxShadow: "none",
    marginBottom: 16,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    padding: "6px !important",
  },
  noRecord: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const Cart = (props) => {
  const { t } = props;
  const classes = useStyles();

  const [showAddressUpdate, setShowAddressUpdate] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [showAddressList, setShowAddressList] = useState(false);

  useEffect(() => {
    props.getAddresses();
  }, [props.getAddresses]);

  useEffect(() => {
    setShowAddressUpdate(props.openAddressPopup);
  }, [props.openAddressPopup]);

  useEffect(() => {
    !selectedAddress &&
      props.addressList &&
      props.addressList.length &&
      props.addressList.map((itm) => {
        if (itm.default_address == 1) {
          setSelectedAddress(itm);
        }
      });
  }, [props.addressList]);

  const handleAddressCreate = (data) => {
    props.createAddress(data);
    setShowAddressUpdate(false);
  };

  if (props.addressListLoading) {
    return <Simmer />;
  }
  return (
    <>
      <Container className={classes.root} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <h2>{t("Your Addresses")}</h2>
                    <Card className={classes.borderBox}>
                      <AddressList
                        removeAddress={(id) => {
                          props.removeAddress(id);
                          setSelectedAddress(false);
                        }}
                        selectedAddress={selectedAddress}
                        handleSelection={setSelectedAddress}
                        addressList={props.addressList || []}
                        createAddressLoader={props.createAddressLoader}
                        handleAddressCreate={handleAddressCreate}
                        updateAddress={props.updateAddress}
                        t={t}
                        closeAddressList={setShowAddressList}
                      />
                      {/* {!showAddressList && selectedAddress && (
                        <Card className={classes.borderBox}>
                          <Grid container spacing={3}>
                            <Grid xs={9}>
                              {t("Deliver to")} {selectedAddress.first_name},{" "}
                              {selectedAddress.postcode}
                              <br></br>
                              {selectedAddress.address1},{" "}
                              {selectedAddress.address2}, {selectedAddress.city}
                            </Grid>
                            <Grid xs={3}>
                              <Button onClick={() => setShowAddressList(true)}>
                                {t("Modify")}
                              </Button>
                            </Grid>
                          </Grid>
                        </Card>
                      )} */}
                      {/* {!showAddressUpdate && (
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => setShowAddressUpdate(true)}
                        >
                          {t("Add Address")}
                        </Button>
                      )} */}
                      {/* {showAddressUpdate && (
                        <AddressForm
                          title=""
                          createAddressLoader={props.createAddressLoader}
                          handleAddressCreate={handleAddressCreate}
                          cancelAddress={() => setShowAddressUpdate(false)}
                        />
                      )} */}
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartDetail: state.cart.cartDetail,
  cartDetailLoading: state.cart.cartDetailLoading,
  openAddressPopup: state.cart.openAddressPopup,
  createAddressLoader: state.cart.createAddressLoader,
  addressList: state.cart.addressList,
  addressListLoading: state.cart.addressListLoading,
  loading_order_create: state.cart.loading_order_create,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getCompleteCart,
    removeItemFromCart,
    moveItemToWishList,
    updateProductQuantity,
    createAddress,
    openUpdateAddress,
    getAddresses,
    removeAddress,
    updateAddress,
    updateCartAddress,
  })(Cart)
);
