import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../Notifications/Alert";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";

import {
  register,
  verificationComplete,
  verifyotp,
  verifyPhone,
} from "../../actions/auth";
import { withTranslation } from "react-i18next";

const VerifyOtp = ({ t }) => {
  const location = useLocation();
  let history = useNavigate();
  let dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: null,
    phone: null,
    phone_code: null,
    password: null,
    code: null,
  });

  const [pagefrom, setPageFrom] = useState("signin");

  useEffect(() => {
    console.log("location data for otp:", location.state);
    const { recaptcha_response, ...stateData } = location.state.formData;
    setFormData({ ...formData, ...stateData });
    setPageFrom(location.state.pagefrom);
  }, [location]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (pagefrom == "signin") {
      dispatch(verifyotp(formData)).then((res) => {
        if (res?.status) {
          location.state.redirect
            ? history(location.state.redirect)
            : history("/profile");
        }
      });
    } else if (pagefrom == "forgot-password") {
      dispatch(verifyotp(formData)).then((res) => {
        if (res.status) {
          history("/reset-password");
        }
      });
    } else if (pagefrom == "verification") {
      dispatch(verificationComplete(formData)).then((res) => {
        if (res.status) {
          history("/profile");
        }
      });
    } else if (pagefrom == "change-phone") {
      dispatch(verifyPhone(formData)).then((res) => {
        if (res.status) {
          history("/profile");
        }
      });
    }
  };

  const resendOtp = async () => {
    dispatch(register(formData));
  };
  return (
    <Container maxWidth="sm" component="main">
      <Box component="div" m={8}>
        <Alert />
        <Typography variant="h5" align="center">
          {t("Verify Otp")}
        </Typography>
        <form onSubmit={(e) => onSubmit(e)}>
          <Grid container spacing={3}>
            <Grid container item xs={12} sm={12}>
              <TextField
                id="outlined-basic"
                margin="normal"
                required
                fullWidth
                label={t("Otp")}
                name="code"
                variant="outlined"
                onChange={(e) => onChange(e)}
              />
            </Grid>
            <Grid container item xs={6} sm={6}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={resendOtp}
              >
                {t("Resend Otp")}
              </Button>
            </Grid>
            <Grid container item xs={6} sm={6}>
              <Button type="submit" variant="contained" color="primary">
                {t("Verify")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default withTranslation()(VerifyOtp);
