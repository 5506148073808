import {
  Grid,
  Container,
  Button,
  Card,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getCompleteCart,
  removeItemFromCart,
  moveItemToWishList,
  updateProductQuantity,
  createAddress,
  openUpdateAddress,
  getAddresses,
  removeAddress,
  updateAddress,
  updateCartAddress,
} from "actions/cart";
import { withTranslation } from "react-i18next";

import Simmer from "../common/skeleton";
import AddressList from "../cart/AddressList";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 60,
    marginBottom: 60,
  },
  borderBox: {
    border: "1px solid #ccc",
    padding: theme.spacing(2),
    boxShadow: "none",
    marginBottom: 16,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    padding: "6px !important",
  },
  noRecord: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    "&.MuiButton-containedPrimary:hover": {
      color: "#fff",
    },
  },
}));
const Cart = (props) => {
  const { t, getCompleteCart, getAddresses } = props;
  const classes = useStyles();
  const history = useNavigate();

  const [productList, setProductList] = useState({});
  const [cartDetail, setCart] = useState({});

  const [selectedAddress, setSelectedAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [sameBillingAddress, setSameBillingAddress] = useState(true);

  useEffect(() => {
    getCompleteCart();
  }, [getCompleteCart]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const handleSecectionDelivery = (itm) => {
    setSelectedAddress(itm);
  };

  const handleSecectionBilling = (itm) => {
    setBillingAddress(itm);
  };

  useEffect(() => {
    if (props.cartDetail) {
      setProductList(props.cartDetail?.items);
      setCart(props.cartDetail);
      if (props.cartDetail?.shipping_address) {
        props.addressList &&
          props.addressList.length &&
          props.addressList.map((itm) => {
            if (
              itm.id ==
              props.cartDetail?.shipping_address?.additional?.address_id
            ) {
              setSelectedAddress({
                ...itm,
                additional: {
                  ...itm.additional,
                  address_id:
                    props.cartDetail?.shipping_address?.additional?.address_id,
                },
              });
            }
            if (
              itm.id ==
              props.cartDetail?.billing_address?.additional?.address_id
            ) {
              setBillingAddress({
                ...itm,
                additional: {
                  ...itm.additional,
                  address_id:
                    props.cartDetail?.billing_address?.additional?.address_id,
                },
              });
            }
          });
      } else {
        !selectedAddress &&
          props.addressList &&
          props.addressList.length &&
          props.addressList.map((itm) => {
            if (itm.default_address == 1) {
              setSelectedAddress(itm);
              setBillingAddress(itm);
            }
          });
      }
    }
  }, [props.cartDetail, props.addressList]);

  useEffect(() => {
    if (props.cartDetail) {
      setProductList(props.cartDetail?.items);
      setCart(props.cartDetail);
    }
  }, [props.cartDetail]);

  const handleAddressCreate = (data) => {
    props.createAddress(data);
  };

  const handleOrderClick = () => {
    if (!selectedAddress.id) {
      window.alert("Please select address first");
      return;
    } else {
      props.updateCartAddress(
        selectedAddress,
        sameBillingAddress ? selectedAddress : billingAddress,
        () => {
          history("/checkout", {
            state: {
              billingAddress: sameBillingAddress
                ? selectedAddress
                : billingAddress,
              shippingAddress: selectedAddress,
            },
          });
        }
      );
    }
  };
  if (props.cartDetailLoading || props.addressListLoading) {
    return <Simmer />;
  }
  return (
    <>
      <Container className={classes.root} maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container spacing={0}>
              {window.location.search.indexOf("message") > -1 && (
                <p style={{ color: "red" }}>
                  {window.location.search
                    .split("message=")[1]
                    .replaceAll("%20", " ")}
                </p>
              )}
              <Grid item xs={12} md={12}>
                {productList && productList.length ? (
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Grid container style={{ paddingBottom: 15 }}>
                        <Grid xs="11">
                          <h2>{t("Shipping Address")}</h2>
                        </Grid>
                        <Grid xs="1" style={{}}>
                          <Container style={{ float: "right", fontSize: 19 }}>
                            <Link
                              to="/cart"
                              style={{
                                display: "flex",
                                float: "right",
                                textDecoration: "none",
                              }}
                            >
                              <Button
                                className="back-button"
                                variant="contained"
                                color="primary"
                              >
                                {t("Back")}
                              </Button>
                            </Link>
                          </Container>
                        </Grid>
                      </Grid>

                      <Card className={classes.borderBox}>
                        <AddressList
                          removeAddress={(id) => {
                            props.removeAddress(id);
                            setSelectedAddress(false);
                          }}
                          compareKey="additional"
                          selectedAddress={selectedAddress}
                          handleSelection={handleSecectionDelivery}
                          addressList={props.addressList || []}
                          createAddressLoader={props.createAddressLoader}
                          handleAddressCreate={handleAddressCreate}
                          updateAddress={props.updateAddress}
                          t={t}
                        />
                        {/* {!showAddressList && selectedAddress && (
                          <Card className={classes.borderBox}>
                            <Grid container spacing={3}>
                              <Grid xs={9}>
                                {t("Deliver to")} {selectedAddress.first_name},{" "}
                                {selectedAddress.postcode}
                                <br></br>
                                {selectedAddress.address1},{" "}
                                {selectedAddress.address2}, {selectedAddress.city}
                              </Grid>
                              <Grid xs={3}>
                                <Button onClick={() => setShowAddressList(true)}>
                                  {t("Update Address")}
                                </Button>
                              </Grid>
                            </Grid>
                          </Card>
                        )} */}
                        {/* {!selectedAddress && (
                          <Button variant="outlined" color="primary" onClick={() => setShowAddressUpdate(true)}>
                            {t("Add Address")}
                          </Button>
                        )} */}
                        {/* {showAddressUpdate && (
                          <AddressForm
                            title="Shipping Address"
                            createAddressLoader={props.createAddressLoader}
                            handleAddressCreate={handleAddressCreate}
                            cancelAddress={() => setShowAddressUpdate(false)}
                          />
                        )} */}
                      </Card>

                      {props.addressList.length ? (
                        <FormGroup style={{ padding: 11 }}>
                          <FormControlLabel
                            control={
                              <input
                                type="checkBox"
                                style={{ width: 19, height: 16 }}
                                checked={sameBillingAddress}
                                onChange={(e) =>
                                  setSameBillingAddress(e.target.checked)
                                }
                              />
                            }
                            label={t("Check if billing address is same")}
                          />
                        </FormGroup>
                      ) : (
                        <></>
                      )}
                    </Grid>

                    {!sameBillingAddress && (
                      <Grid item xs={12}>
                        <h2>{t("Billing Address")}</h2>
                        <Card className={classes.borderBox}>
                          <AddressList
                            removeAddress={(id) => {
                              props.removeAddress(id);
                              setBillingAddress(false);
                            }}
                            selectedAddress={billingAddress}
                            handleSelection={handleSecectionBilling}
                            addressList={props.addressList || []}
                            createAddressLoader={props.createAddressLoader}
                            handleAddressCreate={handleAddressCreate}
                            updateAddress={props.updateAddress}
                            t={t}
                            hideAction={true}
                          />
                        </Card>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              {cartDetail &&
              cartDetail.formated_grand_total &&
              cartDetail.items_count ? (
                <Grid xs={12}>
                  <h2>
                    {t("Price Details")} ({cartDetail.items_count})
                  </h2>
                  {props.loading_order_create ? (
                    <Simmer total={1} />
                  ) : (
                    <>
                      <Card className={classes.borderBox}>
                        <Grid xs={12} container spacing={0}>
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Total Price")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.formated_sub_total}
                            </p>
                          </Grid>
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Shipping Charges")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.selected_shipping_rate
                                ?.formated_price || "0.00"}
                            </p>
                          </Grid>
                          {cartDetail.discount > 0 ? (
                            <Grid xs={8} md={8}>
                              <p style={{ color: "grey" }}>
                                {t("Total Discount ")}:
                              </p>
                            </Grid>
                          ) : (
                            " "
                          )}
                          {cartDetail.discount > 0 ? (
                            <Grid xs={4} md={4}>
                              <p style={{ fontWeight: "600" }}>
                                {"-"}
                                {cartDetail.formated_discount}
                              </p>
                            </Grid>
                          ) : (
                            " "
                          )}
                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Deduct From Wallet")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {"-"}
                              {cartDetail.formated_wallet_detected}
                            </p>
                          </Grid>

                          <Grid xs={8} md={8}>
                            <p style={{ color: "grey" }}>
                              {t("Grand Total")}:{" "}
                            </p>
                          </Grid>
                          <Grid xs={4} md={4}>
                            <p style={{ fontWeight: "600" }}>
                              {cartDetail.formated_payable_amount}
                            </p>
                          </Grid>
                        </Grid>
                      </Card>
                      <Grid xs={12} container spacing={0}>
                        <Button
                          className={classes.checkoutButton}
                          variant="contained"
                          color="primary"
                          onClick={handleOrderClick}
                        >
                          {t("Proceed to checkout")}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartDetail: state.cart.cartDetail,
  cartDetailLoading: state.cart.cartDetailLoading,
  openAddressPopup: state.cart.openAddressPopup,
  createAddressLoader: state.cart.createAddressLoader,
  addressList: state.cart.addressList,
  addressListLoading: state.cart.addressListLoading,
  loading_order_create: state.cart.loading_order_create,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getCompleteCart,
    removeItemFromCart,
    moveItemToWishList,
    updateProductQuantity,
    createAddress,
    openUpdateAddress,
    getAddresses,
    removeAddress,
    updateAddress,
    updateCartAddress,
  })(Cart)
);
