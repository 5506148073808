

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { loaduser } from "./actions/auth";
import { getThemeByName, getConfigData } from "../src/actions/common";
import WebRoute from "./routes";
import ReactPixel from "react-facebook-pixel";

const App = () => {
  let dispatch = useDispatch();
  const token = localStorage.getItem("token");

  const themenew = getThemeByName(
    localStorage.getItem("country")
      ? JSON.parse(localStorage.getItem("country")).code
      : ""
  );
  const options = {
    autoConfig: true,
    debug: false, // enable logs
  };
  const advancedMatching = { em: "taunsaini001@gmail.com" }; 

  useEffect(() => {
    if (token) dispatch(loaduser());
  }, [token]);
  useEffect(() => {
    dispatch(getConfigData());
    ReactPixel.init("561928405705388", advancedMatching, options);

  }, []);
  return (
    <div>
      <Router>
        <WebRoute themenew={themenew} />
      </Router>
    </div>
  );
};

export default App;
