import React from "react";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import FacebookIcon from '@mui/icons-material/Facebook';
import makeStyles from '@mui/styles/makeStyles';
import { withTranslation } from 'react-i18next';

import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { GoogleIcon, REACT_FACEBOOK_APP_ID, REACT_GOOGLE_APP_ID } from "helper";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& Button': {
      marginBottom: theme.spacing(3),
      boxShadow: 2
    },
    '& div': {
      width: '100%'
    }
  },
  facebook: {
    width: '100%',
    height: theme.spacing(6) + 2,
    color: "#fff",
    borderColor: "#3b5999 !important",
    backgroundColor: "#3b5999 !important",
    "&:hover": {
      backgroundColor: "#3b5999 !important",
    },
    '& span': {
      width: '100%'
    }
  },
  google: {
    width: '100%',
    height: theme.spacing(6) + 2,
    color: "#000",
    borderColor: "#ffffff !important",
    backgroundColor: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#ffffff !important",
    },
  },
  icons: {
    marginLeft: theme.spacing(2),
  },
}));
const SocialButton = ({ handleSocialLogin, t }) => {
  const classes = useStyles();

  return (
    <Box p={2} alignItems="center" justifyContent="center" className={classes.root}>
      <SocialLogin
        provider="facebook"
        appId={REACT_FACEBOOK_APP_ID}
        callback={handleSocialLogin}
      >
        <Button variant="contained" className={classes.facebook}>
          <FacebookIcon />
          <Typography className={classes.icons} >
            {t("Login with Facebook")}
          </Typography>
        </Button>
      </SocialLogin>
      <SocialLogin
        provider="google"
        appId={REACT_GOOGLE_APP_ID}
        callback={handleSocialLogin}
      >
        <Button variant="contained" className={classes.google}>
          <GoogleIcon />
          <Typography className={classes.icons} style={{ color: "black" }} >
            {t("Login with Google")}
          </Typography>
        </Button>
      </SocialLogin>
    </Box>
  );
};

export default withTranslation()(SocialButton);
