import axios from "axios";
import {
  BASE_URL,
  authHeader,
  params,
  handleCatchException,
  hideloader,
  showloader,
  errormsg,
  successmsg,
} from "../apihelper";

import { convertObjectIntoQueryString } from "utils/global";

export const getVendorsData =
  (country_id, pagination = {}, sorting) =>
  async (dispatch) => {
    try {
      showloader();
      let sortObj = {};
      if (sorting) {
        switch (sorting) {
          case "lowtohigh":
            sortObj = {
              sort: "seller_rating",
              order: "asc",
            };
            break;
          case "hightolow":
            sortObj = {
              sort: "seller_rating",
              order: "desc",
            };
            break;
          case "newest":
            sortObj = {
              sort: "created_at",
              order: "desc",
            };
            break;
          case "oldest":
            sortObj = {
              sort: "created_at",
              order: "asc",
            };
            break;
        }
      }
      const response = await axios.get(
        `${BASE_URL}/marketplace?${convertObjectIntoQueryString(
          pagination
        )}&${convertObjectIntoQueryString(sortObj)}`,
        authHeader()
      );
      hideloader();
      if (response.data) {
        // dispatch({ type: "SELECTED_COUNTRY", payload: false });
        //   successmsg(response.data.message);
        return response.data;
      } else {
        errormsg(response.data.message);
        return false;
      }
    } catch (error) {
      return handleCatchException(error);
    }
  };

export const getVendorsBySlug = (slug) => async (dispatch) => {
  try {
    showloader();
    let queryParams = "";
    let country =
      localStorage.getItem("country") &&
      JSON.parse(localStorage.getItem("country"));
    if (country) {
      queryParams += `&country_id=${country.id}`;
    }
    const response = await axios.get(
      `${BASE_URL}/marketplace/seller/profile/${slug}?${queryParams}`,
      authHeader()
    );
    hideloader();
    if (response.data) {
      dispatch({ type: "VENDOR_DETAIL", payload: response?.data?.data });
    } else {
      dispatch({ type: "VENDOR_DETAIL_ERROR", payload: true });
      errormsg(response.data.message);
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      window.location.href = "/";
    }
    return handleCatchException(error);
  }
};

export const resetComponent = () => (dispatch) => {
  dispatch({ type: "VENDOR_DETAIL", payload: false });
};

export const getReviews = (slug, pagination) => async (dispatch) => {
  try {
    dispatch({
      type: "REVIEW_LIST_LOADING",
      payload: true,
    });

    const response = await axios.get(
      `${BASE_URL}/marketplace/seller/${slug}/reviews?${convertObjectIntoQueryString(
        pagination
      )}`,
      authHeader()
    );
    if (response.data) {
      dispatch({
        type: "REVIEW_LIST",
        payload: { data: response.data.data, page: pagination.page },
      });
      dispatch({
        type: "REVIEW_LIST_METADATA",
        payload: response.data.meta,
      });

      dispatch({
        type: "REVIEW_LIST_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "REVIEW_LIST_LOADING",
        payload: false,
      });
      errormsg(response.data.message);
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const getVendorsListData =
  (country_id, pagination = {}) =>
  async (dispatch) => {
    try {
      showloader();
      const response = await axios.get(
        `${BASE_URL}/marketplace?${convertObjectIntoQueryString(pagination)}`,
        { params: { country_id: country_id } },
        authHeader()
      );
      hideloader();
      if (response.data) {
        // dispatch({ type: "SELECTED_COUNTRY", payload: false });
        //   successmsg(response.data.message);
        return response.data;
      } else {
        errormsg(response.data.message);
        return false;
      }
    } catch (error) {
      return handleCatchException(error);
    }
  };

export const getVendorsProductBySlug = (slug) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.get(
      `${BASE_URL}/marketplace/seller/${slug}/products`,
      authHeader()
    );
    hideloader();
    if (response.data) {
      dispatch({ type: "VENDOR_PRODUCT", payload: response?.data?.data });
    } else {
      dispatch({ type: "VENDOR_DETAIL_ERROR", payload: true });
      errormsg(response.data.message);
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getPreferredVendor = () => async (dispatch) => {
  try {
    showloader();
    const response = await axios.get(
      `${BASE_URL}/my-preferred-vendor`,
      authHeader()
    );
    hideloader();
    if (response.data) {
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const followVendor = (id) => async (dispatch) => {
  try {
    const formData = {
      seller_id: id,
    };
    const response = await axios.post(
      `${BASE_URL}/follow-seller`,
      formData,
      authHeader()
    );
    if (response.data.status) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const createReview = (data, url) => async (dispatch) => {
  try {
    dispatch({
      type: "SUBMIT_REVIEW_LOADING",
      payload: true,
    });
    const response = await axios.post(
      `${BASE_URL}/marketplace/seller/${url}/reviews/create`,
      data,
      authHeader()
    );
    if (response.data.status) {
      successmsg(response.data.message, "REVIEW_SUBMIT");
      dispatch({
        type: "SUBMIT_REVIEW_LOADING",
        payload: false,
      });
      dispatch({
        type: "SUBMIT_REVIEW_SUCCESS",
        payload: true,
      });
      return response.data;
    } else {
      dispatch({
        type: "SUBMIT_REVIEW_LOADING",
        payload: false,
      });
      errormsg(response.data.message, "REVIEW_SUBMIT");
      return false;
    }
  } catch (error) {
    dispatch({
      type: "SUBMIT_REVIEW_LOADING",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const reportVendor = (formData) => async (dispatch) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/report-vendor`,
      formData,
      authHeader()
    );
    if (response.data.status) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const similarVendor =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/marketplace/similar-vendor?${convertObjectIntoQueryString(
          filters
        )}`,
        authHeader()
      );
      if (response.data) {
        return response.data.data;
      } else {
        errormsg("Unable to fetch data");
      }
    } catch (error) {
      return handleCatchException(error);
    }
  };

export const popularVendor =
  (filters = {}) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/marketplace/popular-seller?${convertObjectIntoQueryString(
          filters
        )}`,
        authHeader()
      );
      if (response.data) {
        return { data: response.data, meta: response.meta };
      } else {
        errormsg("Unable to fetch data");
      }
    } catch (error) {
      return handleCatchException(error);
    }
  };
