import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Grid, Typography, Container, Link } from "@mui/material";
import { APP_ENV } from "utils/config";
import ReactPixel from "react-facebook-pixel";



const ThankYou = (props) => {
  const location = useLocation();
  const [orderID, setOrderId] = useState(0);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search); // gets the URL parameters
    setOrderId(urlParams.has('orderID') ? urlParams.get('orderID') : location?.state?.orderID); // gets the value of the "orderID" parameter
    setAmount(urlParams.has('amount') ? urlParams.get('amount') : location?.state?.amount); // gets the value of the "amount" parameter
    if (APP_ENV == "prod") {
      ReactPixel.fbq("track", "InitiateCheckout", {
        value: amount, // required property
        currency: "USD", // required property, if not using 'contents'
      });
    }
  }, []);
  return (
    <Container>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item md={12}>
          <Box
            p={8}
            style={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              minHeight: 400,
            }}
            alignItems="center"
          >
            <div>
              <Typography variant="h2">Order Placed Successfully!</Typography>
            </div>
            <div style={{ marginTop: 20 }}>
              <CheckCircleIcon style={{ fontSize: 64, color: "#8bc34a" }} />
            </div>
            <Box p={4} style={{ fontSize: "1.6rem" }}>
              Order ID:{" "}
              <Link href={`/my-orders/${orderID}`} underline="hover">
                <b>#{orderID}</b> {amount}
              </Link>
            </Box>
            <Box style={{ marginTop: "auto" }}>
              <Link href="/">Back To Home</Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThankYou;
