import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getPreferredVendor,followVendor } from "actions/vendor";
import Container from "@mui/material/Container";
import makeStyles from '@mui/styles/makeStyles';
import Grid from "@mui/material/Grid";
import VendorCard from "./vendorCard";
import { withTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import Alert from "../Notifications/Alert";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 0,
    padding: 0,
    backgroundColor: "#f2f6f4",
  },
  productContainer: {
    width: "25%",
    [theme.breakpoints.down('lg')]: {
      width: "33.33%",
    },
    [theme.breakpoints.down('md')]: {
      width: "50%",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
  },
  formControl: {
    margin: theme.spacing(2) - 1,
  },
}));

const PreferredVendor = ({ getPreferredVendor,followVendor, countryselected, t }) => {
  const classes = useStyles();
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    getPreferredVendor().then((res) => {
      setVendors(res.data);
    });
  }, []);
  const follow = (id) => {
    followVendor(id).then((res) => {
      if(res.status){
        setVendors(vendors.filter(item => item.id !== id));
      }
    })
  }
  return (
    <Container className={classes.root} maxWidth={false} disableGutters={true}>
      <Grid container>
        <Grid container item xs={12} md={12}>
        <Grid item xs="12" sm="12">
             
                <Typography variant="h3" className={classes.formControl}>
                  {t("My Preferred Vendors")}
                </Typography>
                <Alert />
              </Grid>
          <Grid container direction="row">
            {vendors.length ? (
              vendors.map((itm) => (
                <>
                  <Grid className={classes.productContainer}>
                    <VendorCard item={itm}  remove= {true} follow={follow} />
                  </Grid>
                </>
              ))
            ) : (
              <Grid
                style={{ marginTop: "8%", marginLeft: "40%" }}
                container
                direction="row"
              >
                {t("No Vendor Available")}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  countryselected: state.countries.selected_country,
});
export default withTranslation()(
  connect(mapStateToProps, {
    getPreferredVendor,followVendor,
  })(PreferredVendor)
);
