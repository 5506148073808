import axios from "axios";
import {
  BASE_URL,
  authHeader,
  handleCatchException,
  showloader,
  hideloader,
  errormsg,
  successmsg,
} from "../apihelper";
import { convertObjectIntoQueryString } from "utils/global";
import { getCompleteCartCount } from "./cart";

export const getSuperMarketProducts =
  (pagination = {}, filters = {}, sorting = {}, queryParams) =>
    async (dispatch) => {
      let country =
        localStorage.getItem("country") &&
        JSON.parse(localStorage.getItem("country"));
      if (country) {
        pagination.country_id = country.id;
      }
      const urlParam = new URLSearchParams(queryParams);
      const entrie = urlParam.entries(); //returns an iterator of decoded [key,value] tuples
      let param = paramsToObject(entrie);

      pagination.limit = 12;
      try {
        // showloader();
        let sortObj = {};
        if (sorting) {
          switch (sorting) {
            case "lowtohigh":
              sortObj = {
                sort: "price",
                order: "asc",
              };
              break;
            case "hightolow":
              sortObj = {
                sort: "price",
                order: "desc",
              };
              break;
            case "featured":
              sortObj = {
                sort: "featured",
              };
              break;
            case "newest":
              sortObj = {
                sort: "created_at",
                order: "desc",
              };
              break;
            default:
              sortObj = {
                sort: "created_at",
                order: "desc",
              };
              break;
          }
        }
        dispatch({
          type: "PRODUCT_LIST_LOADING",
          payload: true,
        });
        const combinedObject = {
          ...param,
          ...pagination,
          ...filters,
          ...sortObj,
        };
        const newObj = {};
        // remove empty params
        Object.keys(combinedObject).map((itm) => {
          if (
            !(
              typeof combinedObject[itm] == "undefined" ||
              combinedObject[itm] === "" ||
              combinedObject[itm] == null
            )
          ) {
            newObj[itm] = combinedObject[itm];
          }
        });
        
        const response = await axios.get(
          `${BASE_URL}/supermarket/products?${convertObjectIntoQueryString(newObj)}`,
          authHeader()
        );
        // hideloader();
        if (response.data) {
          dispatch({
            type: "PRODUCT_LIST",
            payload: response.data.data,
          });
          dispatch({
            type: "PRODUCT_LIST_METADATA",
            payload: response.data.meta,
          });
          dispatch({
            type: "PRODUCT_LIST_LOADING",
            payload: false,
          });
          return response.data.data;
        } else {
          dispatch({
            type: "PRODUCT_LIST_LOADING",
            payload: false,
          });
          errormsg("Unable to fetch data");
        }
      } catch (error) {
        return handleCatchException(error);
      }
    };

export const addToCart = (product) => async (dispatch) => {
  try {
    dispatch({
      type: "CART_PRODUCT_ADD",
      payload: true,
    });
    const response = await axios.post(
      `${BASE_URL}/checkout/cart/add/${product.product_id}`,
      product,
      authHeader()
    );
    if (response?.data?.data) {
      dispatch({
        type: "CART_DETAIL",
        payload: response.data.data,
      });
      dispatch(getCompleteCartCount());
      successmsg(response.data.message);
    } else {
      dispatch({
        type: "CART_DETAIL_ERROR",
        payload: null,
      });
      errormsg(response.data?.error?.message);
    }
    dispatch({
      type: "CART_PRODUCT_ADD",
      payload: false,
    });
  } catch (error) {
    dispatch({
      type: "CART_PRODUCT_ADD",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const getProductFilter =
  (queryParams = "?") =>
    async (dispatch) => {
      try {
        if (!queryParams.includes("category_slug")) {
          return false;
        }
        let country =
          localStorage.getItem("country") &&
          JSON.parse(localStorage.getItem("country"));
        if (country) {
          queryParams += `&country_id=${country.id}`;
        }

        dispatch({
          type: "PRODUCT_FILTER_LOADING",
          payload: true,
        });

        const response = await axios.get(
          `${BASE_URL}/category-attributes${queryParams}`,
          authHeader()
        );
        if (response?.data?.status) {
          dispatch({
            type: "PRODUCT_FILTER_LIST",
            payload: response.data.data,
          });
        } else {
          errormsg("Unable to fetch data");
        }
        dispatch({
          type: "PRODUCT_FILTER_LOADING",
          payload: false,
        });
      } catch (error) {
        dispatch({
          type: "PRODUCT_FILTER_LIST",
          payload: [],
        });
        dispatch({
          type: "PRODUCT_FILTER_LOADING",
          payload: false,
        });
        return handleCatchException(error);
      }
    };
export const getSearchProducts = async (textString) => {
  try {
    let country =
      localStorage.getItem("country") &&
      JSON.parse(localStorage.getItem("country"));

    const response = await axios.get(
      `${BASE_URL}/products?search=${textString}${country && country.id ? "&country_id=" + country.id : ""
      }`,
      authHeader()
    );
    return response.data.data;
  } catch (error) {
    return handleCatchException(error);
  }
};

export const fetchYoutubeVideoDetail = async (src) => {
  try {
    const response = await axios.get(
      `https://www.youtube.com/oembed?url=${src}&format=json`
    );
    return response;
  } catch (error) { }
};
export const getProductById = (url_key) => async (dispatch) => {
  try {
    showloader();
    let country =
      localStorage.getItem("country") &&
      JSON.parse(localStorage.getItem("country"));
    const response = await axios.get(
      `${BASE_URL}/products/${url_key}?${country?.id ? "country_id=" + country.id : ""
      }`,
      authHeader()
    );
    if (response?.data?.data) {
      dispatch({
        type: "PRODUCT_DETAIL",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "PRODUCT_DETAIL_ERROR",
        payload: null,
      });
    }
    hideloader();
  } catch (error) {
    if (error?.response?.status === 404) {
      window.location.href = "/";
    }
    return handleCatchException(error);
  }
};

export const getConfigurableProductById = (id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/product-configurable-config/${id}`,
      authHeader()
    );
    if (response?.data?.data) {
      dispatch({
        type: "PRODUCT_CONFIG_DETAIL",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "PRODUCT_CONFIG_DETAIL_ERROR",
        payload: null,
      });
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

export const getProducts =
  (pagination = {}, filters = {}, sorting = {}, queryParams) =>
    async (dispatch) => {
      let country =
        localStorage.getItem("country") &&
        JSON.parse(localStorage.getItem("country"));
      if (country) {
        pagination.country_id = country.id;
      }
      const urlParam = new URLSearchParams(queryParams);
      const entrie = urlParam.entries(); //returns an iterator of decoded [key,value] tuples
      let param = paramsToObject(entrie);

      pagination.limit = 12;
      try {
        // showloader();
        let sortObj = {};
        if (sorting) {
          switch (sorting) {
            case "lowtohigh":
              sortObj = {
                sort: "price",
                order: "asc",
              };
              break;
            case "hightolow":
              sortObj = {
                sort: "price",
                order: "desc",
              };
              break;
            case "featured":
              sortObj = {
                sort: "featured",
              };
              break;
            case "newest":
              sortObj = {
                sort: "created_at",
                order: "desc",
              };
              break;
            default:
              sortObj = {
                sort: "created_at",
                order: "desc",
              };
              break;
          }
        }
        dispatch({
          type: "PRODUCT_LIST_LOADING",
          payload: true,
        });
        const combinedObject = {
          ...param,
          ...pagination,
          ...filters,
          ...sortObj,
        };
        const newObj = {};
        // remove empty params
        Object.keys(combinedObject).map((itm) => {
          if (
            !(
              typeof combinedObject[itm] == "undefined" ||
              combinedObject[itm] === "" ||
              combinedObject[itm] == null
            )
          ) {
            newObj[itm] = combinedObject[itm];
          }
        });

        const response = await axios.get(
          `${BASE_URL}/products?${convertObjectIntoQueryString(newObj)}`,
          authHeader()
        );
        // hideloader();
        if (response.data) {
          dispatch({
            type: "PRODUCT_LIST",
            payload: response.data.data,
          });
          dispatch({
            type: "PRODUCT_LIST_METADATA",
            payload: response.data.meta,
          });
          dispatch({
            type: "PRODUCT_LIST_LOADING",
            payload: false,
          });
          return response.data.data;
        } else {
          dispatch({
            type: "PRODUCT_LIST_LOADING",
            payload: false,
          });
          errormsg("Unable to fetch data");
        }
      } catch (error) {
        return handleCatchException(error);
      }
    };

export const getTopSellingProducts =
  (pagination = {}, filters = {}, sorting, queryParams) =>
    async (dispatch) => {
      let country =
        localStorage.getItem("country") &&
        JSON.parse(localStorage.getItem("country"));
      if (country) {
        pagination.country_id = country.id;
      }
      try {
        // showloader();
        dispatch({
          type: "PRODUCT_LIST_LOADING",
          payload: true,
        });
        const response = await axios.get(
          `${BASE_URL}/get-top-selling-products${queryParams ? queryParams + "&" : "?"
          }${convertObjectIntoQueryString(
            pagination
          )}&${convertObjectIntoQueryString(filters)}`,
          authHeader()
        );
        // hideloader();
        if (response.data) {
          dispatch({
            type: "TOP_SELLING_PRODUCT_LIST",
            payload: response.data.data,
          });
          dispatch({
            type: "PRODUCT_LIST_LOADING",
            payload: false,
          });
          return { data: response.data, meta: response.meta };
        } else {
          dispatch({
            type: "PRODUCT_LIST_LOADING",
            payload: false,
          });
          errormsg("Unable to fetch data");
        }
      } catch (error) {
        return handleCatchException(error);
      }
    };

export const getFeaturedProductsHome =
  (pagination = {}, filters = {}, queryParams) =>
    async (dispatch) => {
      let country =
        localStorage.getItem("country") &&
        JSON.parse(localStorage.getItem("country"));
      if (country) {
        pagination.country_id = country.id;
      }

      try {
        const response = await axios.get(
          `${BASE_URL}/products${queryParams ? queryParams + "&" : "?"
          }${convertObjectIntoQueryString(
            pagination
          )}&${convertObjectIntoQueryString(filters)}`,
          authHeader()
        );
        if (response.data) {
          return { data: response.data, meta: response.meta };
        }
      } catch (error) {
        return handleCatchException(error);
      }
    };

export const getWishlist = () => async (dispatch) => {
  try {
    showloader();
    const response = await axios.get(`${BASE_URL}/wishlist`, authHeader());
    hideloader();
    if (response.data) {
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

//moveToCart
export const moveToCart = (id) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.get(
      `${BASE_URL}/move-to-cart/${id}`,
      authHeader()
    );
    hideloader();
    if (response.data) {
      successmsg(response.data.message);
      dispatch(getCompleteCartCount());
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};
export const removeWhishlist = (id) => async (dispatch) => {
  try {
    showloader();
    const response = await axios.delete(
      `${BASE_URL}/wishlist/${id}`,
      authHeader()
    );
    hideloader();
    if (response.data) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getVendorsFlashDeal =
  (filters = {}) =>
    async (dispatch) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/products?${convertObjectIntoQueryString(filters)}`,
          authHeader()
        );
        // hideloader();
        if (response.data) {
          return response.data.data;
        } else {
          errormsg("Unable to fetch data");
        }
      } catch (error) {
        return handleCatchException(error);
      }
    };

export const getSimilarProductsOtherCountry =
  (filters = {}) =>
    async (dispatch) => {
      try {
        const response = await axios.get(
          `${BASE_URL}/get-similiar-product-other-country?${convertObjectIntoQueryString(
            filters
          )}`
        );
        if (response.data) {
          return response.data.data;
        } else {
          errormsg("Unable to fetch data");
        }
      } catch (error) {
        return handleCatchException(error);
      }
    };

//wishlist/add/{id}
export const wishlistProduct = (formData) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/wishlist/add/${formData.product_id
      }?${convertObjectIntoQueryString(formData)}`,
      authHeader()
    );
    if (response.data) {
      successmsg(response.data.message);
      return response.data;
    } else {
      errormsg(response.data.message);
      return false;
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const getReviews = (slug, pagination) => async (dispatch) => {
  try {
    dispatch({
      type: "PRODUCT_REVIEW_LIST_LOADING",
      payload: true,
    });

    const response = await axios.get(
      `${BASE_URL}/reviews?product_id=${slug}&${convertObjectIntoQueryString(
        pagination
      )}`,
      authHeader()
    );
    if (response.data) {
      dispatch({
        type: "PRODUCT_REVIEW_LIST",
        payload: { data: response.data.data, page: pagination.page },
      });
      dispatch({
        type: "PRODUCT_REVIEW_LIST_METADATA",
        payload: response.data.meta,
      });

      dispatch({
        type: "PRODUCT_REVIEW_LIST_LOADING",
        payload: false,
      });
    } else {
      dispatch({
        type: "PRODUCT_REVIEW_LIST_LOADING",
        payload: false,
      });
      errormsg(response.data.message);
    }
  } catch (error) {
    return handleCatchException(error);
  }
};

export const createReview = (data, id) => async (dispatch) => {
  try {
    dispatch({
      type: "SUBMIT_PRODUCT_REVIEW_LOADING",
      payload: true,
    });
    const response = await axios.post(
      `${BASE_URL}/reviews/${id}/create`,
      data,
      authHeader()
    );
    if (response.data?.data?.id) {
      successmsg(response.data.message, "REVIEW_SUBMIT");
      dispatch({
        type: "SUBMIT_PRODUCT_REVIEW_LOADING",
        payload: false,
      });
      dispatch({
        type: "SUBMIT_PRODUCT_REVIEW_SUCCESS",
        payload: true,
      });
      return response.data;
    } else {
      dispatch({
        type: "SUBMIT_PRODUCT_REVIEW_LOADING",
        payload: false,
      });
      errormsg(response.data.message, "REVIEW_SUBMIT");
      return false;
    }
  } catch (error) {
    dispatch({
      type: "SUBMIT_PRODUCT_REVIEW_LOADING",
      payload: false,
    });
    return handleCatchException(error);
  }
};

export const resetData = () => async (dispatch) => {
  dispatch({
    type: "RESET_PRODUCT_DETAIL",
  });
};
