import auth from "./auth";
import alert from "./alert";
import loading from "./loading";
import countries from "./countries";
import product from "./product";
import banner from "./banner";
import cms from "./cms";
import vendor from "./vendor";
import cart from "./cart";
import innovation from "./innovation";
import { combineReducers } from "redux";
import notification from "./notification";
import order from "./order";
import wallet from "./wallet";
import config from "./config";
import supermarket from "./supermarket";

export default combineReducers({
  auth,
  alert,
  wallet,
  loading,
  countries,
  product,
  banner,
  cms,
  vendor,
  cart,
  notification,
  innovation,
  order,
  config,
  supermarket,
});
