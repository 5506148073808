const initialState = {
  detail: false,
  Loading: false,
  listError: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case "CMS_DETAIL":
      return {
        ...state,
        detail: payload,
      };
    case "CMS_LIST_ERROR":
      return {
        ...state,
        detail: payload,
        listError: true,
      };
    case "CMS_LIST_LOADING":
      return {
        ...state,
        Loading: payload,
      };
    default:
      return state;
  }
}
