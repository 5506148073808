import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import GoogleIcon from 'components/assets/images/google.png'
import AppleIcon from 'components/assets/images/apple.png'
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) =>
    createStyles({
       root:{
           background:'#F3F5F4',
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
           [theme.breakpoints.down('lg')]: {
            flexDirection:'column'
          },
       },
       downloadInfo:{
        marginRight:'25px',
        [theme.breakpoints.down('lg')]: {
            marginRight:0
          },
       },
       downloadFrom:{
           '& img':{
            width: '220px',
            margin: '8px',
           },
           [theme.breakpoints.down('lg')]: {
            textAlign:'center',
            margin:'8px auto'
        },
       },
    })
);

const DownloadApp = ({ t }) => {
    const classes = useStyles();
    return (
        <Box className="bg-white">
        <Box className={classes.root} width={1} p={4} alignItems="center" >
            <Box className={classes.downloadInfo} >
                <Typography variant="h1" component="h1">
                    {t('Download')}
                </Typography>
                <Typography variant="h3" component="p">
                    {t('The Application')}
                </Typography>
            </Box>
            <Box className={classes.downloadFrom}>
                <a href="https://apps.apple.com/us/app/sooqiraq-%D8%B3%D9%88%D9%82-%D8%A7%D9%84%D8%B9%D8%B1%D8%A7%D9%82/id1576186908?l=ar"><img src={AppleIcon} className={classes.tabIcon} alt="Apple" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.sooqiraq.customer"><img src={GoogleIcon} className={classes.tabIcon} alt="Google" /></a>
            </Box>
        </Box>
        </Box>

    );
};

export default DownloadApp;
