import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";

const GuestRoute = () => {
  const location = useLocation();
  const token = localStorage.getItem("token");

  return !token ? (
    <Outlet />
  ) : (
    <Navigate replace to={{ pathname: "/", state: { from: location } }} />
  );
};

export default GuestRoute;
