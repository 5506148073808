const initialState = {
    vendorDetail: false,
    vendorProduct: [],
    reviewList: [],
    reviewListMeta : {page: 1, limit : 6},
    reviewSubmit: false
  };
  
  export default function (state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case "VENDOR_DETAIL":
        return {
          ...state,
          vendorDetail: payload
        };
      case "VENDOR_PRODUCT":
        return {
          ...state,
          vendorProduct: payload
        };
        case "VENDOR_DETAIL_ERROR":
          return {
            ...state,
            vendorDetail: payload
          };
        case "SUBMIT_REVIEW_SUCCESS":
          return {
            ...state,
            reviewSubmit: payload
          }
        case "SUBMIT_REVIEW_LOADING":
          return {
            ...state,
            submitReviewLoading: payload
          };
        case "REVIEW_LIST": 
          if(payload.page === 1){
            return {
              ...state,
              reviewList: payload.data
            }  
          }
          let arr = state.reviewList;
          arr.push(...payload.data)
          
          return {
            ...state,
            reviewList: arr
          }

        case "REVIEW_LIST_METADATA": 
          return{
            ...state,
            reviewListMeta: payload
          }
        case "REVIEW_LIST_LOADING":
          return{
            ...state,
            reviewListLoading: payload
          }
      default:
        return state;
    }
  }
  