import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import { Box, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { Delete, Visibility, ThumbUp } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useTranslation, withTranslation } from "react-i18next";
import Image from "components/common/Image";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 8,
    boxShadow: "none",
    border: "1px solid #efefef",
  },
  media: {
    width: "100%",
    height: 280,
    position: "relative",
  },
  media1: {
    width: "100%",
    height: 150,
    position: "relative",
  },
  bottomBorder: {
    width: "50px",
    height: "5px",
    background: theme.palette.primary.main,
    margin: "16px 0",
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  cardInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  miniQty: {
    background: theme.palette.primary.main,
    color: "#fff",
    position: "absolute",
    // bottom: "16px",
    // left: 0,
    right: 5,
    padding: "4px 8px",
    opacity: 0.8,
    top: 5,
    borderRadius: 55,
  },
  price: {
    paddingRight: 10,
    fontWeight: 600,
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  discounted_price: {
    textDecoration: "line-through",
    textDecorationColor: theme.palette.primary.main,
    marginLeft: 10,
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "100%",
  },
  image: {
    objectFit: "fill",
    borderRadius: 5,
  },
  card: {
    padding: "10px",
  },
  cardContent: {
    marginBottom: 8,
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
  rating: {
    color: "orange",
  },
}));

const ProductCard = ({
  item,
  mediacss,
  wishlist,
  movetocart,
  removeFromWishlist,
  t = () => {},
}) => {
  const history = useNavigate();
  const classes = useStyles();
  return (
    <>
      <Card className={classes.root}>
        <CardActionArea
          className="rightsidebarproduct"
          onClick={() => {
            history(`/products/${item.url_key}`);
          }}
        >
          <Link to={`/products/${item.url_key}`} className={classes.link}>
            <Image
              src={item?.base_image?.original_image_url}
              className={mediacss ? classes.media1 : classes.media}
              title={item.name}
            />
            {
              <Box className="radiousbox radioboxproduct">
                {item.featured ? (
                  <Link to="" className="Deliveryttype">
                    {t("Featured")}
                  </Link>
                ) : (
                  " "
                )}
                {/* <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                  className={classes.miniQty}
                >
                  <Typography component="span">
                    <Visibility fontSize="medium" style={{ paddingTop: 8 }} />{" "}
                  </Typography>
                  <Typography
                    className="uptop"
                    component="span"
                    style={{ fontSize: 14 }}
                  >
                    {item.view_count}
                  </Typography>
                </Typography> */}
              </Box>
            }
          </Link>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h5"
              className={classes.title}
            >
              <Link to="#" className={classes.link}>
                {item.name?.length > 25
                  ? item.name.substring(0, 25) + `${" ..."}`
                  : item.name}
              </Link>
            </Typography>

            <Box
              width={1}
              justifyContent="space-between"
              alignItems="center"
              display="flex"
            >
              <Box
                justifyContent="flex-start"
                alignItems="center"
                display="flex"
              >
                <Typography gutterBottom variant="h5" component="h5">
                  {item.formated_special_price || item.formated_price}
                  {item.formated_regular_price && (
                    <Typography
                      gutterBottom
                      variant="h5"
                      className={classes.discounted_price}
                      component="h5"
                    >
                      {item.formated_regular_price}
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Box
                justifyContent="flex-start"
                alignItems="center"
                display="flex"
              >
                <Tooltip
                  placement="top-start"
                  title={
                    item.reviews.average_rating == 0
                      ? "No rating"
                      : item.reviews.average_rating + " ratings out of 5"
                  }
                >
                  <span>
                    <Rating
                      name="read-only"
                      value={item.reviews.average_rating || 0}
                      readOnly
                      precision={0.5}
                      name="half-rating-read"
                      size="small"
                      title="test"
                      className="reviewrating"
                    />
                  </span>
                </Tooltip>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
        {wishlist && (
          <CardActions>
            <Button
              startIcon={<Delete />}
              size="small"
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => removeFromWishlist(wishlist)}
            >
              {t("Remove")}
            </Button>
            <Button
              size="small"
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => movetocart(wishlist)}
            >
              {t("Move To Cart")}
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
};

export default ProductCard;
