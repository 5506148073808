import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Parser } from 'react-xml-parser';
import {
  BASE_URL,
} from "../../apihelper";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { useParams, useNavigate } from "react-router-dom";

const SitemapElement = () => {
  const [xmlContent, setXmlContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  let apiEndpointUrl = BASE_URL + '/sitemap'; // Replace with your API endpoint URL
  let { id } = useParams();
  if(id)
  {
    apiEndpointUrl = BASE_URL + '/sitemap/'+id; // Replace with your API endpoint URL
  }


  useEffect(() => {
    fetchSitemap();
  }, []);

  const fetchSitemap = async () => {
    try {
      const response = await axios.get(apiEndpointUrl);
      setXmlContent(response.data.content);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  

  const renderXML = (xml) => {
   
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');
    const formattedXML = new XMLSerializer().serializeToString(xmlDoc);
 
    return formattedXML;
  };
  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
      {<div>
        <SyntaxHighlighter language="xml" >
          {xmlContent}
        </SyntaxHighlighter>
      </div>}
       
              </>
            )}
    </div>
  );

  // return (
   
  // <>
  //     {isLoading ? (
  //       <p>Loading...</p>
  //     ) : (
  //       <>
  //       {<div
              
  //             dangerouslySetInnerHTML={{
  //               __html: renderXML(xmlContent),
  //             }}
  //           ></div>}
       
  //       </>
  //     )}
  //   </>
  // );
};


export default SitemapElement;
